@font-face {
  font-family: 'stack-interface';
  src: url('../fonts/stack-interface.eot?33839631');
  src: url('../fonts/stack-interface.eot?33839631#iefix') format('embedded-opentype'),
       url('../fonts/stack-interface.woff2?33839631') format('woff2'),
       url('../fonts/stack-interface.woff?33839631') format('woff'),
       url('../fonts/stack-interface.ttf?33839631') format('truetype'),
       url('../fonts/stack-interface.svg?33839631#stack-interface') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'stack-interface';
    src: url('../font/stack-interface.svg?34857618#stack-interface') format('svg');
  }
}
*/
 
 [class^="stack-"]:before, [class*=" stack-"]:before {
  font-family: "stack-interface";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.stack-down-open-big:before { content: '\e800'; } /* '' */
.stack-left-open-big:before { content: '\e801'; } /* '' */
.stack-right-open-big:before { content: '\e802'; } /* '' */
.stack-up-open-big:before { content: '\e803'; } /* '' */
.stack-basket:before { content: '\e804'; } /* '' */
.stack-search:before { content: '\e805'; } /* '' */
.stack-down-dir:before { content: '\e806'; } /* '' */
.stack-left-dir:before { content: '\e807'; } /* '' */
.stack-right-dir:before { content: '\e808'; } /* '' */
.stack-up-dir:before { content: '\e809'; } /* '' */
.stack-down-open:before { content: '\e80a'; } /* '' */
.stack-left-open:before { content: '\e80b'; } /* '' */
.stack-right-open:before { content: '\e80c'; } /* '' */
.stack-up-open:before { content: '\e80d'; } /* '' */
.stack-menu:before { content: '\e80e'; } /* '' */
.stack-users:before { content: '\e80f'; } /* '' */
.stack-publish:before { content: '\e810'; } /* '' */
.stack-trash:before { content: '\e811'; } /* '' */
.stack-bell:before { content: '\e812'; } /* '' */
.stack-cog:before { content: '\e813'; } /* '' */
.stack-plus-circled:before { content: '\e814'; } /* '' */
.stack-dot-3:before { content: '\e815'; } /* '' */
@font-face {
  font-family: 'Socicon';
  src:  url('../fonts/socicon.eot?a93r5t');
  src:  url('../fonts/socicon.eot?a93r5t#iefix') format('embedded-opentype'),
    url('../fonts/socicon.ttf?a93r5t') format('truetype'),
    url('../fonts/socicon.woff?a93r5t') format('woff'),
    url('../fonts/socicon.svg?a93r5t#Socicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="socicon-"], [class*=" socicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Socicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.socicon-gamewisp:before {
  content: "\e905";
}
.socicon-bloglovin:before {
  content: "\e904";
}
.socicon-tunein:before {
  content: "\e903";
}
.socicon-gamejolt:before {
  content: "\e901";
}
.socicon-trello:before {
  content: "\e902";
}
.socicon-spreadshirt:before {
  content: "\e900";
}
.socicon-500px:before {
  content: "\e000";
}
.socicon-8tracks:before {
  content: "\e001";
}
.socicon-airbnb:before {
  content: "\e002";
}
.socicon-alliance:before {
  content: "\e003";
}
.socicon-amazon:before {
  content: "\e004";
}
.socicon-amplement:before {
  content: "\e005";
}
.socicon-android:before {
  content: "\e006";
}
.socicon-angellist:before {
  content: "\e007";
}
.socicon-apple:before {
  content: "\e008";
}
.socicon-appnet:before {
  content: "\e009";
}
.socicon-baidu:before {
  content: "\e00a";
}
.socicon-bandcamp:before {
  content: "\e00b";
}
.socicon-battlenet:before {
  content: "\e00c";
}
.socicon-beam:before {
  content: "\e00d";
}
.socicon-bebee:before {
  content: "\e00e";
}
.socicon-bebo:before {
  content: "\e00f";
}
.socicon-behance:before {
  content: "\e010";
}
.socicon-blizzard:before {
  content: "\e011";
}
.socicon-blogger:before {
  content: "\e012";
}
.socicon-buffer:before {
  content: "\e013";
}
.socicon-chrome:before {
  content: "\e014";
}
.socicon-coderwall:before {
  content: "\e015";
}
.socicon-curse:before {
  content: "\e016";
}
.socicon-dailymotion:before {
  content: "\e017";
}
.socicon-deezer:before {
  content: "\e018";
}
.socicon-delicious:before {
  content: "\e019";
}
.socicon-deviantart:before {
  content: "\e01a";
}
.socicon-diablo:before {
  content: "\e01b";
}
.socicon-digg:before {
  content: "\e01c";
}
.socicon-discord:before {
  content: "\e01d";
}
.socicon-disqus:before {
  content: "\e01e";
}
.socicon-douban:before {
  content: "\e01f";
}
.socicon-draugiem:before {
  content: "\e020";
}
.socicon-dribbble:before {
  content: "\e021";
}
.socicon-drupal:before {
  content: "\e022";
}
.socicon-ebay:before {
  content: "\e023";
}
.socicon-ello:before {
  content: "\e024";
}
.socicon-endomodo:before {
  content: "\e025";
}
.socicon-envato:before {
  content: "\e026";
}
.socicon-etsy:before {
  content: "\e027";
}
.socicon-facebook:before {
  content: "\e028";
}
.socicon-feedburner:before {
  content: "\e029";
}
.socicon-filmweb:before {
  content: "\e02a";
}
.socicon-firefox:before {
  content: "\e02b";
}
.socicon-flattr:before {
  content: "\e02c";
}
.socicon-flickr:before {
  content: "\e02d";
}
.socicon-formulr:before {
  content: "\e02e";
}
.socicon-forrst:before {
  content: "\e02f";
}
.socicon-foursquare:before {
  content: "\e030";
}
.socicon-friendfeed:before {
  content: "\e031";
}
.socicon-github:before {
  content: "\e032";
}
.socicon-goodreads:before {
  content: "\e033";
}
.socicon-google:before {
  content: "\e034";
}
.socicon-googlescholar:before {
  content: "\e035";
}
.socicon-googlegroups:before {
  content: "\e036";
}
.socicon-googlephotos:before {
  content: "\e037";
}
.socicon-googleplus:before {
  content: "\e038";
}
.socicon-grooveshark:before {
  content: "\e039";
}
.socicon-hackerrank:before {
  content: "\e03a";
}
.socicon-hearthstone:before {
  content: "\e03b";
}
.socicon-hellocoton:before {
  content: "\e03c";
}
.socicon-heroes:before {
  content: "\e03d";
}
.socicon-hitbox:before {
  content: "\e03e";
}
.socicon-horde:before {
  content: "\e03f";
}
.socicon-houzz:before {
  content: "\e040";
}
.socicon-icq:before {
  content: "\e041";
}
.socicon-identica:before {
  content: "\e042";
}
.socicon-imdb:before {
  content: "\e043";
}
.socicon-instagram:before {
  content: "\e044";
}
.socicon-issuu:before {
  content: "\e045";
}
.socicon-istock:before {
  content: "\e046";
}
.socicon-itunes:before {
  content: "\e047";
}
.socicon-keybase:before {
  content: "\e048";
}
.socicon-lanyrd:before {
  content: "\e049";
}
.socicon-lastfm:before {
  content: "\e04a";
}
.socicon-line:before {
  content: "\e04b";
}
.socicon-linkedin:before {
  content: "\e04c";
}
.socicon-livejournal:before {
  content: "\e04d";
}
.socicon-lyft:before {
  content: "\e04e";
}
.socicon-macos:before {
  content: "\e04f";
}
.socicon-mail:before {
  content: "\e050";
}
.socicon-medium:before {
  content: "\e051";
}
.socicon-meetup:before {
  content: "\e052";
}
.socicon-mixcloud:before {
  content: "\e053";
}
.socicon-modelmayhem:before {
  content: "\e054";
}
.socicon-mumble:before {
  content: "\e055";
}
.socicon-myspace:before {
  content: "\e056";
}
.socicon-newsvine:before {
  content: "\e057";
}
.socicon-nintendo:before {
  content: "\e058";
}
.socicon-npm:before {
  content: "\e059";
}
.socicon-odnoklassniki:before {
  content: "\e05a";
}
.socicon-openid:before {
  content: "\e05b";
}
.socicon-opera:before {
  content: "\e05c";
}
.socicon-outlook:before {
  content: "\e05d";
}
.socicon-overwatch:before {
  content: "\e05e";
}
.socicon-patreon:before {
  content: "\e05f";
}
.socicon-paypal:before {
  content: "\e060";
}
.socicon-periscope:before {
  content: "\e061";
}
.socicon-persona:before {
  content: "\e062";
}
.socicon-pinterest:before {
  content: "\e063";
}
.socicon-play:before {
  content: "\e064";
}
.socicon-player:before {
  content: "\e065";
}
.socicon-playstation:before {
  content: "\e066";
}
.socicon-pocket:before {
  content: "\e067";
}
.socicon-qq:before {
  content: "\e068";
}
.socicon-quora:before {
  content: "\e069";
}
.socicon-raidcall:before {
  content: "\e06a";
}
.socicon-ravelry:before {
  content: "\e06b";
}
.socicon-reddit:before {
  content: "\e06c";
}
.socicon-renren:before {
  content: "\e06d";
}
.socicon-researchgate:before {
  content: "\e06e";
}
.socicon-residentadvisor:before {
  content: "\e06f";
}
.socicon-reverbnation:before {
  content: "\e070";
}
.socicon-rss:before {
  content: "\e071";
}
.socicon-sharethis:before {
  content: "\e072";
}
.socicon-skype:before {
  content: "\e073";
}
.socicon-slideshare:before {
  content: "\e074";
}
.socicon-smugmug:before {
  content: "\e075";
}
.socicon-snapchat:before {
  content: "\e076";
}
.socicon-songkick:before {
  content: "\e077";
}
.socicon-soundcloud:before {
  content: "\e078";
}
.socicon-spotify:before {
  content: "\e079";
}
.socicon-stackexchange:before {
  content: "\e07a";
}
.socicon-stackoverflow:before {
  content: "\e07b";
}
.socicon-starcraft:before {
  content: "\e07c";
}
.socicon-stayfriends:before {
  content: "\e07d";
}
.socicon-steam:before {
  content: "\e07e";
}
.socicon-storehouse:before {
  content: "\e07f";
}
.socicon-strava:before {
  content: "\e080";
}
.socicon-streamjar:before {
  content: "\e081";
}
.socicon-stumbleupon:before {
  content: "\e082";
}
.socicon-swarm:before {
  content: "\e083";
}
.socicon-teamspeak:before {
  content: "\e084";
}
.socicon-teamviewer:before {
  content: "\e085";
}
.socicon-technorati:before {
  content: "\e086";
}
.socicon-telegram:before {
  content: "\e087";
}
.socicon-tripadvisor:before {
  content: "\e088";
}
.socicon-tripit:before {
  content: "\e089";
}
.socicon-triplej:before {
  content: "\e08a";
}
.socicon-tumblr:before {
  content: "\e08b";
}
.socicon-twitch:before {
  content: "\e08c";
}
.socicon-twitter:before {
  content: "\e08d";
}
.socicon-uber:before {
  content: "\e08e";
}
.socicon-ventrilo:before {
  content: "\e08f";
}
.socicon-viadeo:before {
  content: "\e090";
}
.socicon-viber:before {
  content: "\e091";
}
.socicon-viewbug:before {
  content: "\e092";
}
.socicon-vimeo:before {
  content: "\e093";
}
.socicon-vine:before {
  content: "\e094";
}
.socicon-vkontakte:before {
  content: "\e095";
}
.socicon-warcraft:before {
  content: "\e096";
}
.socicon-wechat:before {
  content: "\e097";
}
.socicon-weibo:before {
  content: "\e098";
}
.socicon-whatsapp:before {
  content: "\e099";
}
.socicon-wikipedia:before {
  content: "\e09a";
}
.socicon-windows:before {
  content: "\e09b";
}
.socicon-wordpress:before {
  content: "\e09c";
}
.socicon-wykop:before {
  content: "\e09d";
}
.socicon-xbox:before {
  content: "\e09e";
}
.socicon-xing:before {
  content: "\e09f";
}
.socicon-yahoo:before {
  content: "\e0a0";
}
.socicon-yammer:before {
  content: "\e0a1";
}
.socicon-yandex:before {
  content: "\e0a2";
}
.socicon-yelp:before {
  content: "\e0a3";
}
.socicon-younow:before {
  content: "\e0a4";
}
.socicon-youtube:before {
  content: "\e0a5";
}
.socicon-zapier:before {
  content: "\e0a6";
}
.socicon-zerply:before {
  content: "\e0a7";
}
.socicon-zomato:before {
  content: "\e0a8";
}
.socicon-zynga:before {
  content: "\e0a9";
}


@font-face {
	font-family: 'iconsmind';
	src:url('../fonts/iconsmind.eot?#iefix-rdmvgc') format('embedded-opentype');
	src:url('../fonts/iconsmind.woff') format('woff'),
		url('../fonts/iconsmind.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'iconsmind';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-A-Z:before {
	content: "\e600";
}
.icon-Aa:before {
	content: "\e601";
}
.icon-Add-Bag:before {
	content: "\e602";
}
.icon-Add-Basket:before {
	content: "\e603";
}
.icon-Add-Cart:before {
	content: "\e604";
}
.icon-Add-File:before {
	content: "\e605";
}
.icon-Add-SpaceAfterParagraph:before {
	content: "\e606";
}
.icon-Add-SpaceBeforeParagraph:before {
	content: "\e607";
}
.icon-Add-User:before {
	content: "\e608";
}
.icon-Add-UserStar:before {
	content: "\e609";
}
.icon-Add-Window:before {
	content: "\e60a";
}
.icon-Add:before {
	content: "\e60b";
}
.icon-Address-Book:before {
	content: "\e60c";
}
.icon-Address-Book2:before {
	content: "\e60d";
}
.icon-Administrator:before {
	content: "\e60e";
}
.icon-Aerobics-2:before {
	content: "\e60f";
}
.icon-Aerobics-3:before {
	content: "\e610";
}
.icon-Aerobics:before {
	content: "\e611";
}
.icon-Affiliate:before {
	content: "\e612";
}
.icon-Aim:before {
	content: "\e613";
}
.icon-Air-Balloon:before {
	content: "\e614";
}
.icon-Airbrush:before {
	content: "\e615";
}
.icon-Airship:before {
	content: "\e616";
}
.icon-Alarm-Clock:before {
	content: "\e617";
}
.icon-Alarm-Clock2:before {
	content: "\e618";
}
.icon-Alarm:before {
	content: "\e619";
}
.icon-Alien-2:before {
	content: "\e61a";
}
.icon-Alien:before {
	content: "\e61b";
}
.icon-Aligator:before {
	content: "\e61c";
}
.icon-Align-Center:before {
	content: "\e61d";
}
.icon-Align-JustifyAll:before {
	content: "\e61e";
}
.icon-Align-JustifyCenter:before {
	content: "\e61f";
}
.icon-Align-JustifyLeft:before {
	content: "\e620";
}
.icon-Align-JustifyRight:before {
	content: "\e621";
}
.icon-Align-Left:before {
	content: "\e622";
}
.icon-Align-Right:before {
	content: "\e623";
}
.icon-Alpha:before {
	content: "\e624";
}
.icon-Ambulance:before {
	content: "\e625";
}
.icon-AMX:before {
	content: "\e626";
}
.icon-Anchor-2:before {
	content: "\e627";
}
.icon-Anchor:before {
	content: "\e628";
}
.icon-Android-Store:before {
	content: "\e629";
}
.icon-Android:before {
	content: "\e62a";
}
.icon-Angel-Smiley:before {
	content: "\e62b";
}
.icon-Angel:before {
	content: "\e62c";
}
.icon-Angry:before {
	content: "\e62d";
}
.icon-Apple-Bite:before {
	content: "\e62e";
}
.icon-Apple-Store:before {
	content: "\e62f";
}
.icon-Apple:before {
	content: "\e630";
}
.icon-Approved-Window:before {
	content: "\e631";
}
.icon-Aquarius-2:before {
	content: "\e632";
}
.icon-Aquarius:before {
	content: "\e633";
}
.icon-Archery-2:before {
	content: "\e634";
}
.icon-Archery:before {
	content: "\e635";
}
.icon-Argentina:before {
	content: "\e636";
}
.icon-Aries-2:before {
	content: "\e637";
}
.icon-Aries:before {
	content: "\e638";
}
.icon-Army-Key:before {
	content: "\e639";
}
.icon-Arrow-Around:before {
	content: "\e63a";
}
.icon-Arrow-Back3:before {
	content: "\e63b";
}
.icon-Arrow-Back:before {
	content: "\e63c";
}
.icon-Arrow-Back2:before {
	content: "\e63d";
}
.icon-Arrow-Barrier:before {
	content: "\e63e";
}
.icon-Arrow-Circle:before {
	content: "\e63f";
}
.icon-Arrow-Cross:before {
	content: "\e640";
}
.icon-Arrow-Down:before {
	content: "\e641";
}
.icon-Arrow-Down2:before {
	content: "\e642";
}
.icon-Arrow-Down3:before {
	content: "\e643";
}
.icon-Arrow-DowninCircle:before {
	content: "\e644";
}
.icon-Arrow-Fork:before {
	content: "\e645";
}
.icon-Arrow-Forward:before {
	content: "\e646";
}
.icon-Arrow-Forward2:before {
	content: "\e647";
}
.icon-Arrow-From:before {
	content: "\e648";
}
.icon-Arrow-Inside:before {
	content: "\e649";
}
.icon-Arrow-Inside45:before {
	content: "\e64a";
}
.icon-Arrow-InsideGap:before {
	content: "\e64b";
}
.icon-Arrow-InsideGap45:before {
	content: "\e64c";
}
.icon-Arrow-Into:before {
	content: "\e64d";
}
.icon-Arrow-Join:before {
	content: "\e64e";
}
.icon-Arrow-Junction:before {
	content: "\e64f";
}
.icon-Arrow-Left:before {
	content: "\e650";
}
.icon-Arrow-Left2:before {
	content: "\e651";
}
.icon-Arrow-LeftinCircle:before {
	content: "\e652";
}
.icon-Arrow-Loop:before {
	content: "\e653";
}
.icon-Arrow-Merge:before {
	content: "\e654";
}
.icon-Arrow-Mix:before {
	content: "\e655";
}
.icon-Arrow-Next:before {
	content: "\e656";
}
.icon-Arrow-OutLeft:before {
	content: "\e657";
}
.icon-Arrow-OutRight:before {
	content: "\e658";
}
.icon-Arrow-Outside:before {
	content: "\e659";
}
.icon-Arrow-Outside45:before {
	content: "\e65a";
}
.icon-Arrow-OutsideGap:before {
	content: "\e65b";
}
.icon-Arrow-OutsideGap45:before {
	content: "\e65c";
}
.icon-Arrow-Over:before {
	content: "\e65d";
}
.icon-Arrow-Refresh:before {
	content: "\e65e";
}
.icon-Arrow-Refresh2:before {
	content: "\e65f";
}
.icon-Arrow-Right:before {
	content: "\e660";
}
.icon-Arrow-Right2:before {
	content: "\e661";
}
.icon-Arrow-RightinCircle:before {
	content: "\e662";
}
.icon-Arrow-Shuffle:before {
	content: "\e663";
}
.icon-Arrow-Squiggly:before {
	content: "\e664";
}
.icon-Arrow-Through:before {
	content: "\e665";
}
.icon-Arrow-To:before {
	content: "\e666";
}
.icon-Arrow-TurnLeft:before {
	content: "\e667";
}
.icon-Arrow-TurnRight:before {
	content: "\e668";
}
.icon-Arrow-Up:before {
	content: "\e669";
}
.icon-Arrow-Up2:before {
	content: "\e66a";
}
.icon-Arrow-Up3:before {
	content: "\e66b";
}
.icon-Arrow-UpinCircle:before {
	content: "\e66c";
}
.icon-Arrow-XLeft:before {
	content: "\e66d";
}
.icon-Arrow-XRight:before {
	content: "\e66e";
}
.icon-Ask:before {
	content: "\e66f";
}
.icon-Assistant:before {
	content: "\e670";
}
.icon-Astronaut:before {
	content: "\e671";
}
.icon-At-Sign:before {
	content: "\e672";
}
.icon-ATM:before {
	content: "\e673";
}
.icon-Atom:before {
	content: "\e674";
}
.icon-Audio:before {
	content: "\e675";
}
.icon-Auto-Flash:before {
	content: "\e676";
}
.icon-Autumn:before {
	content: "\e677";
}
.icon-Baby-Clothes:before {
	content: "\e678";
}
.icon-Baby-Clothes2:before {
	content: "\e679";
}
.icon-Baby-Cry:before {
	content: "\e67a";
}
.icon-Baby:before {
	content: "\e67b";
}
.icon-Back2:before {
	content: "\e67c";
}
.icon-Back-Media:before {
	content: "\e67d";
}
.icon-Back-Music:before {
	content: "\e67e";
}
.icon-Back:before {
	content: "\e67f";
}
.icon-Background:before {
	content: "\e680";
}
.icon-Bacteria:before {
	content: "\e681";
}
.icon-Bag-Coins:before {
	content: "\e682";
}
.icon-Bag-Items:before {
	content: "\e683";
}
.icon-Bag-Quantity:before {
	content: "\e684";
}
.icon-Bag:before {
	content: "\e685";
}
.icon-Bakelite:before {
	content: "\e686";
}
.icon-Ballet-Shoes:before {
	content: "\e687";
}
.icon-Balloon:before {
	content: "\e688";
}
.icon-Banana:before {
	content: "\e689";
}
.icon-Band-Aid:before {
	content: "\e68a";
}
.icon-Bank:before {
	content: "\e68b";
}
.icon-Bar-Chart:before {
	content: "\e68c";
}
.icon-Bar-Chart2:before {
	content: "\e68d";
}
.icon-Bar-Chart3:before {
	content: "\e68e";
}
.icon-Bar-Chart4:before {
	content: "\e68f";
}
.icon-Bar-Chart5:before {
	content: "\e690";
}
.icon-Bar-Code:before {
	content: "\e691";
}
.icon-Barricade-2:before {
	content: "\e692";
}
.icon-Barricade:before {
	content: "\e693";
}
.icon-Baseball:before {
	content: "\e694";
}
.icon-Basket-Ball:before {
	content: "\e695";
}
.icon-Basket-Coins:before {
	content: "\e696";
}
.icon-Basket-Items:before {
	content: "\e697";
}
.icon-Basket-Quantity:before {
	content: "\e698";
}
.icon-Bat-2:before {
	content: "\e699";
}
.icon-Bat:before {
	content: "\e69a";
}
.icon-Bathrobe:before {
	content: "\e69b";
}
.icon-Batman-Mask:before {
	content: "\e69c";
}
.icon-Battery-0:before {
	content: "\e69d";
}
.icon-Battery-25:before {
	content: "\e69e";
}
.icon-Battery-50:before {
	content: "\e69f";
}
.icon-Battery-75:before {
	content: "\e6a0";
}
.icon-Battery-100:before {
	content: "\e6a1";
}
.icon-Battery-Charge:before {
	content: "\e6a2";
}
.icon-Bear:before {
	content: "\e6a3";
}
.icon-Beard-2:before {
	content: "\e6a4";
}
.icon-Beard-3:before {
	content: "\e6a5";
}
.icon-Beard:before {
	content: "\e6a6";
}
.icon-Bebo:before {
	content: "\e6a7";
}
.icon-Bee:before {
	content: "\e6a8";
}
.icon-Beer-Glass:before {
	content: "\e6a9";
}
.icon-Beer:before {
	content: "\e6aa";
}
.icon-Bell-2:before {
	content: "\e6ab";
}
.icon-Bell:before {
	content: "\e6ac";
}
.icon-Belt-2:before {
	content: "\e6ad";
}
.icon-Belt-3:before {
	content: "\e6ae";
}
.icon-Belt:before {
	content: "\e6af";
}
.icon-Berlin-Tower:before {
	content: "\e6b0";
}
.icon-Beta:before {
	content: "\e6b1";
}
.icon-Betvibes:before {
	content: "\e6b2";
}
.icon-Bicycle-2:before {
	content: "\e6b3";
}
.icon-Bicycle-3:before {
	content: "\e6b4";
}
.icon-Bicycle:before {
	content: "\e6b5";
}
.icon-Big-Bang:before {
	content: "\e6b6";
}
.icon-Big-Data:before {
	content: "\e6b7";
}
.icon-Bike-Helmet:before {
	content: "\e6b8";
}
.icon-Bikini:before {
	content: "\e6b9";
}
.icon-Bilk-Bottle2:before {
	content: "\e6ba";
}
.icon-Billing:before {
	content: "\e6bb";
}
.icon-Bing:before {
	content: "\e6bc";
}
.icon-Binocular:before {
	content: "\e6bd";
}
.icon-Bio-Hazard:before {
	content: "\e6be";
}
.icon-Biotech:before {
	content: "\e6bf";
}
.icon-Bird-DeliveringLetter:before {
	content: "\e6c0";
}
.icon-Bird:before {
	content: "\e6c1";
}
.icon-Birthday-Cake:before {
	content: "\e6c2";
}
.icon-Bisexual:before {
	content: "\e6c3";
}
.icon-Bishop:before {
	content: "\e6c4";
}
.icon-Bitcoin:before {
	content: "\e6c5";
}
.icon-Black-Cat:before {
	content: "\e6c6";
}
.icon-Blackboard:before {
	content: "\e6c7";
}
.icon-Blinklist:before {
	content: "\e6c8";
}
.icon-Block-Cloud:before {
	content: "\e6c9";
}
.icon-Block-Window:before {
	content: "\e6ca";
}
.icon-Blogger:before {
	content: "\e6cb";
}
.icon-Blood:before {
	content: "\e6cc";
}
.icon-Blouse:before {
	content: "\e6cd";
}
.icon-Blueprint:before {
	content: "\e6ce";
}
.icon-Board:before {
	content: "\e6cf";
}
.icon-Bodybuilding:before {
	content: "\e6d0";
}
.icon-Bold-Text:before {
	content: "\e6d1";
}
.icon-Bone:before {
	content: "\e6d2";
}
.icon-Bones:before {
	content: "\e6d3";
}
.icon-Book:before {
	content: "\e6d4";
}
.icon-Bookmark:before {
	content: "\e6d5";
}
.icon-Books-2:before {
	content: "\e6d6";
}
.icon-Books:before {
	content: "\e6d7";
}
.icon-Boom:before {
	content: "\e6d8";
}
.icon-Boot-2:before {
	content: "\e6d9";
}
.icon-Boot:before {
	content: "\e6da";
}
.icon-Bottom-ToTop:before {
	content: "\e6db";
}
.icon-Bow-2:before {
	content: "\e6dc";
}
.icon-Bow-3:before {
	content: "\e6dd";
}
.icon-Bow-4:before {
	content: "\e6de";
}
.icon-Bow-5:before {
	content: "\e6df";
}
.icon-Bow-6:before {
	content: "\e6e0";
}
.icon-Bow:before {
	content: "\e6e1";
}
.icon-Bowling-2:before {
	content: "\e6e2";
}
.icon-Bowling:before {
	content: "\e6e3";
}
.icon-Box2:before {
	content: "\e6e4";
}
.icon-Box-Close:before {
	content: "\e6e5";
}
.icon-Box-Full:before {
	content: "\e6e6";
}
.icon-Box-Open:before {
	content: "\e6e7";
}
.icon-Box-withFolders:before {
	content: "\e6e8";
}
.icon-Box:before {
	content: "\e6e9";
}
.icon-Boy:before {
	content: "\e6ea";
}
.icon-Bra:before {
	content: "\e6eb";
}
.icon-Brain-2:before {
	content: "\e6ec";
}
.icon-Brain-3:before {
	content: "\e6ed";
}
.icon-Brain:before {
	content: "\e6ee";
}
.icon-Brazil:before {
	content: "\e6ef";
}
.icon-Bread-2:before {
	content: "\e6f0";
}
.icon-Bread:before {
	content: "\e6f1";
}
.icon-Bridge:before {
	content: "\e6f2";
}
.icon-Brightkite:before {
	content: "\e6f3";
}
.icon-Broke-Link2:before {
	content: "\e6f4";
}
.icon-Broken-Link:before {
	content: "\e6f5";
}
.icon-Broom:before {
	content: "\e6f6";
}
.icon-Brush:before {
	content: "\e6f7";
}
.icon-Bucket:before {
	content: "\e6f8";
}
.icon-Bug:before {
	content: "\e6f9";
}
.icon-Building:before {
	content: "\e6fa";
}
.icon-Bulleted-List:before {
	content: "\e6fb";
}
.icon-Bus-2:before {
	content: "\e6fc";
}
.icon-Bus:before {
	content: "\e6fd";
}
.icon-Business-Man:before {
	content: "\e6fe";
}
.icon-Business-ManWoman:before {
	content: "\e6ff";
}
.icon-Business-Mens:before {
	content: "\e700";
}
.icon-Business-Woman:before {
	content: "\e701";
}
.icon-Butterfly:before {
	content: "\e702";
}
.icon-Button:before {
	content: "\e703";
}
.icon-Cable-Car:before {
	content: "\e704";
}
.icon-Cake:before {
	content: "\e705";
}
.icon-Calculator-2:before {
	content: "\e706";
}
.icon-Calculator-3:before {
	content: "\e707";
}
.icon-Calculator:before {
	content: "\e708";
}
.icon-Calendar-2:before {
	content: "\e709";
}
.icon-Calendar-3:before {
	content: "\e70a";
}
.icon-Calendar-4:before {
	content: "\e70b";
}
.icon-Calendar-Clock:before {
	content: "\e70c";
}
.icon-Calendar:before {
	content: "\e70d";
}
.icon-Camel:before {
	content: "\e70e";
}
.icon-Camera-2:before {
	content: "\e70f";
}
.icon-Camera-3:before {
	content: "\e710";
}
.icon-Camera-4:before {
	content: "\e711";
}
.icon-Camera-5:before {
	content: "\e712";
}
.icon-Camera-Back:before {
	content: "\e713";
}
.icon-Camera:before {
	content: "\e714";
}
.icon-Can-2:before {
	content: "\e715";
}
.icon-Can:before {
	content: "\e716";
}
.icon-Canada:before {
	content: "\e717";
}
.icon-Cancer-2:before {
	content: "\e718";
}
.icon-Cancer-3:before {
	content: "\e719";
}
.icon-Cancer:before {
	content: "\e71a";
}
.icon-Candle:before {
	content: "\e71b";
}
.icon-Candy-Cane:before {
	content: "\e71c";
}
.icon-Candy:before {
	content: "\e71d";
}
.icon-Cannon:before {
	content: "\e71e";
}
.icon-Cap-2:before {
	content: "\e71f";
}
.icon-Cap-3:before {
	content: "\e720";
}
.icon-Cap-Smiley:before {
	content: "\e721";
}
.icon-Cap:before {
	content: "\e722";
}
.icon-Capricorn-2:before {
	content: "\e723";
}
.icon-Capricorn:before {
	content: "\e724";
}
.icon-Car-2:before {
	content: "\e725";
}
.icon-Car-3:before {
	content: "\e726";
}
.icon-Car-Coins:before {
	content: "\e727";
}
.icon-Car-Items:before {
	content: "\e728";
}
.icon-Car-Wheel:before {
	content: "\e729";
}
.icon-Car:before {
	content: "\e72a";
}
.icon-Cardigan:before {
	content: "\e72b";
}
.icon-Cardiovascular:before {
	content: "\e72c";
}
.icon-Cart-Quantity:before {
	content: "\e72d";
}
.icon-Casette-Tape:before {
	content: "\e72e";
}
.icon-Cash-Register:before {
	content: "\e72f";
}
.icon-Cash-register2:before {
	content: "\e730";
}
.icon-Castle:before {
	content: "\e731";
}
.icon-Cat:before {
	content: "\e732";
}
.icon-Cathedral:before {
	content: "\e733";
}
.icon-Cauldron:before {
	content: "\e734";
}
.icon-CD-2:before {
	content: "\e735";
}
.icon-CD-Cover:before {
	content: "\e736";
}
.icon-CD:before {
	content: "\e737";
}
.icon-Cello:before {
	content: "\e738";
}
.icon-Celsius:before {
	content: "\e739";
}
.icon-Chacked-Flag:before {
	content: "\e73a";
}
.icon-Chair:before {
	content: "\e73b";
}
.icon-Charger:before {
	content: "\e73c";
}
.icon-Check-2:before {
	content: "\e73d";
}
.icon-Check:before {
	content: "\e73e";
}
.icon-Checked-User:before {
	content: "\e73f";
}
.icon-Checkmate:before {
	content: "\e740";
}
.icon-Checkout-Bag:before {
	content: "\e741";
}
.icon-Checkout-Basket:before {
	content: "\e742";
}
.icon-Checkout:before {
	content: "\e743";
}
.icon-Cheese:before {
	content: "\e744";
}
.icon-Cheetah:before {
	content: "\e745";
}
.icon-Chef-Hat:before {
	content: "\e746";
}
.icon-Chef-Hat2:before {
	content: "\e747";
}
.icon-Chef:before {
	content: "\e748";
}
.icon-Chemical-2:before {
	content: "\e749";
}
.icon-Chemical-3:before {
	content: "\e74a";
}
.icon-Chemical-4:before {
	content: "\e74b";
}
.icon-Chemical-5:before {
	content: "\e74c";
}
.icon-Chemical:before {
	content: "\e74d";
}
.icon-Chess-Board:before {
	content: "\e74e";
}
.icon-Chess:before {
	content: "\e74f";
}
.icon-Chicken:before {
	content: "\e750";
}
.icon-Chile:before {
	content: "\e751";
}
.icon-Chimney:before {
	content: "\e752";
}
.icon-China:before {
	content: "\e753";
}
.icon-Chinese-Temple:before {
	content: "\e754";
}
.icon-Chip:before {
	content: "\e755";
}
.icon-Chopsticks-2:before {
	content: "\e756";
}
.icon-Chopsticks:before {
	content: "\e757";
}
.icon-Christmas-Ball:before {
	content: "\e758";
}
.icon-Christmas-Bell:before {
	content: "\e759";
}
.icon-Christmas-Candle:before {
	content: "\e75a";
}
.icon-Christmas-Hat:before {
	content: "\e75b";
}
.icon-Christmas-Sleigh:before {
	content: "\e75c";
}
.icon-Christmas-Snowman:before {
	content: "\e75d";
}
.icon-Christmas-Sock:before {
	content: "\e75e";
}
.icon-Christmas-Tree:before {
	content: "\e75f";
}
.icon-Christmas:before {
	content: "\e760";
}
.icon-Chrome:before {
	content: "\e761";
}
.icon-Chrysler-Building:before {
	content: "\e762";
}
.icon-Cinema:before {
	content: "\e763";
}
.icon-Circular-Point:before {
	content: "\e764";
}
.icon-City-Hall:before {
	content: "\e765";
}
.icon-Clamp:before {
	content: "\e766";
}
.icon-Clapperboard-Close:before {
	content: "\e767";
}
.icon-Clapperboard-Open:before {
	content: "\e768";
}
.icon-Claps:before {
	content: "\e769";
}
.icon-Clef:before {
	content: "\e76a";
}
.icon-Clinic:before {
	content: "\e76b";
}
.icon-Clock-2:before {
	content: "\e76c";
}
.icon-Clock-3:before {
	content: "\e76d";
}
.icon-Clock-4:before {
	content: "\e76e";
}
.icon-Clock-Back:before {
	content: "\e76f";
}
.icon-Clock-Forward:before {
	content: "\e770";
}
.icon-Clock:before {
	content: "\e771";
}
.icon-Close-Window:before {
	content: "\e772";
}
.icon-Close:before {
	content: "\e773";
}
.icon-Clothing-Store:before {
	content: "\e774";
}
.icon-Cloud--:before {
	content: "\e775";
}
.icon-Cloud-:before {
	content: "\e776";
}
.icon-Cloud-Camera:before {
	content: "\e777";
}
.icon-Cloud-Computer:before {
	content: "\e778";
}
.icon-Cloud-Email:before {
	content: "\e779";
}
.icon-Cloud-Hail:before {
	content: "\e77a";
}
.icon-Cloud-Laptop:before {
	content: "\e77b";
}
.icon-Cloud-Lock:before {
	content: "\e77c";
}
.icon-Cloud-Moon:before {
	content: "\e77d";
}
.icon-Cloud-Music:before {
	content: "\e77e";
}
.icon-Cloud-Picture:before {
	content: "\e77f";
}
.icon-Cloud-Rain:before {
	content: "\e780";
}
.icon-Cloud-Remove:before {
	content: "\e781";
}
.icon-Cloud-Secure:before {
	content: "\e782";
}
.icon-Cloud-Settings:before {
	content: "\e783";
}
.icon-Cloud-Smartphone:before {
	content: "\e784";
}
.icon-Cloud-Snow:before {
	content: "\e785";
}
.icon-Cloud-Sun:before {
	content: "\e786";
}
.icon-Cloud-Tablet:before {
	content: "\e787";
}
.icon-Cloud-Video:before {
	content: "\e788";
}
.icon-Cloud-Weather:before {
	content: "\e789";
}
.icon-Cloud:before {
	content: "\e78a";
}
.icon-Clouds-Weather:before {
	content: "\e78b";
}
.icon-Clouds:before {
	content: "\e78c";
}
.icon-Clown:before {
	content: "\e78d";
}
.icon-CMYK:before {
	content: "\e78e";
}
.icon-Coat:before {
	content: "\e78f";
}
.icon-Cocktail:before {
	content: "\e790";
}
.icon-Coconut:before {
	content: "\e791";
}
.icon-Code-Window:before {
	content: "\e792";
}
.icon-Coding:before {
	content: "\e793";
}
.icon-Coffee-2:before {
	content: "\e794";
}
.icon-Coffee-Bean:before {
	content: "\e795";
}
.icon-Coffee-Machine:before {
	content: "\e796";
}
.icon-Coffee-toGo:before {
	content: "\e797";
}
.icon-Coffee:before {
	content: "\e798";
}
.icon-Coffin:before {
	content: "\e799";
}
.icon-Coin:before {
	content: "\e79a";
}
.icon-Coins-2:before {
	content: "\e79b";
}
.icon-Coins-3:before {
	content: "\e79c";
}
.icon-Coins:before {
	content: "\e79d";
}
.icon-Colombia:before {
	content: "\e79e";
}
.icon-Colosseum:before {
	content: "\e79f";
}
.icon-Column-2:before {
	content: "\e7a0";
}
.icon-Column-3:before {
	content: "\e7a1";
}
.icon-Column:before {
	content: "\e7a2";
}
.icon-Comb-2:before {
	content: "\e7a3";
}
.icon-Comb:before {
	content: "\e7a4";
}
.icon-Communication-Tower:before {
	content: "\e7a5";
}
.icon-Communication-Tower2:before {
	content: "\e7a6";
}
.icon-Compass-2:before {
	content: "\e7a7";
}
.icon-Compass-3:before {
	content: "\e7a8";
}
.icon-Compass-4:before {
	content: "\e7a9";
}
.icon-Compass-Rose:before {
	content: "\e7aa";
}
.icon-Compass:before {
	content: "\e7ab";
}
.icon-Computer-2:before {
	content: "\e7ac";
}
.icon-Computer-3:before {
	content: "\e7ad";
}
.icon-Computer-Secure:before {
	content: "\e7ae";
}
.icon-Computer:before {
	content: "\e7af";
}
.icon-Conference:before {
	content: "\e7b0";
}
.icon-Confused:before {
	content: "\e7b1";
}
.icon-Conservation:before {
	content: "\e7b2";
}
.icon-Consulting:before {
	content: "\e7b3";
}
.icon-Contrast:before {
	content: "\e7b4";
}
.icon-Control-2:before {
	content: "\e7b5";
}
.icon-Control:before {
	content: "\e7b6";
}
.icon-Cookie-Man:before {
	content: "\e7b7";
}
.icon-Cookies:before {
	content: "\e7b8";
}
.icon-Cool-Guy:before {
	content: "\e7b9";
}
.icon-Cool:before {
	content: "\e7ba";
}
.icon-Copyright:before {
	content: "\e7bb";
}
.icon-Costume:before {
	content: "\e7bc";
}
.icon-Couple-Sign:before {
	content: "\e7bd";
}
.icon-Cow:before {
	content: "\e7be";
}
.icon-CPU:before {
	content: "\e7bf";
}
.icon-Crane:before {
	content: "\e7c0";
}
.icon-Cranium:before {
	content: "\e7c1";
}
.icon-Credit-Card:before {
	content: "\e7c2";
}
.icon-Credit-Card2:before {
	content: "\e7c3";
}
.icon-Credit-Card3:before {
	content: "\e7c4";
}
.icon-Cricket:before {
	content: "\e7c5";
}
.icon-Criminal:before {
	content: "\e7c6";
}
.icon-Croissant:before {
	content: "\e7c7";
}
.icon-Crop-2:before {
	content: "\e7c8";
}
.icon-Crop-3:before {
	content: "\e7c9";
}
.icon-Crown-2:before {
	content: "\e7ca";
}
.icon-Crown:before {
	content: "\e7cb";
}
.icon-Crying:before {
	content: "\e7cc";
}
.icon-Cube-Molecule:before {
	content: "\e7cd";
}
.icon-Cube-Molecule2:before {
	content: "\e7ce";
}
.icon-Cupcake:before {
	content: "\e7cf";
}
.icon-Cursor-Click:before {
	content: "\e7d0";
}
.icon-Cursor-Click2:before {
	content: "\e7d1";
}
.icon-Cursor-Move:before {
	content: "\e7d2";
}
.icon-Cursor-Move2:before {
	content: "\e7d3";
}
.icon-Cursor-Select:before {
	content: "\e7d4";
}
.icon-Cursor:before {
	content: "\e7d5";
}
.icon-D-Eyeglasses:before {
	content: "\e7d6";
}
.icon-D-Eyeglasses2:before {
	content: "\e7d7";
}
.icon-Dam:before {
	content: "\e7d8";
}
.icon-Danemark:before {
	content: "\e7d9";
}
.icon-Danger-2:before {
	content: "\e7da";
}
.icon-Danger:before {
	content: "\e7db";
}
.icon-Dashboard:before {
	content: "\e7dc";
}
.icon-Data-Backup:before {
	content: "\e7dd";
}
.icon-Data-Block:before {
	content: "\e7de";
}
.icon-Data-Center:before {
	content: "\e7df";
}
.icon-Data-Clock:before {
	content: "\e7e0";
}
.icon-Data-Cloud:before {
	content: "\e7e1";
}
.icon-Data-Compress:before {
	content: "\e7e2";
}
.icon-Data-Copy:before {
	content: "\e7e3";
}
.icon-Data-Download:before {
	content: "\e7e4";
}
.icon-Data-Financial:before {
	content: "\e7e5";
}
.icon-Data-Key:before {
	content: "\e7e6";
}
.icon-Data-Lock:before {
	content: "\e7e7";
}
.icon-Data-Network:before {
	content: "\e7e8";
}
.icon-Data-Password:before {
	content: "\e7e9";
}
.icon-Data-Power:before {
	content: "\e7ea";
}
.icon-Data-Refresh:before {
	content: "\e7eb";
}
.icon-Data-Save:before {
	content: "\e7ec";
}
.icon-Data-Search:before {
	content: "\e7ed";
}
.icon-Data-Security:before {
	content: "\e7ee";
}
.icon-Data-Settings:before {
	content: "\e7ef";
}
.icon-Data-Sharing:before {
	content: "\e7f0";
}
.icon-Data-Shield:before {
	content: "\e7f1";
}
.icon-Data-Signal:before {
	content: "\e7f2";
}
.icon-Data-Storage:before {
	content: "\e7f3";
}
.icon-Data-Stream:before {
	content: "\e7f4";
}
.icon-Data-Transfer:before {
	content: "\e7f5";
}
.icon-Data-Unlock:before {
	content: "\e7f6";
}
.icon-Data-Upload:before {
	content: "\e7f7";
}
.icon-Data-Yes:before {
	content: "\e7f8";
}
.icon-Data:before {
	content: "\e7f9";
}
.icon-David-Star:before {
	content: "\e7fa";
}
.icon-Daylight:before {
	content: "\e7fb";
}
.icon-Death:before {
	content: "\e7fc";
}
.icon-Debian:before {
	content: "\e7fd";
}
.icon-Dec:before {
	content: "\e7fe";
}
.icon-Decrase-Inedit:before {
	content: "\e7ff";
}
.icon-Deer-2:before {
	content: "\e800";
}
.icon-Deer:before {
	content: "\e801";
}
.icon-Delete-File:before {
	content: "\e802";
}
.icon-Delete-Window:before {
	content: "\e803";
}
.icon-Delicious:before {
	content: "\e804";
}
.icon-Depression:before {
	content: "\e805";
}
.icon-Deviantart:before {
	content: "\e806";
}
.icon-Device-SyncwithCloud:before {
	content: "\e807";
}
.icon-Diamond:before {
	content: "\e808";
}
.icon-Dice-2:before {
	content: "\e809";
}
.icon-Dice:before {
	content: "\e80a";
}
.icon-Digg:before {
	content: "\e80b";
}
.icon-Digital-Drawing:before {
	content: "\e80c";
}
.icon-Diigo:before {
	content: "\e80d";
}
.icon-Dinosaur:before {
	content: "\e80e";
}
.icon-Diploma-2:before {
	content: "\e80f";
}
.icon-Diploma:before {
	content: "\e810";
}
.icon-Direction-East:before {
	content: "\e811";
}
.icon-Direction-North:before {
	content: "\e812";
}
.icon-Direction-South:before {
	content: "\e813";
}
.icon-Direction-West:before {
	content: "\e814";
}
.icon-Director:before {
	content: "\e815";
}
.icon-Disk:before {
	content: "\e816";
}
.icon-Dj:before {
	content: "\e817";
}
.icon-DNA-2:before {
	content: "\e818";
}
.icon-DNA-Helix:before {
	content: "\e819";
}
.icon-DNA:before {
	content: "\e81a";
}
.icon-Doctor:before {
	content: "\e81b";
}
.icon-Dog:before {
	content: "\e81c";
}
.icon-Dollar-Sign:before {
	content: "\e81d";
}
.icon-Dollar-Sign2:before {
	content: "\e81e";
}
.icon-Dollar:before {
	content: "\e81f";
}
.icon-Dolphin:before {
	content: "\e820";
}
.icon-Domino:before {
	content: "\e821";
}
.icon-Door-Hanger:before {
	content: "\e822";
}
.icon-Door:before {
	content: "\e823";
}
.icon-Doplr:before {
	content: "\e824";
}
.icon-Double-Circle:before {
	content: "\e825";
}
.icon-Double-Tap:before {
	content: "\e826";
}
.icon-Doughnut:before {
	content: "\e827";
}
.icon-Dove:before {
	content: "\e828";
}
.icon-Down-2:before {
	content: "\e829";
}
.icon-Down-3:before {
	content: "\e82a";
}
.icon-Down-4:before {
	content: "\e82b";
}
.icon-Down:before {
	content: "\e82c";
}
.icon-Download-2:before {
	content: "\e82d";
}
.icon-Download-fromCloud:before {
	content: "\e82e";
}
.icon-Download-Window:before {
	content: "\e82f";
}
.icon-Download:before {
	content: "\e830";
}
.icon-Downward:before {
	content: "\e831";
}
.icon-Drag-Down:before {
	content: "\e832";
}
.icon-Drag-Left:before {
	content: "\e833";
}
.icon-Drag-Right:before {
	content: "\e834";
}
.icon-Drag-Up:before {
	content: "\e835";
}
.icon-Drag:before {
	content: "\e836";
}
.icon-Dress:before {
	content: "\e837";
}
.icon-Drill-2:before {
	content: "\e838";
}
.icon-Drill:before {
	content: "\e839";
}
.icon-Drop:before {
	content: "\e83a";
}
.icon-Dropbox:before {
	content: "\e83b";
}
.icon-Drum:before {
	content: "\e83c";
}
.icon-Dry:before {
	content: "\e83d";
}
.icon-Duck:before {
	content: "\e83e";
}
.icon-Dumbbell:before {
	content: "\e83f";
}
.icon-Duplicate-Layer:before {
	content: "\e840";
}
.icon-Duplicate-Window:before {
	content: "\e841";
}
.icon-DVD:before {
	content: "\e842";
}
.icon-Eagle:before {
	content: "\e843";
}
.icon-Ear:before {
	content: "\e844";
}
.icon-Earphones-2:before {
	content: "\e845";
}
.icon-Earphones:before {
	content: "\e846";
}
.icon-Eci-Icon:before {
	content: "\e847";
}
.icon-Edit-Map:before {
	content: "\e848";
}
.icon-Edit:before {
	content: "\e849";
}
.icon-Eggs:before {
	content: "\e84a";
}
.icon-Egypt:before {
	content: "\e84b";
}
.icon-Eifel-Tower:before {
	content: "\e84c";
}
.icon-eject-2:before {
	content: "\e84d";
}
.icon-Eject:before {
	content: "\e84e";
}
.icon-El-Castillo:before {
	content: "\e84f";
}
.icon-Elbow:before {
	content: "\e850";
}
.icon-Electric-Guitar:before {
	content: "\e851";
}
.icon-Electricity:before {
	content: "\e852";
}
.icon-Elephant:before {
	content: "\e853";
}
.icon-Email:before {
	content: "\e854";
}
.icon-Embassy:before {
	content: "\e855";
}
.icon-Empire-StateBuilding:before {
	content: "\e856";
}
.icon-Empty-Box:before {
	content: "\e857";
}
.icon-End2:before {
	content: "\e858";
}
.icon-End-2:before {
	content: "\e859";
}
.icon-End:before {
	content: "\e85a";
}
.icon-Endways:before {
	content: "\e85b";
}
.icon-Engineering:before {
	content: "\e85c";
}
.icon-Envelope-2:before {
	content: "\e85d";
}
.icon-Envelope:before {
	content: "\e85e";
}
.icon-Environmental-2:before {
	content: "\e85f";
}
.icon-Environmental-3:before {
	content: "\e860";
}
.icon-Environmental:before {
	content: "\e861";
}
.icon-Equalizer:before {
	content: "\e862";
}
.icon-Eraser-2:before {
	content: "\e863";
}
.icon-Eraser-3:before {
	content: "\e864";
}
.icon-Eraser:before {
	content: "\e865";
}
.icon-Error-404Window:before {
	content: "\e866";
}
.icon-Euro-Sign:before {
	content: "\e867";
}
.icon-Euro-Sign2:before {
	content: "\e868";
}
.icon-Euro:before {
	content: "\e869";
}
.icon-Evernote:before {
	content: "\e86a";
}
.icon-Evil:before {
	content: "\e86b";
}
.icon-Explode:before {
	content: "\e86c";
}
.icon-Eye-2:before {
	content: "\e86d";
}
.icon-Eye-Blind:before {
	content: "\e86e";
}
.icon-Eye-Invisible:before {
	content: "\e86f";
}
.icon-Eye-Scan:before {
	content: "\e870";
}
.icon-Eye-Visible:before {
	content: "\e871";
}
.icon-Eye:before {
	content: "\e872";
}
.icon-Eyebrow-2:before {
	content: "\e873";
}
.icon-Eyebrow-3:before {
	content: "\e874";
}
.icon-Eyebrow:before {
	content: "\e875";
}
.icon-Eyeglasses-Smiley:before {
	content: "\e876";
}
.icon-Eyeglasses-Smiley2:before {
	content: "\e877";
}
.icon-Face-Style:before {
	content: "\e878";
}
.icon-Face-Style2:before {
	content: "\e879";
}
.icon-Face-Style3:before {
	content: "\e87a";
}
.icon-Face-Style4:before {
	content: "\e87b";
}
.icon-Face-Style5:before {
	content: "\e87c";
}
.icon-Face-Style6:before {
	content: "\e87d";
}
.icon-Facebook-2:before {
	content: "\e87e";
}
.icon-Facebook:before {
	content: "\e87f";
}
.icon-Factory-2:before {
	content: "\e880";
}
.icon-Factory:before {
	content: "\e881";
}
.icon-Fahrenheit:before {
	content: "\e882";
}
.icon-Family-Sign:before {
	content: "\e883";
}
.icon-Fan:before {
	content: "\e884";
}
.icon-Farmer:before {
	content: "\e885";
}
.icon-Fashion:before {
	content: "\e886";
}
.icon-Favorite-Window:before {
	content: "\e887";
}
.icon-Fax:before {
	content: "\e888";
}
.icon-Feather:before {
	content: "\e889";
}
.icon-Feedburner:before {
	content: "\e88a";
}
.icon-Female-2:before {
	content: "\e88b";
}
.icon-Female-Sign:before {
	content: "\e88c";
}
.icon-Female:before {
	content: "\e88d";
}
.icon-File-Block:before {
	content: "\e88e";
}
.icon-File-Bookmark:before {
	content: "\e88f";
}
.icon-File-Chart:before {
	content: "\e890";
}
.icon-File-Clipboard:before {
	content: "\e891";
}
.icon-File-ClipboardFileText:before {
	content: "\e892";
}
.icon-File-ClipboardTextImage:before {
	content: "\e893";
}
.icon-File-Cloud:before {
	content: "\e894";
}
.icon-File-Copy:before {
	content: "\e895";
}
.icon-File-Copy2:before {
	content: "\e896";
}
.icon-File-CSV:before {
	content: "\e897";
}
.icon-File-Download:before {
	content: "\e898";
}
.icon-File-Edit:before {
	content: "\e899";
}
.icon-File-Excel:before {
	content: "\e89a";
}
.icon-File-Favorite:before {
	content: "\e89b";
}
.icon-File-Fire:before {
	content: "\e89c";
}
.icon-File-Graph:before {
	content: "\e89d";
}
.icon-File-Hide:before {
	content: "\e89e";
}
.icon-File-Horizontal:before {
	content: "\e89f";
}
.icon-File-HorizontalText:before {
	content: "\e8a0";
}
.icon-File-HTML:before {
	content: "\e8a1";
}
.icon-File-JPG:before {
	content: "\e8a2";
}
.icon-File-Link:before {
	content: "\e8a3";
}
.icon-File-Loading:before {
	content: "\e8a4";
}
.icon-File-Lock:before {
	content: "\e8a5";
}
.icon-File-Love:before {
	content: "\e8a6";
}
.icon-File-Music:before {
	content: "\e8a7";
}
.icon-File-Network:before {
	content: "\e8a8";
}
.icon-File-Pictures:before {
	content: "\e8a9";
}
.icon-File-Pie:before {
	content: "\e8aa";
}
.icon-File-Presentation:before {
	content: "\e8ab";
}
.icon-File-Refresh:before {
	content: "\e8ac";
}
.icon-File-Search:before {
	content: "\e8ad";
}
.icon-File-Settings:before {
	content: "\e8ae";
}
.icon-File-Share:before {
	content: "\e8af";
}
.icon-File-TextImage:before {
	content: "\e8b0";
}
.icon-File-Trash:before {
	content: "\e8b1";
}
.icon-File-TXT:before {
	content: "\e8b2";
}
.icon-File-Upload:before {
	content: "\e8b3";
}
.icon-File-Video:before {
	content: "\e8b4";
}
.icon-File-Word:before {
	content: "\e8b5";
}
.icon-File-Zip:before {
	content: "\e8b6";
}
.icon-File:before {
	content: "\e8b7";
}
.icon-Files:before {
	content: "\e8b8";
}
.icon-Film-Board:before {
	content: "\e8b9";
}
.icon-Film-Cartridge:before {
	content: "\e8ba";
}
.icon-Film-Strip:before {
	content: "\e8bb";
}
.icon-Film-Video:before {
	content: "\e8bc";
}
.icon-Film:before {
	content: "\e8bd";
}
.icon-Filter-2:before {
	content: "\e8be";
}
.icon-Filter:before {
	content: "\e8bf";
}
.icon-Financial:before {
	content: "\e8c0";
}
.icon-Find-User:before {
	content: "\e8c1";
}
.icon-Finger-DragFourSides:before {
	content: "\e8c2";
}
.icon-Finger-DragTwoSides:before {
	content: "\e8c3";
}
.icon-Finger-Print:before {
	content: "\e8c4";
}
.icon-Finger:before {
	content: "\e8c5";
}
.icon-Fingerprint-2:before {
	content: "\e8c6";
}
.icon-Fingerprint:before {
	content: "\e8c7";
}
.icon-Fire-Flame:before {
	content: "\e8c8";
}
.icon-Fire-Flame2:before {
	content: "\e8c9";
}
.icon-Fire-Hydrant:before {
	content: "\e8ca";
}
.icon-Fire-Staion:before {
	content: "\e8cb";
}
.icon-Firefox:before {
	content: "\e8cc";
}
.icon-Firewall:before {
	content: "\e8cd";
}
.icon-First-Aid:before {
	content: "\e8ce";
}
.icon-First:before {
	content: "\e8cf";
}
.icon-Fish-Food:before {
	content: "\e8d0";
}
.icon-Fish:before {
	content: "\e8d1";
}
.icon-Fit-To:before {
	content: "\e8d2";
}
.icon-Fit-To2:before {
	content: "\e8d3";
}
.icon-Five-Fingers:before {
	content: "\e8d4";
}
.icon-Five-FingersDrag:before {
	content: "\e8d5";
}
.icon-Five-FingersDrag2:before {
	content: "\e8d6";
}
.icon-Five-FingersTouch:before {
	content: "\e8d7";
}
.icon-Flag-2:before {
	content: "\e8d8";
}
.icon-Flag-3:before {
	content: "\e8d9";
}
.icon-Flag-4:before {
	content: "\e8da";
}
.icon-Flag-5:before {
	content: "\e8db";
}
.icon-Flag-6:before {
	content: "\e8dc";
}
.icon-Flag:before {
	content: "\e8dd";
}
.icon-Flamingo:before {
	content: "\e8de";
}
.icon-Flash-2:before {
	content: "\e8df";
}
.icon-Flash-Video:before {
	content: "\e8e0";
}
.icon-Flash:before {
	content: "\e8e1";
}
.icon-Flashlight:before {
	content: "\e8e2";
}
.icon-Flask-2:before {
	content: "\e8e3";
}
.icon-Flask:before {
	content: "\e8e4";
}
.icon-Flick:before {
	content: "\e8e5";
}
.icon-Flickr:before {
	content: "\e8e6";
}
.icon-Flowerpot:before {
	content: "\e8e7";
}
.icon-Fluorescent:before {
	content: "\e8e8";
}
.icon-Fog-Day:before {
	content: "\e8e9";
}
.icon-Fog-Night:before {
	content: "\e8ea";
}
.icon-Folder-Add:before {
	content: "\e8eb";
}
.icon-Folder-Archive:before {
	content: "\e8ec";
}
.icon-Folder-Binder:before {
	content: "\e8ed";
}
.icon-Folder-Binder2:before {
	content: "\e8ee";
}
.icon-Folder-Block:before {
	content: "\e8ef";
}
.icon-Folder-Bookmark:before {
	content: "\e8f0";
}
.icon-Folder-Close:before {
	content: "\e8f1";
}
.icon-Folder-Cloud:before {
	content: "\e8f2";
}
.icon-Folder-Delete:before {
	content: "\e8f3";
}
.icon-Folder-Download:before {
	content: "\e8f4";
}
.icon-Folder-Edit:before {
	content: "\e8f5";
}
.icon-Folder-Favorite:before {
	content: "\e8f6";
}
.icon-Folder-Fire:before {
	content: "\e8f7";
}
.icon-Folder-Hide:before {
	content: "\e8f8";
}
.icon-Folder-Link:before {
	content: "\e8f9";
}
.icon-Folder-Loading:before {
	content: "\e8fa";
}
.icon-Folder-Lock:before {
	content: "\e8fb";
}
.icon-Folder-Love:before {
	content: "\e8fc";
}
.icon-Folder-Music:before {
	content: "\e8fd";
}
.icon-Folder-Network:before {
	content: "\e8fe";
}
.icon-Folder-Open:before {
	content: "\e8ff";
}
.icon-Folder-Open2:before {
	content: "\e900";
}
.icon-Folder-Organizing:before {
	content: "\e901";
}
.icon-Folder-Pictures:before {
	content: "\e902";
}
.icon-Folder-Refresh:before {
	content: "\e903";
}
.icon-Folder-Remove-:before {
	content: "\e904";
}
.icon-Folder-Search:before {
	content: "\e905";
}
.icon-Folder-Settings:before {
	content: "\e906";
}
.icon-Folder-Share:before {
	content: "\e907";
}
.icon-Folder-Trash:before {
	content: "\e908";
}
.icon-Folder-Upload:before {
	content: "\e909";
}
.icon-Folder-Video:before {
	content: "\e90a";
}
.icon-Folder-WithDocument:before {
	content: "\e90b";
}
.icon-Folder-Zip:before {
	content: "\e90c";
}
.icon-Folder:before {
	content: "\e90d";
}
.icon-Folders:before {
	content: "\e90e";
}
.icon-Font-Color:before {
	content: "\e90f";
}
.icon-Font-Name:before {
	content: "\e910";
}
.icon-Font-Size:before {
	content: "\e911";
}
.icon-Font-Style:before {
	content: "\e912";
}
.icon-Font-StyleSubscript:before {
	content: "\e913";
}
.icon-Font-StyleSuperscript:before {
	content: "\e914";
}
.icon-Font-Window:before {
	content: "\e915";
}
.icon-Foot-2:before {
	content: "\e916";
}
.icon-Foot:before {
	content: "\e917";
}
.icon-Football-2:before {
	content: "\e918";
}
.icon-Football:before {
	content: "\e919";
}
.icon-Footprint-2:before {
	content: "\e91a";
}
.icon-Footprint-3:before {
	content: "\e91b";
}
.icon-Footprint:before {
	content: "\e91c";
}
.icon-Forest:before {
	content: "\e91d";
}
.icon-Fork:before {
	content: "\e91e";
}
.icon-Formspring:before {
	content: "\e91f";
}
.icon-Formula:before {
	content: "\e920";
}
.icon-Forsquare:before {
	content: "\e921";
}
.icon-Forward:before {
	content: "\e922";
}
.icon-Fountain-Pen:before {
	content: "\e923";
}
.icon-Four-Fingers:before {
	content: "\e924";
}
.icon-Four-FingersDrag:before {
	content: "\e925";
}
.icon-Four-FingersDrag2:before {
	content: "\e926";
}
.icon-Four-FingersTouch:before {
	content: "\e927";
}
.icon-Fox:before {
	content: "\e928";
}
.icon-Frankenstein:before {
	content: "\e929";
}
.icon-French-Fries:before {
	content: "\e92a";
}
.icon-Friendfeed:before {
	content: "\e92b";
}
.icon-Friendster:before {
	content: "\e92c";
}
.icon-Frog:before {
	content: "\e92d";
}
.icon-Fruits:before {
	content: "\e92e";
}
.icon-Fuel:before {
	content: "\e92f";
}
.icon-Full-Bag:before {
	content: "\e930";
}
.icon-Full-Basket:before {
	content: "\e931";
}
.icon-Full-Cart:before {
	content: "\e932";
}
.icon-Full-Moon:before {
	content: "\e933";
}
.icon-Full-Screen:before {
	content: "\e934";
}
.icon-Full-Screen2:before {
	content: "\e935";
}
.icon-Full-View:before {
	content: "\e936";
}
.icon-Full-View2:before {
	content: "\e937";
}
.icon-Full-ViewWindow:before {
	content: "\e938";
}
.icon-Function:before {
	content: "\e939";
}
.icon-Funky:before {
	content: "\e93a";
}
.icon-Funny-Bicycle:before {
	content: "\e93b";
}
.icon-Furl:before {
	content: "\e93c";
}
.icon-Gamepad-2:before {
	content: "\e93d";
}
.icon-Gamepad:before {
	content: "\e93e";
}
.icon-Gas-Pump:before {
	content: "\e93f";
}
.icon-Gaugage-2:before {
	content: "\e940";
}
.icon-Gaugage:before {
	content: "\e941";
}
.icon-Gay:before {
	content: "\e942";
}
.icon-Gear-2:before {
	content: "\e943";
}
.icon-Gear:before {
	content: "\e944";
}
.icon-Gears-2:before {
	content: "\e945";
}
.icon-Gears:before {
	content: "\e946";
}
.icon-Geek-2:before {
	content: "\e947";
}
.icon-Geek:before {
	content: "\e948";
}
.icon-Gemini-2:before {
	content: "\e949";
}
.icon-Gemini:before {
	content: "\e94a";
}
.icon-Genius:before {
	content: "\e94b";
}
.icon-Gentleman:before {
	content: "\e94c";
}
.icon-Geo--:before {
	content: "\e94d";
}
.icon-Geo-:before {
	content: "\e94e";
}
.icon-Geo-Close:before {
	content: "\e94f";
}
.icon-Geo-Love:before {
	content: "\e950";
}
.icon-Geo-Number:before {
	content: "\e951";
}
.icon-Geo-Star:before {
	content: "\e952";
}
.icon-Geo:before {
	content: "\e953";
}
.icon-Geo2--:before {
	content: "\e954";
}
.icon-Geo2-:before {
	content: "\e955";
}
.icon-Geo2-Close:before {
	content: "\e956";
}
.icon-Geo2-Love:before {
	content: "\e957";
}
.icon-Geo2-Number:before {
	content: "\e958";
}
.icon-Geo2-Star:before {
	content: "\e959";
}
.icon-Geo2:before {
	content: "\e95a";
}
.icon-Geo3--:before {
	content: "\e95b";
}
.icon-Geo3-:before {
	content: "\e95c";
}
.icon-Geo3-Close:before {
	content: "\e95d";
}
.icon-Geo3-Love:before {
	content: "\e95e";
}
.icon-Geo3-Number:before {
	content: "\e95f";
}
.icon-Geo3-Star:before {
	content: "\e960";
}
.icon-Geo3:before {
	content: "\e961";
}
.icon-Gey:before {
	content: "\e962";
}
.icon-Gift-Box:before {
	content: "\e963";
}
.icon-Giraffe:before {
	content: "\e964";
}
.icon-Girl:before {
	content: "\e965";
}
.icon-Glass-Water:before {
	content: "\e966";
}
.icon-Glasses-2:before {
	content: "\e967";
}
.icon-Glasses-3:before {
	content: "\e968";
}
.icon-Glasses:before {
	content: "\e969";
}
.icon-Global-Position:before {
	content: "\e96a";
}
.icon-Globe-2:before {
	content: "\e96b";
}
.icon-Globe:before {
	content: "\e96c";
}
.icon-Gloves:before {
	content: "\e96d";
}
.icon-Go-Bottom:before {
	content: "\e96e";
}
.icon-Go-Top:before {
	content: "\e96f";
}
.icon-Goggles:before {
	content: "\e970";
}
.icon-Golf-2:before {
	content: "\e971";
}
.icon-Golf:before {
	content: "\e972";
}
.icon-Google-Buzz:before {
	content: "\e973";
}
.icon-Google-Drive:before {
	content: "\e974";
}
.icon-Google-Play:before {
	content: "\e975";
}
.icon-Google-Plus:before {
	content: "\e976";
}
.icon-Google:before {
	content: "\e977";
}
.icon-Gopro:before {
	content: "\e978";
}
.icon-Gorilla:before {
	content: "\e979";
}
.icon-Gowalla:before {
	content: "\e97a";
}
.icon-Grave:before {
	content: "\e97b";
}
.icon-Graveyard:before {
	content: "\e97c";
}
.icon-Greece:before {
	content: "\e97d";
}
.icon-Green-Energy:before {
	content: "\e97e";
}
.icon-Green-House:before {
	content: "\e97f";
}
.icon-Guitar:before {
	content: "\e980";
}
.icon-Gun-2:before {
	content: "\e981";
}
.icon-Gun-3:before {
	content: "\e982";
}
.icon-Gun:before {
	content: "\e983";
}
.icon-Gymnastics:before {
	content: "\e984";
}
.icon-Hair-2:before {
	content: "\e985";
}
.icon-Hair-3:before {
	content: "\e986";
}
.icon-Hair-4:before {
	content: "\e987";
}
.icon-Hair:before {
	content: "\e988";
}
.icon-Half-Moon:before {
	content: "\e989";
}
.icon-Halloween-HalfMoon:before {
	content: "\e98a";
}
.icon-Halloween-Moon:before {
	content: "\e98b";
}
.icon-Hamburger:before {
	content: "\e98c";
}
.icon-Hammer:before {
	content: "\e98d";
}
.icon-Hand-Touch:before {
	content: "\e98e";
}
.icon-Hand-Touch2:before {
	content: "\e98f";
}
.icon-Hand-TouchSmartphone:before {
	content: "\e990";
}
.icon-Hand:before {
	content: "\e991";
}
.icon-Hands:before {
	content: "\e992";
}
.icon-Handshake:before {
	content: "\e993";
}
.icon-Hanger:before {
	content: "\e994";
}
.icon-Happy:before {
	content: "\e995";
}
.icon-Hat-2:before {
	content: "\e996";
}
.icon-Hat:before {
	content: "\e997";
}
.icon-Haunted-House:before {
	content: "\e998";
}
.icon-HD-Video:before {
	content: "\e999";
}
.icon-HD:before {
	content: "\e99a";
}
.icon-HDD:before {
	content: "\e99b";
}
.icon-Headphone:before {
	content: "\e99c";
}
.icon-Headphones:before {
	content: "\e99d";
}
.icon-Headset:before {
	content: "\e99e";
}
.icon-Heart-2:before {
	content: "\e99f";
}
.icon-Heart:before {
	content: "\e9a0";
}
.icon-Heels-2:before {
	content: "\e9a1";
}
.icon-Heels:before {
	content: "\e9a2";
}
.icon-Height-Window:before {
	content: "\e9a3";
}
.icon-Helicopter-2:before {
	content: "\e9a4";
}
.icon-Helicopter:before {
	content: "\e9a5";
}
.icon-Helix-2:before {
	content: "\e9a6";
}
.icon-Hello:before {
	content: "\e9a7";
}
.icon-Helmet-2:before {
	content: "\e9a8";
}
.icon-Helmet-3:before {
	content: "\e9a9";
}
.icon-Helmet:before {
	content: "\e9aa";
}
.icon-Hipo:before {
	content: "\e9ab";
}
.icon-Hipster-Glasses:before {
	content: "\e9ac";
}
.icon-Hipster-Glasses2:before {
	content: "\e9ad";
}
.icon-Hipster-Glasses3:before {
	content: "\e9ae";
}
.icon-Hipster-Headphones:before {
	content: "\e9af";
}
.icon-Hipster-Men:before {
	content: "\e9b0";
}
.icon-Hipster-Men2:before {
	content: "\e9b1";
}
.icon-Hipster-Men3:before {
	content: "\e9b2";
}
.icon-Hipster-Sunglasses:before {
	content: "\e9b3";
}
.icon-Hipster-Sunglasses2:before {
	content: "\e9b4";
}
.icon-Hipster-Sunglasses3:before {
	content: "\e9b5";
}
.icon-Hokey:before {
	content: "\e9b6";
}
.icon-Holly:before {
	content: "\e9b7";
}
.icon-Home-2:before {
	content: "\e9b8";
}
.icon-Home-3:before {
	content: "\e9b9";
}
.icon-Home-4:before {
	content: "\e9ba";
}
.icon-Home-5:before {
	content: "\e9bb";
}
.icon-Home-Window:before {
	content: "\e9bc";
}
.icon-Home:before {
	content: "\e9bd";
}
.icon-Homosexual:before {
	content: "\e9be";
}
.icon-Honey:before {
	content: "\e9bf";
}
.icon-Hong-Kong:before {
	content: "\e9c0";
}
.icon-Hoodie:before {
	content: "\e9c1";
}
.icon-Horror:before {
	content: "\e9c2";
}
.icon-Horse:before {
	content: "\e9c3";
}
.icon-Hospital-2:before {
	content: "\e9c4";
}
.icon-Hospital:before {
	content: "\e9c5";
}
.icon-Host:before {
	content: "\e9c6";
}
.icon-Hot-Dog:before {
	content: "\e9c7";
}
.icon-Hotel:before {
	content: "\e9c8";
}
.icon-Hour:before {
	content: "\e9c9";
}
.icon-Hub:before {
	content: "\e9ca";
}
.icon-Humor:before {
	content: "\e9cb";
}
.icon-Hurt:before {
	content: "\e9cc";
}
.icon-Ice-Cream:before {
	content: "\e9cd";
}
.icon-ICQ:before {
	content: "\e9ce";
}
.icon-ID-2:before {
	content: "\e9cf";
}
.icon-ID-3:before {
	content: "\e9d0";
}
.icon-ID-Card:before {
	content: "\e9d1";
}
.icon-Idea-2:before {
	content: "\e9d2";
}
.icon-Idea-3:before {
	content: "\e9d3";
}
.icon-Idea-4:before {
	content: "\e9d4";
}
.icon-Idea-5:before {
	content: "\e9d5";
}
.icon-Idea:before {
	content: "\e9d6";
}
.icon-Identification-Badge:before {
	content: "\e9d7";
}
.icon-ImDB:before {
	content: "\e9d8";
}
.icon-Inbox-Empty:before {
	content: "\e9d9";
}
.icon-Inbox-Forward:before {
	content: "\e9da";
}
.icon-Inbox-Full:before {
	content: "\e9db";
}
.icon-Inbox-Into:before {
	content: "\e9dc";
}
.icon-Inbox-Out:before {
	content: "\e9dd";
}
.icon-Inbox-Reply:before {
	content: "\e9de";
}
.icon-Inbox:before {
	content: "\e9df";
}
.icon-Increase-Inedit:before {
	content: "\e9e0";
}
.icon-Indent-FirstLine:before {
	content: "\e9e1";
}
.icon-Indent-LeftMargin:before {
	content: "\e9e2";
}
.icon-Indent-RightMargin:before {
	content: "\e9e3";
}
.icon-India:before {
	content: "\e9e4";
}
.icon-Info-Window:before {
	content: "\e9e5";
}
.icon-Information:before {
	content: "\e9e6";
}
.icon-Inifity:before {
	content: "\e9e7";
}
.icon-Instagram:before {
	content: "\e9e8";
}
.icon-Internet-2:before {
	content: "\e9e9";
}
.icon-Internet-Explorer:before {
	content: "\e9ea";
}
.icon-Internet-Smiley:before {
	content: "\e9eb";
}
.icon-Internet:before {
	content: "\e9ec";
}
.icon-iOS-Apple:before {
	content: "\e9ed";
}
.icon-Israel:before {
	content: "\e9ee";
}
.icon-Italic-Text:before {
	content: "\e9ef";
}
.icon-Jacket-2:before {
	content: "\e9f0";
}
.icon-Jacket:before {
	content: "\e9f1";
}
.icon-Jamaica:before {
	content: "\e9f2";
}
.icon-Japan:before {
	content: "\e9f3";
}
.icon-Japanese-Gate:before {
	content: "\e9f4";
}
.icon-Jeans:before {
	content: "\e9f5";
}
.icon-Jeep-2:before {
	content: "\e9f6";
}
.icon-Jeep:before {
	content: "\e9f7";
}
.icon-Jet:before {
	content: "\e9f8";
}
.icon-Joystick:before {
	content: "\e9f9";
}
.icon-Juice:before {
	content: "\e9fa";
}
.icon-Jump-Rope:before {
	content: "\e9fb";
}
.icon-Kangoroo:before {
	content: "\e9fc";
}
.icon-Kenya:before {
	content: "\e9fd";
}
.icon-Key-2:before {
	content: "\e9fe";
}
.icon-Key-3:before {
	content: "\e9ff";
}
.icon-Key-Lock:before {
	content: "\ea00";
}
.icon-Key:before {
	content: "\ea01";
}
.icon-Keyboard:before {
	content: "\ea02";
}
.icon-Keyboard3:before {
	content: "\ea03";
}
.icon-Keypad:before {
	content: "\ea04";
}
.icon-King-2:before {
	content: "\ea05";
}
.icon-King:before {
	content: "\ea06";
}
.icon-Kiss:before {
	content: "\ea07";
}
.icon-Knee:before {
	content: "\ea08";
}
.icon-Knife-2:before {
	content: "\ea09";
}
.icon-Knife:before {
	content: "\ea0a";
}
.icon-Knight:before {
	content: "\ea0b";
}
.icon-Koala:before {
	content: "\ea0c";
}
.icon-Korea:before {
	content: "\ea0d";
}
.icon-Lamp:before {
	content: "\ea0e";
}
.icon-Landscape-2:before {
	content: "\ea0f";
}
.icon-Landscape:before {
	content: "\ea10";
}
.icon-Lantern:before {
	content: "\ea11";
}
.icon-Laptop-2:before {
	content: "\ea12";
}
.icon-Laptop-3:before {
	content: "\ea13";
}
.icon-Laptop-Phone:before {
	content: "\ea14";
}
.icon-Laptop-Secure:before {
	content: "\ea15";
}
.icon-Laptop-Tablet:before {
	content: "\ea16";
}
.icon-Laptop:before {
	content: "\ea17";
}
.icon-Laser:before {
	content: "\ea18";
}
.icon-Last-FM:before {
	content: "\ea19";
}
.icon-Last:before {
	content: "\ea1a";
}
.icon-Laughing:before {
	content: "\ea1b";
}
.icon-Layer-1635:before {
	content: "\ea1c";
}
.icon-Layer-1646:before {
	content: "\ea1d";
}
.icon-Layer-Backward:before {
	content: "\ea1e";
}
.icon-Layer-Forward:before {
	content: "\ea1f";
}
.icon-Leafs-2:before {
	content: "\ea20";
}
.icon-Leafs:before {
	content: "\ea21";
}
.icon-Leaning-Tower:before {
	content: "\ea22";
}
.icon-Left--Right:before {
	content: "\ea23";
}
.icon-Left--Right3:before {
	content: "\ea24";
}
.icon-Left-2:before {
	content: "\ea25";
}
.icon-Left-3:before {
	content: "\ea26";
}
.icon-Left-4:before {
	content: "\ea27";
}
.icon-Left-ToRight:before {
	content: "\ea28";
}
.icon-Left:before {
	content: "\ea29";
}
.icon-Leg-2:before {
	content: "\ea2a";
}
.icon-Leg:before {
	content: "\ea2b";
}
.icon-Lego:before {
	content: "\ea2c";
}
.icon-Lemon:before {
	content: "\ea2d";
}
.icon-Len-2:before {
	content: "\ea2e";
}
.icon-Len-3:before {
	content: "\ea2f";
}
.icon-Len:before {
	content: "\ea30";
}
.icon-Leo-2:before {
	content: "\ea31";
}
.icon-Leo:before {
	content: "\ea32";
}
.icon-Leopard:before {
	content: "\ea33";
}
.icon-Lesbian:before {
	content: "\ea34";
}
.icon-Lesbians:before {
	content: "\ea35";
}
.icon-Letter-Close:before {
	content: "\ea36";
}
.icon-Letter-Open:before {
	content: "\ea37";
}
.icon-Letter-Sent:before {
	content: "\ea38";
}
.icon-Libra-2:before {
	content: "\ea39";
}
.icon-Libra:before {
	content: "\ea3a";
}
.icon-Library-2:before {
	content: "\ea3b";
}
.icon-Library:before {
	content: "\ea3c";
}
.icon-Life-Jacket:before {
	content: "\ea3d";
}
.icon-Life-Safer:before {
	content: "\ea3e";
}
.icon-Light-Bulb:before {
	content: "\ea3f";
}
.icon-Light-Bulb2:before {
	content: "\ea40";
}
.icon-Light-BulbLeaf:before {
	content: "\ea41";
}
.icon-Lighthouse:before {
	content: "\ea42";
}
.icon-Like-2:before {
	content: "\ea43";
}
.icon-Like:before {
	content: "\ea44";
}
.icon-Line-Chart:before {
	content: "\ea45";
}
.icon-Line-Chart2:before {
	content: "\ea46";
}
.icon-Line-Chart3:before {
	content: "\ea47";
}
.icon-Line-Chart4:before {
	content: "\ea48";
}
.icon-Line-Spacing:before {
	content: "\ea49";
}
.icon-Line-SpacingText:before {
	content: "\ea4a";
}
.icon-Link-2:before {
	content: "\ea4b";
}
.icon-Link:before {
	content: "\ea4c";
}
.icon-Linkedin-2:before {
	content: "\ea4d";
}
.icon-Linkedin:before {
	content: "\ea4e";
}
.icon-Linux:before {
	content: "\ea4f";
}
.icon-Lion:before {
	content: "\ea50";
}
.icon-Livejournal:before {
	content: "\ea51";
}
.icon-Loading-2:before {
	content: "\ea52";
}
.icon-Loading-3:before {
	content: "\ea53";
}
.icon-Loading-Window:before {
	content: "\ea54";
}
.icon-Loading:before {
	content: "\ea55";
}
.icon-Location-2:before {
	content: "\ea56";
}
.icon-Location:before {
	content: "\ea57";
}
.icon-Lock-2:before {
	content: "\ea58";
}
.icon-Lock-3:before {
	content: "\ea59";
}
.icon-Lock-User:before {
	content: "\ea5a";
}
.icon-Lock-Window:before {
	content: "\ea5b";
}
.icon-Lock:before {
	content: "\ea5c";
}
.icon-Lollipop-2:before {
	content: "\ea5d";
}
.icon-Lollipop-3:before {
	content: "\ea5e";
}
.icon-Lollipop:before {
	content: "\ea5f";
}
.icon-Loop:before {
	content: "\ea60";
}
.icon-Loud:before {
	content: "\ea61";
}
.icon-Loudspeaker:before {
	content: "\ea62";
}
.icon-Love-2:before {
	content: "\ea63";
}
.icon-Love-User:before {
	content: "\ea64";
}
.icon-Love-Window:before {
	content: "\ea65";
}
.icon-Love:before {
	content: "\ea66";
}
.icon-Lowercase-Text:before {
	content: "\ea67";
}
.icon-Luggafe-Front:before {
	content: "\ea68";
}
.icon-Luggage-2:before {
	content: "\ea69";
}
.icon-Macro:before {
	content: "\ea6a";
}
.icon-Magic-Wand:before {
	content: "\ea6b";
}
.icon-Magnet:before {
	content: "\ea6c";
}
.icon-Magnifi-Glass-:before {
	content: "\ea6d";
}
.icon-Magnifi-Glass:before {
	content: "\ea6e";
}
.icon-Magnifi-Glass2:before {
	content: "\ea6f";
}
.icon-Mail-2:before {
	content: "\ea70";
}
.icon-Mail-3:before {
	content: "\ea71";
}
.icon-Mail-Add:before {
	content: "\ea72";
}
.icon-Mail-Attachement:before {
	content: "\ea73";
}
.icon-Mail-Block:before {
	content: "\ea74";
}
.icon-Mail-Delete:before {
	content: "\ea75";
}
.icon-Mail-Favorite:before {
	content: "\ea76";
}
.icon-Mail-Forward:before {
	content: "\ea77";
}
.icon-Mail-Gallery:before {
	content: "\ea78";
}
.icon-Mail-Inbox:before {
	content: "\ea79";
}
.icon-Mail-Link:before {
	content: "\ea7a";
}
.icon-Mail-Lock:before {
	content: "\ea7b";
}
.icon-Mail-Love:before {
	content: "\ea7c";
}
.icon-Mail-Money:before {
	content: "\ea7d";
}
.icon-Mail-Open:before {
	content: "\ea7e";
}
.icon-Mail-Outbox:before {
	content: "\ea7f";
}
.icon-Mail-Password:before {
	content: "\ea80";
}
.icon-Mail-Photo:before {
	content: "\ea81";
}
.icon-Mail-Read:before {
	content: "\ea82";
}
.icon-Mail-Removex:before {
	content: "\ea83";
}
.icon-Mail-Reply:before {
	content: "\ea84";
}
.icon-Mail-ReplyAll:before {
	content: "\ea85";
}
.icon-Mail-Search:before {
	content: "\ea86";
}
.icon-Mail-Send:before {
	content: "\ea87";
}
.icon-Mail-Settings:before {
	content: "\ea88";
}
.icon-Mail-Unread:before {
	content: "\ea89";
}
.icon-Mail-Video:before {
	content: "\ea8a";
}
.icon-Mail-withAtSign:before {
	content: "\ea8b";
}
.icon-Mail-WithCursors:before {
	content: "\ea8c";
}
.icon-Mail:before {
	content: "\ea8d";
}
.icon-Mailbox-Empty:before {
	content: "\ea8e";
}
.icon-Mailbox-Full:before {
	content: "\ea8f";
}
.icon-Male-2:before {
	content: "\ea90";
}
.icon-Male-Sign:before {
	content: "\ea91";
}
.icon-Male:before {
	content: "\ea92";
}
.icon-MaleFemale:before {
	content: "\ea93";
}
.icon-Man-Sign:before {
	content: "\ea94";
}
.icon-Management:before {
	content: "\ea95";
}
.icon-Mans-Underwear:before {
	content: "\ea96";
}
.icon-Mans-Underwear2:before {
	content: "\ea97";
}
.icon-Map-Marker:before {
	content: "\ea98";
}
.icon-Map-Marker2:before {
	content: "\ea99";
}
.icon-Map-Marker3:before {
	content: "\ea9a";
}
.icon-Map:before {
	content: "\ea9b";
}
.icon-Map2:before {
	content: "\ea9c";
}
.icon-Marker-2:before {
	content: "\ea9d";
}
.icon-Marker-3:before {
	content: "\ea9e";
}
.icon-Marker:before {
	content: "\ea9f";
}
.icon-Martini-Glass:before {
	content: "\eaa0";
}
.icon-Mask:before {
	content: "\eaa1";
}
.icon-Master-Card:before {
	content: "\eaa2";
}
.icon-Maximize-Window:before {
	content: "\eaa3";
}
.icon-Maximize:before {
	content: "\eaa4";
}
.icon-Medal-2:before {
	content: "\eaa5";
}
.icon-Medal-3:before {
	content: "\eaa6";
}
.icon-Medal:before {
	content: "\eaa7";
}
.icon-Medical-Sign:before {
	content: "\eaa8";
}
.icon-Medicine-2:before {
	content: "\eaa9";
}
.icon-Medicine-3:before {
	content: "\eaaa";
}
.icon-Medicine:before {
	content: "\eaab";
}
.icon-Megaphone:before {
	content: "\eaac";
}
.icon-Memory-Card:before {
	content: "\eaad";
}
.icon-Memory-Card2:before {
	content: "\eaae";
}
.icon-Memory-Card3:before {
	content: "\eaaf";
}
.icon-Men:before {
	content: "\eab0";
}
.icon-Menorah:before {
	content: "\eab1";
}
.icon-Mens:before {
	content: "\eab2";
}
.icon-Metacafe:before {
	content: "\eab3";
}
.icon-Mexico:before {
	content: "\eab4";
}
.icon-Mic:before {
	content: "\eab5";
}
.icon-Microphone-2:before {
	content: "\eab6";
}
.icon-Microphone-3:before {
	content: "\eab7";
}
.icon-Microphone-4:before {
	content: "\eab8";
}
.icon-Microphone-5:before {
	content: "\eab9";
}
.icon-Microphone-6:before {
	content: "\eaba";
}
.icon-Microphone-7:before {
	content: "\eabb";
}
.icon-Microphone:before {
	content: "\eabc";
}
.icon-Microscope:before {
	content: "\eabd";
}
.icon-Milk-Bottle:before {
	content: "\eabe";
}
.icon-Mine:before {
	content: "\eabf";
}
.icon-Minimize-Maximize-Close-Window:before {
	content: "\eac0";
}
.icon-Minimize-Window:before {
	content: "\eac1";
}
.icon-Minimize:before {
	content: "\eac2";
}
.icon-Mirror:before {
	content: "\eac3";
}
.icon-Mixer:before {
	content: "\eac4";
}
.icon-Mixx:before {
	content: "\eac5";
}
.icon-Money-2:before {
	content: "\eac6";
}
.icon-Money-Bag:before {
	content: "\eac7";
}
.icon-Money-Smiley:before {
	content: "\eac8";
}
.icon-Money:before {
	content: "\eac9";
}
.icon-Monitor-2:before {
	content: "\eaca";
}
.icon-Monitor-3:before {
	content: "\eacb";
}
.icon-Monitor-4:before {
	content: "\eacc";
}
.icon-Monitor-5:before {
	content: "\eacd";
}
.icon-Monitor-Analytics:before {
	content: "\eace";
}
.icon-Monitor-Laptop:before {
	content: "\eacf";
}
.icon-Monitor-phone:before {
	content: "\ead0";
}
.icon-Monitor-Tablet:before {
	content: "\ead1";
}
.icon-Monitor-Vertical:before {
	content: "\ead2";
}
.icon-Monitor:before {
	content: "\ead3";
}
.icon-Monitoring:before {
	content: "\ead4";
}
.icon-Monkey:before {
	content: "\ead5";
}
.icon-Monster:before {
	content: "\ead6";
}
.icon-Morocco:before {
	content: "\ead7";
}
.icon-Motorcycle:before {
	content: "\ead8";
}
.icon-Mouse-2:before {
	content: "\ead9";
}
.icon-Mouse-3:before {
	content: "\eada";
}
.icon-Mouse-4:before {
	content: "\eadb";
}
.icon-Mouse-Pointer:before {
	content: "\eadc";
}
.icon-Mouse:before {
	content: "\eadd";
}
.icon-Moustache-Smiley:before {
	content: "\eade";
}
.icon-Movie-Ticket:before {
	content: "\eadf";
}
.icon-Movie:before {
	content: "\eae0";
}
.icon-Mp3-File:before {
	content: "\eae1";
}
.icon-Museum:before {
	content: "\eae2";
}
.icon-Mushroom:before {
	content: "\eae3";
}
.icon-Music-Note:before {
	content: "\eae4";
}
.icon-Music-Note2:before {
	content: "\eae5";
}
.icon-Music-Note3:before {
	content: "\eae6";
}
.icon-Music-Note4:before {
	content: "\eae7";
}
.icon-Music-Player:before {
	content: "\eae8";
}
.icon-Mustache-2:before {
	content: "\eae9";
}
.icon-Mustache-3:before {
	content: "\eaea";
}
.icon-Mustache-4:before {
	content: "\eaeb";
}
.icon-Mustache-5:before {
	content: "\eaec";
}
.icon-Mustache-6:before {
	content: "\eaed";
}
.icon-Mustache-7:before {
	content: "\eaee";
}
.icon-Mustache-8:before {
	content: "\eaef";
}
.icon-Mustache:before {
	content: "\eaf0";
}
.icon-Mute:before {
	content: "\eaf1";
}
.icon-Myspace:before {
	content: "\eaf2";
}
.icon-Navigat-Start:before {
	content: "\eaf3";
}
.icon-Navigate-End:before {
	content: "\eaf4";
}
.icon-Navigation-LeftWindow:before {
	content: "\eaf5";
}
.icon-Navigation-RightWindow:before {
	content: "\eaf6";
}
.icon-Nepal:before {
	content: "\eaf7";
}
.icon-Netscape:before {
	content: "\eaf8";
}
.icon-Network-Window:before {
	content: "\eaf9";
}
.icon-Network:before {
	content: "\eafa";
}
.icon-Neutron:before {
	content: "\eafb";
}
.icon-New-Mail:before {
	content: "\eafc";
}
.icon-New-Tab:before {
	content: "\eafd";
}
.icon-Newspaper-2:before {
	content: "\eafe";
}
.icon-Newspaper:before {
	content: "\eaff";
}
.icon-Newsvine:before {
	content: "\eb00";
}
.icon-Next2:before {
	content: "\eb01";
}
.icon-Next-3:before {
	content: "\eb02";
}
.icon-Next-Music:before {
	content: "\eb03";
}
.icon-Next:before {
	content: "\eb04";
}
.icon-No-Battery:before {
	content: "\eb05";
}
.icon-No-Drop:before {
	content: "\eb06";
}
.icon-No-Flash:before {
	content: "\eb07";
}
.icon-No-Smoking:before {
	content: "\eb08";
}
.icon-Noose:before {
	content: "\eb09";
}
.icon-Normal-Text:before {
	content: "\eb0a";
}
.icon-Note:before {
	content: "\eb0b";
}
.icon-Notepad-2:before {
	content: "\eb0c";
}
.icon-Notepad:before {
	content: "\eb0d";
}
.icon-Nuclear:before {
	content: "\eb0e";
}
.icon-Numbering-List:before {
	content: "\eb0f";
}
.icon-Nurse:before {
	content: "\eb10";
}
.icon-Office-Lamp:before {
	content: "\eb11";
}
.icon-Office:before {
	content: "\eb12";
}
.icon-Oil:before {
	content: "\eb13";
}
.icon-Old-Camera:before {
	content: "\eb14";
}
.icon-Old-Cassette:before {
	content: "\eb15";
}
.icon-Old-Clock:before {
	content: "\eb16";
}
.icon-Old-Radio:before {
	content: "\eb17";
}
.icon-Old-Sticky:before {
	content: "\eb18";
}
.icon-Old-Sticky2:before {
	content: "\eb19";
}
.icon-Old-Telephone:before {
	content: "\eb1a";
}
.icon-Old-TV:before {
	content: "\eb1b";
}
.icon-On-Air:before {
	content: "\eb1c";
}
.icon-On-Off-2:before {
	content: "\eb1d";
}
.icon-On-Off-3:before {
	content: "\eb1e";
}
.icon-On-off:before {
	content: "\eb1f";
}
.icon-One-Finger:before {
	content: "\eb20";
}
.icon-One-FingerTouch:before {
	content: "\eb21";
}
.icon-One-Window:before {
	content: "\eb22";
}
.icon-Open-Banana:before {
	content: "\eb23";
}
.icon-Open-Book:before {
	content: "\eb24";
}
.icon-Opera-House:before {
	content: "\eb25";
}
.icon-Opera:before {
	content: "\eb26";
}
.icon-Optimization:before {
	content: "\eb27";
}
.icon-Orientation-2:before {
	content: "\eb28";
}
.icon-Orientation-3:before {
	content: "\eb29";
}
.icon-Orientation:before {
	content: "\eb2a";
}
.icon-Orkut:before {
	content: "\eb2b";
}
.icon-Ornament:before {
	content: "\eb2c";
}
.icon-Over-Time:before {
	content: "\eb2d";
}
.icon-Over-Time2:before {
	content: "\eb2e";
}
.icon-Owl:before {
	content: "\eb2f";
}
.icon-Pac-Man:before {
	content: "\eb30";
}
.icon-Paint-Brush:before {
	content: "\eb31";
}
.icon-Paint-Bucket:before {
	content: "\eb32";
}
.icon-Paintbrush:before {
	content: "\eb33";
}
.icon-Palette:before {
	content: "\eb34";
}
.icon-Palm-Tree:before {
	content: "\eb35";
}
.icon-Panda:before {
	content: "\eb36";
}
.icon-Panorama:before {
	content: "\eb37";
}
.icon-Pantheon:before {
	content: "\eb38";
}
.icon-Pantone:before {
	content: "\eb39";
}
.icon-Pants:before {
	content: "\eb3a";
}
.icon-Paper-Plane:before {
	content: "\eb3b";
}
.icon-Paper:before {
	content: "\eb3c";
}
.icon-Parasailing:before {
	content: "\eb3d";
}
.icon-Parrot:before {
	content: "\eb3e";
}
.icon-Password-2shopping:before {
	content: "\eb3f";
}
.icon-Password-Field:before {
	content: "\eb40";
}
.icon-Password-shopping:before {
	content: "\eb41";
}
.icon-Password:before {
	content: "\eb42";
}
.icon-pause-2:before {
	content: "\eb43";
}
.icon-Pause:before {
	content: "\eb44";
}
.icon-Paw:before {
	content: "\eb45";
}
.icon-Pawn:before {
	content: "\eb46";
}
.icon-Paypal:before {
	content: "\eb47";
}
.icon-Pen-2:before {
	content: "\eb48";
}
.icon-Pen-3:before {
	content: "\eb49";
}
.icon-Pen-4:before {
	content: "\eb4a";
}
.icon-Pen-5:before {
	content: "\eb4b";
}
.icon-Pen-6:before {
	content: "\eb4c";
}
.icon-Pen:before {
	content: "\eb4d";
}
.icon-Pencil-Ruler:before {
	content: "\eb4e";
}
.icon-Pencil:before {
	content: "\eb4f";
}
.icon-Penguin:before {
	content: "\eb50";
}
.icon-Pentagon:before {
	content: "\eb51";
}
.icon-People-onCloud:before {
	content: "\eb52";
}
.icon-Pepper-withFire:before {
	content: "\eb53";
}
.icon-Pepper:before {
	content: "\eb54";
}
.icon-Petrol:before {
	content: "\eb55";
}
.icon-Petronas-Tower:before {
	content: "\eb56";
}
.icon-Philipines:before {
	content: "\eb57";
}
.icon-Phone-2:before {
	content: "\eb58";
}
.icon-Phone-3:before {
	content: "\eb59";
}
.icon-Phone-3G:before {
	content: "\eb5a";
}
.icon-Phone-4G:before {
	content: "\eb5b";
}
.icon-Phone-Simcard:before {
	content: "\eb5c";
}
.icon-Phone-SMS:before {
	content: "\eb5d";
}
.icon-Phone-Wifi:before {
	content: "\eb5e";
}
.icon-Phone:before {
	content: "\eb5f";
}
.icon-Photo-2:before {
	content: "\eb60";
}
.icon-Photo-3:before {
	content: "\eb61";
}
.icon-Photo-Album:before {
	content: "\eb62";
}
.icon-Photo-Album2:before {
	content: "\eb63";
}
.icon-Photo-Album3:before {
	content: "\eb64";
}
.icon-Photo:before {
	content: "\eb65";
}
.icon-Photos:before {
	content: "\eb66";
}
.icon-Physics:before {
	content: "\eb67";
}
.icon-Pi:before {
	content: "\eb68";
}
.icon-Piano:before {
	content: "\eb69";
}
.icon-Picasa:before {
	content: "\eb6a";
}
.icon-Pie-Chart:before {
	content: "\eb6b";
}
.icon-Pie-Chart2:before {
	content: "\eb6c";
}
.icon-Pie-Chart3:before {
	content: "\eb6d";
}
.icon-Pilates-2:before {
	content: "\eb6e";
}
.icon-Pilates-3:before {
	content: "\eb6f";
}
.icon-Pilates:before {
	content: "\eb70";
}
.icon-Pilot:before {
	content: "\eb71";
}
.icon-Pinch:before {
	content: "\eb72";
}
.icon-Ping-Pong:before {
	content: "\eb73";
}
.icon-Pinterest:before {
	content: "\eb74";
}
.icon-Pipe:before {
	content: "\eb75";
}
.icon-Pipette:before {
	content: "\eb76";
}
.icon-Piramids:before {
	content: "\eb77";
}
.icon-Pisces-2:before {
	content: "\eb78";
}
.icon-Pisces:before {
	content: "\eb79";
}
.icon-Pizza-Slice:before {
	content: "\eb7a";
}
.icon-Pizza:before {
	content: "\eb7b";
}
.icon-Plane-2:before {
	content: "\eb7c";
}
.icon-Plane:before {
	content: "\eb7d";
}
.icon-Plant:before {
	content: "\eb7e";
}
.icon-Plasmid:before {
	content: "\eb7f";
}
.icon-Plaster:before {
	content: "\eb80";
}
.icon-Plastic-CupPhone:before {
	content: "\eb81";
}
.icon-Plastic-CupPhone2:before {
	content: "\eb82";
}
.icon-Plate:before {
	content: "\eb83";
}
.icon-Plates:before {
	content: "\eb84";
}
.icon-Plaxo:before {
	content: "\eb85";
}
.icon-Play-Music:before {
	content: "\eb86";
}
.icon-Plug-In:before {
	content: "\eb87";
}
.icon-Plug-In2:before {
	content: "\eb88";
}
.icon-Plurk:before {
	content: "\eb89";
}
.icon-Pointer:before {
	content: "\eb8a";
}
.icon-Poland:before {
	content: "\eb8b";
}
.icon-Police-Man:before {
	content: "\eb8c";
}
.icon-Police-Station:before {
	content: "\eb8d";
}
.icon-Police-Woman:before {
	content: "\eb8e";
}
.icon-Police:before {
	content: "\eb8f";
}
.icon-Polo-Shirt:before {
	content: "\eb90";
}
.icon-Portrait:before {
	content: "\eb91";
}
.icon-Portugal:before {
	content: "\eb92";
}
.icon-Post-Mail:before {
	content: "\eb93";
}
.icon-Post-Mail2:before {
	content: "\eb94";
}
.icon-Post-Office:before {
	content: "\eb95";
}
.icon-Post-Sign:before {
	content: "\eb96";
}
.icon-Post-Sign2ways:before {
	content: "\eb97";
}
.icon-Posterous:before {
	content: "\eb98";
}
.icon-Pound-Sign:before {
	content: "\eb99";
}
.icon-Pound-Sign2:before {
	content: "\eb9a";
}
.icon-Pound:before {
	content: "\eb9b";
}
.icon-Power-2:before {
	content: "\eb9c";
}
.icon-Power-3:before {
	content: "\eb9d";
}
.icon-Power-Cable:before {
	content: "\eb9e";
}
.icon-Power-Station:before {
	content: "\eb9f";
}
.icon-Power:before {
	content: "\eba0";
}
.icon-Prater:before {
	content: "\eba1";
}
.icon-Present:before {
	content: "\eba2";
}
.icon-Presents:before {
	content: "\eba3";
}
.icon-Press:before {
	content: "\eba4";
}
.icon-Preview:before {
	content: "\eba5";
}
.icon-Previous:before {
	content: "\eba6";
}
.icon-Pricing:before {
	content: "\eba7";
}
.icon-Printer:before {
	content: "\eba8";
}
.icon-Professor:before {
	content: "\eba9";
}
.icon-Profile:before {
	content: "\ebaa";
}
.icon-Project:before {
	content: "\ebab";
}
.icon-Projector-2:before {
	content: "\ebac";
}
.icon-Projector:before {
	content: "\ebad";
}
.icon-Pulse:before {
	content: "\ebae";
}
.icon-Pumpkin:before {
	content: "\ebaf";
}
.icon-Punk:before {
	content: "\ebb0";
}
.icon-Punker:before {
	content: "\ebb1";
}
.icon-Puzzle:before {
	content: "\ebb2";
}
.icon-QIK:before {
	content: "\ebb3";
}
.icon-QR-Code:before {
	content: "\ebb4";
}
.icon-Queen-2:before {
	content: "\ebb5";
}
.icon-Queen:before {
	content: "\ebb6";
}
.icon-Quill-2:before {
	content: "\ebb7";
}
.icon-Quill-3:before {
	content: "\ebb8";
}
.icon-Quill:before {
	content: "\ebb9";
}
.icon-Quotes-2:before {
	content: "\ebba";
}
.icon-Quotes:before {
	content: "\ebbb";
}
.icon-Radio:before {
	content: "\ebbc";
}
.icon-Radioactive:before {
	content: "\ebbd";
}
.icon-Rafting:before {
	content: "\ebbe";
}
.icon-Rain-Drop:before {
	content: "\ebbf";
}
.icon-Rainbow-2:before {
	content: "\ebc0";
}
.icon-Rainbow:before {
	content: "\ebc1";
}
.icon-Ram:before {
	content: "\ebc2";
}
.icon-Razzor-Blade:before {
	content: "\ebc3";
}
.icon-Receipt-2:before {
	content: "\ebc4";
}
.icon-Receipt-3:before {
	content: "\ebc5";
}
.icon-Receipt-4:before {
	content: "\ebc6";
}
.icon-Receipt:before {
	content: "\ebc7";
}
.icon-Record2:before {
	content: "\ebc8";
}
.icon-Record-3:before {
	content: "\ebc9";
}
.icon-Record-Music:before {
	content: "\ebca";
}
.icon-Record:before {
	content: "\ebcb";
}
.icon-Recycling-2:before {
	content: "\ebcc";
}
.icon-Recycling:before {
	content: "\ebcd";
}
.icon-Reddit:before {
	content: "\ebce";
}
.icon-Redhat:before {
	content: "\ebcf";
}
.icon-Redirect:before {
	content: "\ebd0";
}
.icon-Redo:before {
	content: "\ebd1";
}
.icon-Reel:before {
	content: "\ebd2";
}
.icon-Refinery:before {
	content: "\ebd3";
}
.icon-Refresh-Window:before {
	content: "\ebd4";
}
.icon-Refresh:before {
	content: "\ebd5";
}
.icon-Reload-2:before {
	content: "\ebd6";
}
.icon-Reload-3:before {
	content: "\ebd7";
}
.icon-Reload:before {
	content: "\ebd8";
}
.icon-Remote-Controll:before {
	content: "\ebd9";
}
.icon-Remote-Controll2:before {
	content: "\ebda";
}
.icon-Remove-Bag:before {
	content: "\ebdb";
}
.icon-Remove-Basket:before {
	content: "\ebdc";
}
.icon-Remove-Cart:before {
	content: "\ebdd";
}
.icon-Remove-File:before {
	content: "\ebde";
}
.icon-Remove-User:before {
	content: "\ebdf";
}
.icon-Remove-Window:before {
	content: "\ebe0";
}
.icon-Remove:before {
	content: "\ebe1";
}
.icon-Rename:before {
	content: "\ebe2";
}
.icon-Repair:before {
	content: "\ebe3";
}
.icon-Repeat-2:before {
	content: "\ebe4";
}
.icon-Repeat-3:before {
	content: "\ebe5";
}
.icon-Repeat-4:before {
	content: "\ebe6";
}
.icon-Repeat-5:before {
	content: "\ebe7";
}
.icon-Repeat-6:before {
	content: "\ebe8";
}
.icon-Repeat-7:before {
	content: "\ebe9";
}
.icon-Repeat:before {
	content: "\ebea";
}
.icon-Reset:before {
	content: "\ebeb";
}
.icon-Resize:before {
	content: "\ebec";
}
.icon-Restore-Window:before {
	content: "\ebed";
}
.icon-Retouching:before {
	content: "\ebee";
}
.icon-Retro-Camera:before {
	content: "\ebef";
}
.icon-Retro:before {
	content: "\ebf0";
}
.icon-Retweet:before {
	content: "\ebf1";
}
.icon-Reverbnation:before {
	content: "\ebf2";
}
.icon-Rewind:before {
	content: "\ebf3";
}
.icon-RGB:before {
	content: "\ebf4";
}
.icon-Ribbon-2:before {
	content: "\ebf5";
}
.icon-Ribbon-3:before {
	content: "\ebf6";
}
.icon-Ribbon:before {
	content: "\ebf7";
}
.icon-Right-2:before {
	content: "\ebf8";
}
.icon-Right-3:before {
	content: "\ebf9";
}
.icon-Right-4:before {
	content: "\ebfa";
}
.icon-Right-ToLeft:before {
	content: "\ebfb";
}
.icon-Right:before {
	content: "\ebfc";
}
.icon-Road-2:before {
	content: "\ebfd";
}
.icon-Road-3:before {
	content: "\ebfe";
}
.icon-Road:before {
	content: "\ebff";
}
.icon-Robot-2:before {
	content: "\ec00";
}
.icon-Robot:before {
	content: "\ec01";
}
.icon-Rock-andRoll:before {
	content: "\ec02";
}
.icon-Rocket:before {
	content: "\ec03";
}
.icon-Roller:before {
	content: "\ec04";
}
.icon-Roof:before {
	content: "\ec05";
}
.icon-Rook:before {
	content: "\ec06";
}
.icon-Rotate-Gesture:before {
	content: "\ec07";
}
.icon-Rotate-Gesture2:before {
	content: "\ec08";
}
.icon-Rotate-Gesture3:before {
	content: "\ec09";
}
.icon-Rotation-390:before {
	content: "\ec0a";
}
.icon-Rotation:before {
	content: "\ec0b";
}
.icon-Router-2:before {
	content: "\ec0c";
}
.icon-Router:before {
	content: "\ec0d";
}
.icon-RSS:before {
	content: "\ec0e";
}
.icon-Ruler-2:before {
	content: "\ec0f";
}
.icon-Ruler:before {
	content: "\ec10";
}
.icon-Running-Shoes:before {
	content: "\ec11";
}
.icon-Running:before {
	content: "\ec12";
}
.icon-Safari:before {
	content: "\ec13";
}
.icon-Safe-Box:before {
	content: "\ec14";
}
.icon-Safe-Box2:before {
	content: "\ec15";
}
.icon-Safety-PinClose:before {
	content: "\ec16";
}
.icon-Safety-PinOpen:before {
	content: "\ec17";
}
.icon-Sagittarus-2:before {
	content: "\ec18";
}
.icon-Sagittarus:before {
	content: "\ec19";
}
.icon-Sailing-Ship:before {
	content: "\ec1a";
}
.icon-Sand-watch:before {
	content: "\ec1b";
}
.icon-Sand-watch2:before {
	content: "\ec1c";
}
.icon-Santa-Claus:before {
	content: "\ec1d";
}
.icon-Santa-Claus2:before {
	content: "\ec1e";
}
.icon-Santa-onSled:before {
	content: "\ec1f";
}
.icon-Satelite-2:before {
	content: "\ec20";
}
.icon-Satelite:before {
	content: "\ec21";
}
.icon-Save-Window:before {
	content: "\ec22";
}
.icon-Save:before {
	content: "\ec23";
}
.icon-Saw:before {
	content: "\ec24";
}
.icon-Saxophone:before {
	content: "\ec25";
}
.icon-Scale:before {
	content: "\ec26";
}
.icon-Scarf:before {
	content: "\ec27";
}
.icon-Scissor:before {
	content: "\ec28";
}
.icon-Scooter-Front:before {
	content: "\ec29";
}
.icon-Scooter:before {
	content: "\ec2a";
}
.icon-Scorpio-2:before {
	content: "\ec2b";
}
.icon-Scorpio:before {
	content: "\ec2c";
}
.icon-Scotland:before {
	content: "\ec2d";
}
.icon-Screwdriver:before {
	content: "\ec2e";
}
.icon-Scroll-Fast:before {
	content: "\ec2f";
}
.icon-Scroll:before {
	content: "\ec30";
}
.icon-Scroller-2:before {
	content: "\ec31";
}
.icon-Scroller:before {
	content: "\ec32";
}
.icon-Sea-Dog:before {
	content: "\ec33";
}
.icon-Search-onCloud:before {
	content: "\ec34";
}
.icon-Search-People:before {
	content: "\ec35";
}
.icon-secound:before {
	content: "\ec36";
}
.icon-secound2:before {
	content: "\ec37";
}
.icon-Security-Block:before {
	content: "\ec38";
}
.icon-Security-Bug:before {
	content: "\ec39";
}
.icon-Security-Camera:before {
	content: "\ec3a";
}
.icon-Security-Check:before {
	content: "\ec3b";
}
.icon-Security-Settings:before {
	content: "\ec3c";
}
.icon-Security-Smiley:before {
	content: "\ec3d";
}
.icon-Securiy-Remove:before {
	content: "\ec3e";
}
.icon-Seed:before {
	content: "\ec3f";
}
.icon-Selfie:before {
	content: "\ec40";
}
.icon-Serbia:before {
	content: "\ec41";
}
.icon-Server-2:before {
	content: "\ec42";
}
.icon-Server:before {
	content: "\ec43";
}
.icon-Servers:before {
	content: "\ec44";
}
.icon-Settings-Window:before {
	content: "\ec45";
}
.icon-Sewing-Machine:before {
	content: "\ec46";
}
.icon-Sexual:before {
	content: "\ec47";
}
.icon-Share-onCloud:before {
	content: "\ec48";
}
.icon-Share-Window:before {
	content: "\ec49";
}
.icon-Share:before {
	content: "\ec4a";
}
.icon-Sharethis:before {
	content: "\ec4b";
}
.icon-Shark:before {
	content: "\ec4c";
}
.icon-Sheep:before {
	content: "\ec4d";
}
.icon-Sheriff-Badge:before {
	content: "\ec4e";
}
.icon-Shield:before {
	content: "\ec4f";
}
.icon-Ship-2:before {
	content: "\ec50";
}
.icon-Ship:before {
	content: "\ec51";
}
.icon-Shirt:before {
	content: "\ec52";
}
.icon-Shoes-2:before {
	content: "\ec53";
}
.icon-Shoes-3:before {
	content: "\ec54";
}
.icon-Shoes:before {
	content: "\ec55";
}
.icon-Shop-2:before {
	content: "\ec56";
}
.icon-Shop-3:before {
	content: "\ec57";
}
.icon-Shop-4:before {
	content: "\ec58";
}
.icon-Shop:before {
	content: "\ec59";
}
.icon-Shopping-Bag:before {
	content: "\ec5a";
}
.icon-Shopping-Basket:before {
	content: "\ec5b";
}
.icon-Shopping-Cart:before {
	content: "\ec5c";
}
.icon-Short-Pants:before {
	content: "\ec5d";
}
.icon-Shoutwire:before {
	content: "\ec5e";
}
.icon-Shovel:before {
	content: "\ec5f";
}
.icon-Shuffle-2:before {
	content: "\ec60";
}
.icon-Shuffle-3:before {
	content: "\ec61";
}
.icon-Shuffle-4:before {
	content: "\ec62";
}
.icon-Shuffle:before {
	content: "\ec63";
}
.icon-Shutter:before {
	content: "\ec64";
}
.icon-Sidebar-Window:before {
	content: "\ec65";
}
.icon-Signal:before {
	content: "\ec66";
}
.icon-Singapore:before {
	content: "\ec67";
}
.icon-Skate-Shoes:before {
	content: "\ec68";
}
.icon-Skateboard-2:before {
	content: "\ec69";
}
.icon-Skateboard:before {
	content: "\ec6a";
}
.icon-Skeleton:before {
	content: "\ec6b";
}
.icon-Ski:before {
	content: "\ec6c";
}
.icon-Skirt:before {
	content: "\ec6d";
}
.icon-Skrill:before {
	content: "\ec6e";
}
.icon-Skull:before {
	content: "\ec6f";
}
.icon-Skydiving:before {
	content: "\ec70";
}
.icon-Skype:before {
	content: "\ec71";
}
.icon-Sled-withGifts:before {
	content: "\ec72";
}
.icon-Sled:before {
	content: "\ec73";
}
.icon-Sleeping:before {
	content: "\ec74";
}
.icon-Sleet:before {
	content: "\ec75";
}
.icon-Slippers:before {
	content: "\ec76";
}
.icon-Smart:before {
	content: "\ec77";
}
.icon-Smartphone-2:before {
	content: "\ec78";
}
.icon-Smartphone-3:before {
	content: "\ec79";
}
.icon-Smartphone-4:before {
	content: "\ec7a";
}
.icon-Smartphone-Secure:before {
	content: "\ec7b";
}
.icon-Smartphone:before {
	content: "\ec7c";
}
.icon-Smile:before {
	content: "\ec7d";
}
.icon-Smoking-Area:before {
	content: "\ec7e";
}
.icon-Smoking-Pipe:before {
	content: "\ec7f";
}
.icon-Snake:before {
	content: "\ec80";
}
.icon-Snorkel:before {
	content: "\ec81";
}
.icon-Snow-2:before {
	content: "\ec82";
}
.icon-Snow-Dome:before {
	content: "\ec83";
}
.icon-Snow-Storm:before {
	content: "\ec84";
}
.icon-Snow:before {
	content: "\ec85";
}
.icon-Snowflake-2:before {
	content: "\ec86";
}
.icon-Snowflake-3:before {
	content: "\ec87";
}
.icon-Snowflake-4:before {
	content: "\ec88";
}
.icon-Snowflake:before {
	content: "\ec89";
}
.icon-Snowman:before {
	content: "\ec8a";
}
.icon-Soccer-Ball:before {
	content: "\ec8b";
}
.icon-Soccer-Shoes:before {
	content: "\ec8c";
}
.icon-Socks:before {
	content: "\ec8d";
}
.icon-Solar:before {
	content: "\ec8e";
}
.icon-Sound-Wave:before {
	content: "\ec8f";
}
.icon-Sound:before {
	content: "\ec90";
}
.icon-Soundcloud:before {
	content: "\ec91";
}
.icon-Soup:before {
	content: "\ec92";
}
.icon-South-Africa:before {
	content: "\ec93";
}
.icon-Space-Needle:before {
	content: "\ec94";
}
.icon-Spain:before {
	content: "\ec95";
}
.icon-Spam-Mail:before {
	content: "\ec96";
}
.icon-Speach-Bubble:before {
	content: "\ec97";
}
.icon-Speach-Bubble2:before {
	content: "\ec98";
}
.icon-Speach-Bubble3:before {
	content: "\ec99";
}
.icon-Speach-Bubble4:before {
	content: "\ec9a";
}
.icon-Speach-Bubble5:before {
	content: "\ec9b";
}
.icon-Speach-Bubble6:before {
	content: "\ec9c";
}
.icon-Speach-Bubble7:before {
	content: "\ec9d";
}
.icon-Speach-Bubble8:before {
	content: "\ec9e";
}
.icon-Speach-Bubble9:before {
	content: "\ec9f";
}
.icon-Speach-Bubble10:before {
	content: "\eca0";
}
.icon-Speach-Bubble11:before {
	content: "\eca1";
}
.icon-Speach-Bubble12:before {
	content: "\eca2";
}
.icon-Speach-Bubble13:before {
	content: "\eca3";
}
.icon-Speach-BubbleAsking:before {
	content: "\eca4";
}
.icon-Speach-BubbleComic:before {
	content: "\eca5";
}
.icon-Speach-BubbleComic2:before {
	content: "\eca6";
}
.icon-Speach-BubbleComic3:before {
	content: "\eca7";
}
.icon-Speach-BubbleComic4:before {
	content: "\eca8";
}
.icon-Speach-BubbleDialog:before {
	content: "\eca9";
}
.icon-Speach-Bubbles:before {
	content: "\ecaa";
}
.icon-Speak-2:before {
	content: "\ecab";
}
.icon-Speak:before {
	content: "\ecac";
}
.icon-Speaker-2:before {
	content: "\ecad";
}
.icon-Speaker:before {
	content: "\ecae";
}
.icon-Spell-Check:before {
	content: "\ecaf";
}
.icon-Spell-CheckABC:before {
	content: "\ecb0";
}
.icon-Spermium:before {
	content: "\ecb1";
}
.icon-Spider:before {
	content: "\ecb2";
}
.icon-Spiderweb:before {
	content: "\ecb3";
}
.icon-Split-FourSquareWindow:before {
	content: "\ecb4";
}
.icon-Split-Horizontal:before {
	content: "\ecb5";
}
.icon-Split-Horizontal2Window:before {
	content: "\ecb6";
}
.icon-Split-Vertical:before {
	content: "\ecb7";
}
.icon-Split-Vertical2:before {
	content: "\ecb8";
}
.icon-Split-Window:before {
	content: "\ecb9";
}
.icon-Spoder:before {
	content: "\ecba";
}
.icon-Spoon:before {
	content: "\ecbb";
}
.icon-Sport-Mode:before {
	content: "\ecbc";
}
.icon-Sports-Clothings1:before {
	content: "\ecbd";
}
.icon-Sports-Clothings2:before {
	content: "\ecbe";
}
.icon-Sports-Shirt:before {
	content: "\ecbf";
}
.icon-Spot:before {
	content: "\ecc0";
}
.icon-Spray:before {
	content: "\ecc1";
}
.icon-Spread:before {
	content: "\ecc2";
}
.icon-Spring:before {
	content: "\ecc3";
}
.icon-Spurl:before {
	content: "\ecc4";
}
.icon-Spy:before {
	content: "\ecc5";
}
.icon-Squirrel:before {
	content: "\ecc6";
}
.icon-SSL:before {
	content: "\ecc7";
}
.icon-St-BasilsCathedral:before {
	content: "\ecc8";
}
.icon-St-PaulsCathedral:before {
	content: "\ecc9";
}
.icon-Stamp-2:before {
	content: "\ecca";
}
.icon-Stamp:before {
	content: "\eccb";
}
.icon-Stapler:before {
	content: "\eccc";
}
.icon-Star-Track:before {
	content: "\eccd";
}
.icon-Star:before {
	content: "\ecce";
}
.icon-Starfish:before {
	content: "\eccf";
}
.icon-Start2:before {
	content: "\ecd0";
}
.icon-Start-3:before {
	content: "\ecd1";
}
.icon-Start-ways:before {
	content: "\ecd2";
}
.icon-Start:before {
	content: "\ecd3";
}
.icon-Statistic:before {
	content: "\ecd4";
}
.icon-Stethoscope:before {
	content: "\ecd5";
}
.icon-stop--2:before {
	content: "\ecd6";
}
.icon-Stop-Music:before {
	content: "\ecd7";
}
.icon-Stop:before {
	content: "\ecd8";
}
.icon-Stopwatch-2:before {
	content: "\ecd9";
}
.icon-Stopwatch:before {
	content: "\ecda";
}
.icon-Storm:before {
	content: "\ecdb";
}
.icon-Street-View:before {
	content: "\ecdc";
}
.icon-Street-View2:before {
	content: "\ecdd";
}
.icon-Strikethrough-Text:before {
	content: "\ecde";
}
.icon-Stroller:before {
	content: "\ecdf";
}
.icon-Structure:before {
	content: "\ece0";
}
.icon-Student-Female:before {
	content: "\ece1";
}
.icon-Student-Hat:before {
	content: "\ece2";
}
.icon-Student-Hat2:before {
	content: "\ece3";
}
.icon-Student-Male:before {
	content: "\ece4";
}
.icon-Student-MaleFemale:before {
	content: "\ece5";
}
.icon-Students:before {
	content: "\ece6";
}
.icon-Studio-Flash:before {
	content: "\ece7";
}
.icon-Studio-Lightbox:before {
	content: "\ece8";
}
.icon-Stumbleupon:before {
	content: "\ece9";
}
.icon-Suit:before {
	content: "\ecea";
}
.icon-Suitcase:before {
	content: "\eceb";
}
.icon-Sum-2:before {
	content: "\ecec";
}
.icon-Sum:before {
	content: "\eced";
}
.icon-Summer:before {
	content: "\ecee";
}
.icon-Sun-CloudyRain:before {
	content: "\ecef";
}
.icon-Sun:before {
	content: "\ecf0";
}
.icon-Sunglasses-2:before {
	content: "\ecf1";
}
.icon-Sunglasses-3:before {
	content: "\ecf2";
}
.icon-Sunglasses-Smiley:before {
	content: "\ecf3";
}
.icon-Sunglasses-Smiley2:before {
	content: "\ecf4";
}
.icon-Sunglasses-W:before {
	content: "\ecf5";
}
.icon-Sunglasses-W2:before {
	content: "\ecf6";
}
.icon-Sunglasses-W3:before {
	content: "\ecf7";
}
.icon-Sunglasses:before {
	content: "\ecf8";
}
.icon-Sunrise:before {
	content: "\ecf9";
}
.icon-Sunset:before {
	content: "\ecfa";
}
.icon-Superman:before {
	content: "\ecfb";
}
.icon-Support:before {
	content: "\ecfc";
}
.icon-Surprise:before {
	content: "\ecfd";
}
.icon-Sushi:before {
	content: "\ecfe";
}
.icon-Sweden:before {
	content: "\ecff";
}
.icon-Swimming-Short:before {
	content: "\ed00";
}
.icon-Swimming:before {
	content: "\ed01";
}
.icon-Swimmwear:before {
	content: "\ed02";
}
.icon-Switch:before {
	content: "\ed03";
}
.icon-Switzerland:before {
	content: "\ed04";
}
.icon-Sync-Cloud:before {
	content: "\ed05";
}
.icon-Sync:before {
	content: "\ed06";
}
.icon-Synchronize-2:before {
	content: "\ed07";
}
.icon-Synchronize:before {
	content: "\ed08";
}
.icon-T-Shirt:before {
	content: "\ed09";
}
.icon-Tablet-2:before {
	content: "\ed0a";
}
.icon-Tablet-3:before {
	content: "\ed0b";
}
.icon-Tablet-Orientation:before {
	content: "\ed0c";
}
.icon-Tablet-Phone:before {
	content: "\ed0d";
}
.icon-Tablet-Secure:before {
	content: "\ed0e";
}
.icon-Tablet-Vertical:before {
	content: "\ed0f";
}
.icon-Tablet:before {
	content: "\ed10";
}
.icon-Tactic:before {
	content: "\ed11";
}
.icon-Tag-2:before {
	content: "\ed12";
}
.icon-Tag-3:before {
	content: "\ed13";
}
.icon-Tag-4:before {
	content: "\ed14";
}
.icon-Tag-5:before {
	content: "\ed15";
}
.icon-Tag:before {
	content: "\ed16";
}
.icon-Taj-Mahal:before {
	content: "\ed17";
}
.icon-Talk-Man:before {
	content: "\ed18";
}
.icon-Tap:before {
	content: "\ed19";
}
.icon-Target-Market:before {
	content: "\ed1a";
}
.icon-Target:before {
	content: "\ed1b";
}
.icon-Taurus-2:before {
	content: "\ed1c";
}
.icon-Taurus:before {
	content: "\ed1d";
}
.icon-Taxi-2:before {
	content: "\ed1e";
}
.icon-Taxi-Sign:before {
	content: "\ed1f";
}
.icon-Taxi:before {
	content: "\ed20";
}
.icon-Teacher:before {
	content: "\ed21";
}
.icon-Teapot:before {
	content: "\ed22";
}
.icon-Technorati:before {
	content: "\ed23";
}
.icon-Teddy-Bear:before {
	content: "\ed24";
}
.icon-Tee-Mug:before {
	content: "\ed25";
}
.icon-Telephone-2:before {
	content: "\ed26";
}
.icon-Telephone:before {
	content: "\ed27";
}
.icon-Telescope:before {
	content: "\ed28";
}
.icon-Temperature-2:before {
	content: "\ed29";
}
.icon-Temperature-3:before {
	content: "\ed2a";
}
.icon-Temperature:before {
	content: "\ed2b";
}
.icon-Temple:before {
	content: "\ed2c";
}
.icon-Tennis-Ball:before {
	content: "\ed2d";
}
.icon-Tennis:before {
	content: "\ed2e";
}
.icon-Tent:before {
	content: "\ed2f";
}
.icon-Test-Tube:before {
	content: "\ed30";
}
.icon-Test-Tube2:before {
	content: "\ed31";
}
.icon-Testimonal:before {
	content: "\ed32";
}
.icon-Text-Box:before {
	content: "\ed33";
}
.icon-Text-Effect:before {
	content: "\ed34";
}
.icon-Text-HighlightColor:before {
	content: "\ed35";
}
.icon-Text-Paragraph:before {
	content: "\ed36";
}
.icon-Thailand:before {
	content: "\ed37";
}
.icon-The-WhiteHouse:before {
	content: "\ed38";
}
.icon-This-SideUp:before {
	content: "\ed39";
}
.icon-Thread:before {
	content: "\ed3a";
}
.icon-Three-ArrowFork:before {
	content: "\ed3b";
}
.icon-Three-Fingers:before {
	content: "\ed3c";
}
.icon-Three-FingersDrag:before {
	content: "\ed3d";
}
.icon-Three-FingersDrag2:before {
	content: "\ed3e";
}
.icon-Three-FingersTouch:before {
	content: "\ed3f";
}
.icon-Thumb:before {
	content: "\ed40";
}
.icon-Thumbs-DownSmiley:before {
	content: "\ed41";
}
.icon-Thumbs-UpSmiley:before {
	content: "\ed42";
}
.icon-Thunder:before {
	content: "\ed43";
}
.icon-Thunderstorm:before {
	content: "\ed44";
}
.icon-Ticket:before {
	content: "\ed45";
}
.icon-Tie-2:before {
	content: "\ed46";
}
.icon-Tie-3:before {
	content: "\ed47";
}
.icon-Tie-4:before {
	content: "\ed48";
}
.icon-Tie:before {
	content: "\ed49";
}
.icon-Tiger:before {
	content: "\ed4a";
}
.icon-Time-Backup:before {
	content: "\ed4b";
}
.icon-Time-Bomb:before {
	content: "\ed4c";
}
.icon-Time-Clock:before {
	content: "\ed4d";
}
.icon-Time-Fire:before {
	content: "\ed4e";
}
.icon-Time-Machine:before {
	content: "\ed4f";
}
.icon-Time-Window:before {
	content: "\ed50";
}
.icon-Timer-2:before {
	content: "\ed51";
}
.icon-Timer:before {
	content: "\ed52";
}
.icon-To-Bottom:before {
	content: "\ed53";
}
.icon-To-Bottom2:before {
	content: "\ed54";
}
.icon-To-Left:before {
	content: "\ed55";
}
.icon-To-Right:before {
	content: "\ed56";
}
.icon-To-Top:before {
	content: "\ed57";
}
.icon-To-Top2:before {
	content: "\ed58";
}
.icon-Token-:before {
	content: "\ed59";
}
.icon-Tomato:before {
	content: "\ed5a";
}
.icon-Tongue:before {
	content: "\ed5b";
}
.icon-Tooth-2:before {
	content: "\ed5c";
}
.icon-Tooth:before {
	content: "\ed5d";
}
.icon-Top-ToBottom:before {
	content: "\ed5e";
}
.icon-Touch-Window:before {
	content: "\ed5f";
}
.icon-Tourch:before {
	content: "\ed60";
}
.icon-Tower-2:before {
	content: "\ed61";
}
.icon-Tower-Bridge:before {
	content: "\ed62";
}
.icon-Tower:before {
	content: "\ed63";
}
.icon-Trace:before {
	content: "\ed64";
}
.icon-Tractor:before {
	content: "\ed65";
}
.icon-traffic-Light:before {
	content: "\ed66";
}
.icon-Traffic-Light2:before {
	content: "\ed67";
}
.icon-Train-2:before {
	content: "\ed68";
}
.icon-Train:before {
	content: "\ed69";
}
.icon-Tram:before {
	content: "\ed6a";
}
.icon-Transform-2:before {
	content: "\ed6b";
}
.icon-Transform-3:before {
	content: "\ed6c";
}
.icon-Transform-4:before {
	content: "\ed6d";
}
.icon-Transform:before {
	content: "\ed6e";
}
.icon-Trash-withMen:before {
	content: "\ed6f";
}
.icon-Tree-2:before {
	content: "\ed70";
}
.icon-Tree-3:before {
	content: "\ed71";
}
.icon-Tree-4:before {
	content: "\ed72";
}
.icon-Tree-5:before {
	content: "\ed73";
}
.icon-Tree:before {
	content: "\ed74";
}
.icon-Trekking:before {
	content: "\ed75";
}
.icon-Triangle-ArrowDown:before {
	content: "\ed76";
}
.icon-Triangle-ArrowLeft:before {
	content: "\ed77";
}
.icon-Triangle-ArrowRight:before {
	content: "\ed78";
}
.icon-Triangle-ArrowUp:before {
	content: "\ed79";
}
.icon-Tripod-2:before {
	content: "\ed7a";
}
.icon-Tripod-andVideo:before {
	content: "\ed7b";
}
.icon-Tripod-withCamera:before {
	content: "\ed7c";
}
.icon-Tripod-withGopro:before {
	content: "\ed7d";
}
.icon-Trophy-2:before {
	content: "\ed7e";
}
.icon-Trophy:before {
	content: "\ed7f";
}
.icon-Truck:before {
	content: "\ed80";
}
.icon-Trumpet:before {
	content: "\ed81";
}
.icon-Tumblr:before {
	content: "\ed82";
}
.icon-Turkey:before {
	content: "\ed83";
}
.icon-Turn-Down:before {
	content: "\ed84";
}
.icon-Turn-Down2:before {
	content: "\ed85";
}
.icon-Turn-DownFromLeft:before {
	content: "\ed86";
}
.icon-Turn-DownFromRight:before {
	content: "\ed87";
}
.icon-Turn-Left:before {
	content: "\ed88";
}
.icon-Turn-Left3:before {
	content: "\ed89";
}
.icon-Turn-Right:before {
	content: "\ed8a";
}
.icon-Turn-Right3:before {
	content: "\ed8b";
}
.icon-Turn-Up:before {
	content: "\ed8c";
}
.icon-Turn-Up2:before {
	content: "\ed8d";
}
.icon-Turtle:before {
	content: "\ed8e";
}
.icon-Tuxedo:before {
	content: "\ed8f";
}
.icon-TV:before {
	content: "\ed90";
}
.icon-Twister:before {
	content: "\ed91";
}
.icon-Twitter-2:before {
	content: "\ed92";
}
.icon-Twitter:before {
	content: "\ed93";
}
.icon-Two-Fingers:before {
	content: "\ed94";
}
.icon-Two-FingersDrag:before {
	content: "\ed95";
}
.icon-Two-FingersDrag2:before {
	content: "\ed96";
}
.icon-Two-FingersScroll:before {
	content: "\ed97";
}
.icon-Two-FingersTouch:before {
	content: "\ed98";
}
.icon-Two-Windows:before {
	content: "\ed99";
}
.icon-Type-Pass:before {
	content: "\ed9a";
}
.icon-Ukraine:before {
	content: "\ed9b";
}
.icon-Umbrela:before {
	content: "\ed9c";
}
.icon-Umbrella-2:before {
	content: "\ed9d";
}
.icon-Umbrella-3:before {
	content: "\ed9e";
}
.icon-Under-LineText:before {
	content: "\ed9f";
}
.icon-Undo:before {
	content: "\eda0";
}
.icon-United-Kingdom:before {
	content: "\eda1";
}
.icon-United-States:before {
	content: "\eda2";
}
.icon-University-2:before {
	content: "\eda3";
}
.icon-University:before {
	content: "\eda4";
}
.icon-Unlike-2:before {
	content: "\eda5";
}
.icon-Unlike:before {
	content: "\eda6";
}
.icon-Unlock-2:before {
	content: "\eda7";
}
.icon-Unlock-3:before {
	content: "\eda8";
}
.icon-Unlock:before {
	content: "\eda9";
}
.icon-Up--Down:before {
	content: "\edaa";
}
.icon-Up--Down3:before {
	content: "\edab";
}
.icon-Up-2:before {
	content: "\edac";
}
.icon-Up-3:before {
	content: "\edad";
}
.icon-Up-4:before {
	content: "\edae";
}
.icon-Up:before {
	content: "\edaf";
}
.icon-Upgrade:before {
	content: "\edb0";
}
.icon-Upload-2:before {
	content: "\edb1";
}
.icon-Upload-toCloud:before {
	content: "\edb2";
}
.icon-Upload-Window:before {
	content: "\edb3";
}
.icon-Upload:before {
	content: "\edb4";
}
.icon-Uppercase-Text:before {
	content: "\edb5";
}
.icon-Upward:before {
	content: "\edb6";
}
.icon-URL-Window:before {
	content: "\edb7";
}
.icon-Usb-2:before {
	content: "\edb8";
}
.icon-Usb-Cable:before {
	content: "\edb9";
}
.icon-Usb:before {
	content: "\edba";
}
.icon-User:before {
	content: "\edbb";
}
.icon-Ustream:before {
	content: "\edbc";
}
.icon-Vase:before {
	content: "\edbd";
}
.icon-Vector-2:before {
	content: "\edbe";
}
.icon-Vector-3:before {
	content: "\edbf";
}
.icon-Vector-4:before {
	content: "\edc0";
}
.icon-Vector-5:before {
	content: "\edc1";
}
.icon-Vector:before {
	content: "\edc2";
}
.icon-Venn-Diagram:before {
	content: "\edc3";
}
.icon-Vest-2:before {
	content: "\edc4";
}
.icon-Vest:before {
	content: "\edc5";
}
.icon-Viddler:before {
	content: "\edc6";
}
.icon-Video-2:before {
	content: "\edc7";
}
.icon-Video-3:before {
	content: "\edc8";
}
.icon-Video-4:before {
	content: "\edc9";
}
.icon-Video-5:before {
	content: "\edca";
}
.icon-Video-6:before {
	content: "\edcb";
}
.icon-Video-GameController:before {
	content: "\edcc";
}
.icon-Video-Len:before {
	content: "\edcd";
}
.icon-Video-Len2:before {
	content: "\edce";
}
.icon-Video-Photographer:before {
	content: "\edcf";
}
.icon-Video-Tripod:before {
	content: "\edd0";
}
.icon-Video:before {
	content: "\edd1";
}
.icon-Vietnam:before {
	content: "\edd2";
}
.icon-View-Height:before {
	content: "\edd3";
}
.icon-View-Width:before {
	content: "\edd4";
}
.icon-Vimeo:before {
	content: "\edd5";
}
.icon-Virgo-2:before {
	content: "\edd6";
}
.icon-Virgo:before {
	content: "\edd7";
}
.icon-Virus-2:before {
	content: "\edd8";
}
.icon-Virus-3:before {
	content: "\edd9";
}
.icon-Virus:before {
	content: "\edda";
}
.icon-Visa:before {
	content: "\eddb";
}
.icon-Voice:before {
	content: "\eddc";
}
.icon-Voicemail:before {
	content: "\eddd";
}
.icon-Volleyball:before {
	content: "\edde";
}
.icon-Volume-Down:before {
	content: "\eddf";
}
.icon-Volume-Up:before {
	content: "\ede0";
}
.icon-VPN:before {
	content: "\ede1";
}
.icon-Wacom-Tablet:before {
	content: "\ede2";
}
.icon-Waiter:before {
	content: "\ede3";
}
.icon-Walkie-Talkie:before {
	content: "\ede4";
}
.icon-Wallet-2:before {
	content: "\ede5";
}
.icon-Wallet-3:before {
	content: "\ede6";
}
.icon-Wallet:before {
	content: "\ede7";
}
.icon-Warehouse:before {
	content: "\ede8";
}
.icon-Warning-Window:before {
	content: "\ede9";
}
.icon-Watch-2:before {
	content: "\edea";
}
.icon-Watch-3:before {
	content: "\edeb";
}
.icon-Watch:before {
	content: "\edec";
}
.icon-Wave-2:before {
	content: "\eded";
}
.icon-Wave:before {
	content: "\edee";
}
.icon-Webcam:before {
	content: "\edef";
}
.icon-weight-Lift:before {
	content: "\edf0";
}
.icon-Wheelbarrow:before {
	content: "\edf1";
}
.icon-Wheelchair:before {
	content: "\edf2";
}
.icon-Width-Window:before {
	content: "\edf3";
}
.icon-Wifi-2:before {
	content: "\edf4";
}
.icon-Wifi-Keyboard:before {
	content: "\edf5";
}
.icon-Wifi:before {
	content: "\edf6";
}
.icon-Wind-Turbine:before {
	content: "\edf7";
}
.icon-Windmill:before {
	content: "\edf8";
}
.icon-Window-2:before {
	content: "\edf9";
}
.icon-Window:before {
	content: "\edfa";
}
.icon-Windows-2:before {
	content: "\edfb";
}
.icon-Windows-Microsoft:before {
	content: "\edfc";
}
.icon-Windows:before {
	content: "\edfd";
}
.icon-Windsock:before {
	content: "\edfe";
}
.icon-Windy:before {
	content: "\edff";
}
.icon-Wine-Bottle:before {
	content: "\ee00";
}
.icon-Wine-Glass:before {
	content: "\ee01";
}
.icon-Wink:before {
	content: "\ee02";
}
.icon-Winter-2:before {
	content: "\ee03";
}
.icon-Winter:before {
	content: "\ee04";
}
.icon-Wireless:before {
	content: "\ee05";
}
.icon-Witch-Hat:before {
	content: "\ee06";
}
.icon-Witch:before {
	content: "\ee07";
}
.icon-Wizard:before {
	content: "\ee08";
}
.icon-Wolf:before {
	content: "\ee09";
}
.icon-Woman-Sign:before {
	content: "\ee0a";
}
.icon-WomanMan:before {
	content: "\ee0b";
}
.icon-Womans-Underwear:before {
	content: "\ee0c";
}
.icon-Womans-Underwear2:before {
	content: "\ee0d";
}
.icon-Women:before {
	content: "\ee0e";
}
.icon-Wonder-Woman:before {
	content: "\ee0f";
}
.icon-Wordpress:before {
	content: "\ee10";
}
.icon-Worker-Clothes:before {
	content: "\ee11";
}
.icon-Worker:before {
	content: "\ee12";
}
.icon-Wrap-Text:before {
	content: "\ee13";
}
.icon-Wreath:before {
	content: "\ee14";
}
.icon-Wrench:before {
	content: "\ee15";
}
.icon-X-Box:before {
	content: "\ee16";
}
.icon-X-ray:before {
	content: "\ee17";
}
.icon-Xanga:before {
	content: "\ee18";
}
.icon-Xing:before {
	content: "\ee19";
}
.icon-Yacht:before {
	content: "\ee1a";
}
.icon-Yahoo-Buzz:before {
	content: "\ee1b";
}
.icon-Yahoo:before {
	content: "\ee1c";
}
.icon-Yelp:before {
	content: "\ee1d";
}
.icon-Yes:before {
	content: "\ee1e";
}
.icon-Ying-Yang:before {
	content: "\ee1f";
}
.icon-Youtube:before {
	content: "\ee20";
}
.icon-Z-A:before {
	content: "\ee21";
}
.icon-Zebra:before {
	content: "\ee22";
}
.icon-Zombie:before {
	content: "\ee23";
}
.icon-Zoom-Gesture:before {
	content: "\ee24";
}
.icon-Zootool:before {
	content: "\ee25";
}

/*!
 * Bootstrap v4.1.2 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-sm-first {
    order: -1; }

  .order-sm-last {
    order: 13; }

  .order-sm-0 {
    order: 0; }

  .order-sm-1 {
    order: 1; }

  .order-sm-2 {
    order: 2; }

  .order-sm-3 {
    order: 3; }

  .order-sm-4 {
    order: 4; }

  .order-sm-5 {
    order: 5; }

  .order-sm-6 {
    order: 6; }

  .order-sm-7 {
    order: 7; }

  .order-sm-8 {
    order: 8; }

  .order-sm-9 {
    order: 9; }

  .order-sm-10 {
    order: 10; }

  .order-sm-11 {
    order: 11; }

  .order-sm-12 {
    order: 12; }

  .offset-sm-0 {
    margin-left: 0; }

  .offset-sm-1 {
    margin-left: 8.3333333333%; }

  .offset-sm-2 {
    margin-left: 16.6666666667%; }

  .offset-sm-3 {
    margin-left: 25%; }

  .offset-sm-4 {
    margin-left: 33.3333333333%; }

  .offset-sm-5 {
    margin-left: 41.6666666667%; }

  .offset-sm-6 {
    margin-left: 50%; }

  .offset-sm-7 {
    margin-left: 58.3333333333%; }

  .offset-sm-8 {
    margin-left: 66.6666666667%; }

  .offset-sm-9 {
    margin-left: 75%; }

  .offset-sm-10 {
    margin-left: 83.3333333333%; }

  .offset-sm-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-md-first {
    order: -1; }

  .order-md-last {
    order: 13; }

  .order-md-0 {
    order: 0; }

  .order-md-1 {
    order: 1; }

  .order-md-2 {
    order: 2; }

  .order-md-3 {
    order: 3; }

  .order-md-4 {
    order: 4; }

  .order-md-5 {
    order: 5; }

  .order-md-6 {
    order: 6; }

  .order-md-7 {
    order: 7; }

  .order-md-8 {
    order: 8; }

  .order-md-9 {
    order: 9; }

  .order-md-10 {
    order: 10; }

  .order-md-11 {
    order: 11; }

  .order-md-12 {
    order: 12; }

  .offset-md-0 {
    margin-left: 0; }

  .offset-md-1 {
    margin-left: 8.3333333333%; }

  .offset-md-2 {
    margin-left: 16.6666666667%; }

  .offset-md-3 {
    margin-left: 25%; }

  .offset-md-4 {
    margin-left: 33.3333333333%; }

  .offset-md-5 {
    margin-left: 41.6666666667%; }

  .offset-md-6 {
    margin-left: 50%; }

  .offset-md-7 {
    margin-left: 58.3333333333%; }

  .offset-md-8 {
    margin-left: 66.6666666667%; }

  .offset-md-9 {
    margin-left: 75%; }

  .offset-md-10 {
    margin-left: 83.3333333333%; }

  .offset-md-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-lg-first {
    order: -1; }

  .order-lg-last {
    order: 13; }

  .order-lg-0 {
    order: 0; }

  .order-lg-1 {
    order: 1; }

  .order-lg-2 {
    order: 2; }

  .order-lg-3 {
    order: 3; }

  .order-lg-4 {
    order: 4; }

  .order-lg-5 {
    order: 5; }

  .order-lg-6 {
    order: 6; }

  .order-lg-7 {
    order: 7; }

  .order-lg-8 {
    order: 8; }

  .order-lg-9 {
    order: 9; }

  .order-lg-10 {
    order: 10; }

  .order-lg-11 {
    order: 11; }

  .order-lg-12 {
    order: 12; }

  .offset-lg-0 {
    margin-left: 0; }

  .offset-lg-1 {
    margin-left: 8.3333333333%; }

  .offset-lg-2 {
    margin-left: 16.6666666667%; }

  .offset-lg-3 {
    margin-left: 25%; }

  .offset-lg-4 {
    margin-left: 33.3333333333%; }

  .offset-lg-5 {
    margin-left: 41.6666666667%; }

  .offset-lg-6 {
    margin-left: 50%; }

  .offset-lg-7 {
    margin-left: 58.3333333333%; }

  .offset-lg-8 {
    margin-left: 66.6666666667%; }

  .offset-lg-9 {
    margin-left: 75%; }

  .offset-lg-10 {
    margin-left: 83.3333333333%; }

  .offset-lg-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-xl-first {
    order: -1; }

  .order-xl-last {
    order: 13; }

  .order-xl-0 {
    order: 0; }

  .order-xl-1 {
    order: 1; }

  .order-xl-2 {
    order: 2; }

  .order-xl-3 {
    order: 3; }

  .order-xl-4 {
    order: 4; }

  .order-xl-5 {
    order: 5; }

  .order-xl-6 {
    order: 6; }

  .order-xl-7 {
    order: 7; }

  .order-xl-8 {
    order: 8; }

  .order-xl-9 {
    order: 9; }

  .order-xl-10 {
    order: 10; }

  .order-xl-11 {
    order: 11; }

  .order-xl-12 {
    order: 12; }

  .offset-xl-0 {
    margin-left: 0; }

  .offset-xl-1 {
    margin-left: 8.3333333333%; }

  .offset-xl-2 {
    margin-left: 16.6666666667%; }

  .offset-xl-3 {
    margin-left: 25%; }

  .offset-xl-4 {
    margin-left: 33.3333333333%; }

  .offset-xl-5 {
    margin-left: 41.6666666667%; }

  .offset-xl-6 {
    margin-left: 50%; }

  .offset-xl-7 {
    margin-left: 58.3333333333%; }

  .offset-xl-8 {
    margin-left: 66.6666666667%; }

  .offset-xl-9 {
    margin-left: 75%; }

  .offset-xl-10 {
    margin-left: 83.3333333333%; }

  .offset-xl-11 {
    margin-left: 91.6666666667%; } }
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus,
  .was-validated .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
  .was-validated .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }

  .d-sm-inline {
    display: inline !important; }

  .d-sm-inline-block {
    display: inline-block !important; }

  .d-sm-block {
    display: block !important; }

  .d-sm-table {
    display: table !important; }

  .d-sm-table-row {
    display: table-row !important; }

  .d-sm-table-cell {
    display: table-cell !important; }

  .d-sm-flex {
    display: flex !important; }

  .d-sm-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }

  .d-md-inline {
    display: inline !important; }

  .d-md-inline-block {
    display: inline-block !important; }

  .d-md-block {
    display: block !important; }

  .d-md-table {
    display: table !important; }

  .d-md-table-row {
    display: table-row !important; }

  .d-md-table-cell {
    display: table-cell !important; }

  .d-md-flex {
    display: flex !important; }

  .d-md-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }

  .d-lg-inline {
    display: inline !important; }

  .d-lg-inline-block {
    display: inline-block !important; }

  .d-lg-block {
    display: block !important; }

  .d-lg-table {
    display: table !important; }

  .d-lg-table-row {
    display: table-row !important; }

  .d-lg-table-cell {
    display: table-cell !important; }

  .d-lg-flex {
    display: flex !important; }

  .d-lg-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }

  .d-xl-inline {
    display: inline !important; }

  .d-xl-inline-block {
    display: inline-block !important; }

  .d-xl-block {
    display: block !important; }

  .d-xl-table {
    display: table !important; }

  .d-xl-table-row {
    display: table-row !important; }

  .d-xl-table-cell {
    display: table-cell !important; }

  .d-xl-flex {
    display: flex !important; }

  .d-xl-inline-flex {
    display: inline-flex !important; } }
@media print {
  .d-print-none {
    display: none !important; }

  .d-print-inline {
    display: inline !important; }

  .d-print-inline-block {
    display: inline-block !important; }

  .d-print-block {
    display: block !important; }

  .d-print-table {
    display: table !important; }

  .d-print-table-row {
    display: table-row !important; }

  .d-print-table-cell {
    display: table-cell !important; }

  .d-print-flex {
    display: flex !important; }

  .d-print-inline-flex {
    display: inline-flex !important; } }
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }

  .flex-sm-column {
    flex-direction: column !important; }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-sm-wrap {
    flex-wrap: wrap !important; }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-sm-fill {
    flex: 1 1 auto !important; }

  .flex-sm-grow-0 {
    flex-grow: 0 !important; }

  .flex-sm-grow-1 {
    flex-grow: 1 !important; }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-sm-start {
    justify-content: flex-start !important; }

  .justify-content-sm-end {
    justify-content: flex-end !important; }

  .justify-content-sm-center {
    justify-content: center !important; }

  .justify-content-sm-between {
    justify-content: space-between !important; }

  .justify-content-sm-around {
    justify-content: space-around !important; }

  .align-items-sm-start {
    align-items: flex-start !important; }

  .align-items-sm-end {
    align-items: flex-end !important; }

  .align-items-sm-center {
    align-items: center !important; }

  .align-items-sm-baseline {
    align-items: baseline !important; }

  .align-items-sm-stretch {
    align-items: stretch !important; }

  .align-content-sm-start {
    align-content: flex-start !important; }

  .align-content-sm-end {
    align-content: flex-end !important; }

  .align-content-sm-center {
    align-content: center !important; }

  .align-content-sm-between {
    align-content: space-between !important; }

  .align-content-sm-around {
    align-content: space-around !important; }

  .align-content-sm-stretch {
    align-content: stretch !important; }

  .align-self-sm-auto {
    align-self: auto !important; }

  .align-self-sm-start {
    align-self: flex-start !important; }

  .align-self-sm-end {
    align-self: flex-end !important; }

  .align-self-sm-center {
    align-self: center !important; }

  .align-self-sm-baseline {
    align-self: baseline !important; }

  .align-self-sm-stretch {
    align-self: stretch !important; } }
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }

  .flex-md-column {
    flex-direction: column !important; }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-md-wrap {
    flex-wrap: wrap !important; }

  .flex-md-nowrap {
    flex-wrap: nowrap !important; }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-md-fill {
    flex: 1 1 auto !important; }

  .flex-md-grow-0 {
    flex-grow: 0 !important; }

  .flex-md-grow-1 {
    flex-grow: 1 !important; }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-md-start {
    justify-content: flex-start !important; }

  .justify-content-md-end {
    justify-content: flex-end !important; }

  .justify-content-md-center {
    justify-content: center !important; }

  .justify-content-md-between {
    justify-content: space-between !important; }

  .justify-content-md-around {
    justify-content: space-around !important; }

  .align-items-md-start {
    align-items: flex-start !important; }

  .align-items-md-end {
    align-items: flex-end !important; }

  .align-items-md-center {
    align-items: center !important; }

  .align-items-md-baseline {
    align-items: baseline !important; }

  .align-items-md-stretch {
    align-items: stretch !important; }

  .align-content-md-start {
    align-content: flex-start !important; }

  .align-content-md-end {
    align-content: flex-end !important; }

  .align-content-md-center {
    align-content: center !important; }

  .align-content-md-between {
    align-content: space-between !important; }

  .align-content-md-around {
    align-content: space-around !important; }

  .align-content-md-stretch {
    align-content: stretch !important; }

  .align-self-md-auto {
    align-self: auto !important; }

  .align-self-md-start {
    align-self: flex-start !important; }

  .align-self-md-end {
    align-self: flex-end !important; }

  .align-self-md-center {
    align-self: center !important; }

  .align-self-md-baseline {
    align-self: baseline !important; }

  .align-self-md-stretch {
    align-self: stretch !important; } }
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }

  .flex-lg-column {
    flex-direction: column !important; }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-lg-wrap {
    flex-wrap: wrap !important; }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-lg-fill {
    flex: 1 1 auto !important; }

  .flex-lg-grow-0 {
    flex-grow: 0 !important; }

  .flex-lg-grow-1 {
    flex-grow: 1 !important; }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-lg-start {
    justify-content: flex-start !important; }

  .justify-content-lg-end {
    justify-content: flex-end !important; }

  .justify-content-lg-center {
    justify-content: center !important; }

  .justify-content-lg-between {
    justify-content: space-between !important; }

  .justify-content-lg-around {
    justify-content: space-around !important; }

  .align-items-lg-start {
    align-items: flex-start !important; }

  .align-items-lg-end {
    align-items: flex-end !important; }

  .align-items-lg-center {
    align-items: center !important; }

  .align-items-lg-baseline {
    align-items: baseline !important; }

  .align-items-lg-stretch {
    align-items: stretch !important; }

  .align-content-lg-start {
    align-content: flex-start !important; }

  .align-content-lg-end {
    align-content: flex-end !important; }

  .align-content-lg-center {
    align-content: center !important; }

  .align-content-lg-between {
    align-content: space-between !important; }

  .align-content-lg-around {
    align-content: space-around !important; }

  .align-content-lg-stretch {
    align-content: stretch !important; }

  .align-self-lg-auto {
    align-self: auto !important; }

  .align-self-lg-start {
    align-self: flex-start !important; }

  .align-self-lg-end {
    align-self: flex-end !important; }

  .align-self-lg-center {
    align-self: center !important; }

  .align-self-lg-baseline {
    align-self: baseline !important; }

  .align-self-lg-stretch {
    align-self: stretch !important; } }
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }

  .flex-xl-column {
    flex-direction: column !important; }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-xl-wrap {
    flex-wrap: wrap !important; }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-xl-fill {
    flex: 1 1 auto !important; }

  .flex-xl-grow-0 {
    flex-grow: 0 !important; }

  .flex-xl-grow-1 {
    flex-grow: 1 !important; }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-xl-start {
    justify-content: flex-start !important; }

  .justify-content-xl-end {
    justify-content: flex-end !important; }

  .justify-content-xl-center {
    justify-content: center !important; }

  .justify-content-xl-between {
    justify-content: space-between !important; }

  .justify-content-xl-around {
    justify-content: space-around !important; }

  .align-items-xl-start {
    align-items: flex-start !important; }

  .align-items-xl-end {
    align-items: flex-end !important; }

  .align-items-xl-center {
    align-items: center !important; }

  .align-items-xl-baseline {
    align-items: baseline !important; }

  .align-items-xl-stretch {
    align-items: stretch !important; }

  .align-content-xl-start {
    align-content: flex-start !important; }

  .align-content-xl-end {
    align-content: flex-end !important; }

  .align-content-xl-center {
    align-content: center !important; }

  .align-content-xl-between {
    align-content: space-between !important; }

  .align-content-xl-around {
    align-content: space-around !important; }

  .align-content-xl-stretch {
    align-content: stretch !important; }

  .align-self-xl-auto {
    align-self: auto !important; }

  .align-self-xl-start {
    align-self: flex-start !important; }

  .align-self-xl-end {
    align-self: flex-end !important; }

  .align-self-xl-center {
    align-self: center !important; }

  .align-self-xl-baseline {
    align-self: baseline !important; }

  .align-self-xl-stretch {
    align-self: stretch !important; } }
.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }

  .float-sm-right {
    float: right !important; }

  .float-sm-none {
    float: none !important; } }
@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }

  .float-md-right {
    float: right !important; }

  .float-md-none {
    float: none !important; } }
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }

  .float-lg-right {
    float: right !important; }

  .float-lg-none {
    float: none !important; } }
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }

  .float-xl-right {
    float: right !important; }

  .float-xl-none {
    float: none !important; } }
.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }

  .m-sm-1 {
    margin: 0.25rem !important; }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }

  .m-sm-2 {
    margin: 0.5rem !important; }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }

  .m-sm-3 {
    margin: 1rem !important; }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }

  .m-sm-4 {
    margin: 1.5rem !important; }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }

  .m-sm-5 {
    margin: 3rem !important; }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }

  .p-sm-0 {
    padding: 0 !important; }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }

  .p-sm-1 {
    padding: 0.25rem !important; }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }

  .p-sm-2 {
    padding: 0.5rem !important; }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }

  .p-sm-3 {
    padding: 1rem !important; }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }

  .p-sm-4 {
    padding: 1.5rem !important; }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }

  .p-sm-5 {
    padding: 3rem !important; }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }

  .m-sm-auto {
    margin: auto !important; }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }

  .m-md-1 {
    margin: 0.25rem !important; }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }

  .m-md-2 {
    margin: 0.5rem !important; }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }

  .m-md-3 {
    margin: 1rem !important; }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }

  .m-md-4 {
    margin: 1.5rem !important; }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }

  .m-md-5 {
    margin: 3rem !important; }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }

  .p-md-0 {
    padding: 0 !important; }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }

  .p-md-1 {
    padding: 0.25rem !important; }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }

  .p-md-2 {
    padding: 0.5rem !important; }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }

  .p-md-3 {
    padding: 1rem !important; }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }

  .p-md-4 {
    padding: 1.5rem !important; }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }

  .p-md-5 {
    padding: 3rem !important; }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }

  .m-md-auto {
    margin: auto !important; }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }

  .m-lg-1 {
    margin: 0.25rem !important; }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }

  .m-lg-2 {
    margin: 0.5rem !important; }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }

  .m-lg-3 {
    margin: 1rem !important; }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }

  .m-lg-4 {
    margin: 1.5rem !important; }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }

  .m-lg-5 {
    margin: 3rem !important; }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }

  .p-lg-0 {
    padding: 0 !important; }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }

  .p-lg-1 {
    padding: 0.25rem !important; }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }

  .p-lg-2 {
    padding: 0.5rem !important; }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }

  .p-lg-3 {
    padding: 1rem !important; }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }

  .p-lg-4 {
    padding: 1.5rem !important; }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }

  .p-lg-5 {
    padding: 3rem !important; }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }

  .m-lg-auto {
    margin: auto !important; }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }

  .m-xl-1 {
    margin: 0.25rem !important; }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }

  .m-xl-2 {
    margin: 0.5rem !important; }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }

  .m-xl-3 {
    margin: 1rem !important; }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }

  .m-xl-4 {
    margin: 1.5rem !important; }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }

  .m-xl-5 {
    margin: 3rem !important; }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }

  .p-xl-0 {
    padding: 0 !important; }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }

  .p-xl-1 {
    padding: 0.25rem !important; }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }

  .p-xl-2 {
    padding: 0.5rem !important; }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }

  .p-xl-3 {
    padding: 1rem !important; }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }

  .p-xl-4 {
    padding: 1.5rem !important; }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }

  .p-xl-5 {
    padding: 3rem !important; }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }

  .m-xl-auto {
    margin: auto !important; }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }

  .text-sm-right {
    text-align: right !important; }

  .text-sm-center {
    text-align: center !important; } }
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }

  .text-md-right {
    text-align: right !important; }

  .text-md-center {
    text-align: center !important; } }
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }

  .text-lg-right {
    text-align: right !important; }

  .text-lg-center {
    text-align: center !important; } }
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }

  .text-xl-right {
    text-align: right !important; }

  .text-xl-center {
    text-align: center !important; } }
.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }

  a:not(.btn) {
    text-decoration: underline; }

  abbr[title]::after {
    content: " (" attr(title) ")"; }

  pre {
    white-space: pre-wrap !important; }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }

  thead {
    display: table-header-group; }

  tr,
  img {
    page-break-inside: avoid; }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }

  h2,
  h3 {
    page-break-after: avoid; }

  @page {
    size: a3; }
  body {
    min-width: 992px !important; }

  .container {
    min-width: 992px !important; }

  .navbar {
    display: none; }

  .badge {
    border: 1px solid #000; }

  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }

  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/*! Flickity v2.0.2
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.flickity-prev-next-button:hover { background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.flickity-prev-next-button.previous { left: 10px; }
.flickity-prev-next-button.next { right: 10px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
/**!**************/
/**! Contents **/
/**! 01. Reset **/
/**! 02. Typography **/
/**! 03. Position **/
/**! 04. Element Size **/
/**! 05. Images **/
/**! 06. Buttons **/
/**! 07. Icons **/
/**! 08. Lists **/
/**! 09. Lightbox **/
/**! 10. Menus **/
/**! 11. Dropdowns **/
/**! 12. Form Elements **/
/**! 13. Accordions **/
/**! 14. Breadcrumbs **/
/**! 15. Radials **/
/**! 16. Tabs **/
/**! 17. Boxes **/
/**! 18. Sliders Flickity **/
/**! 19. Hover Elements **/
/**! 20. Masonry **/
/**! 21. Modals **/
/**! 22. Maps **/
/**! 23. Parallax **/
/**! 24. Notifications **/
/**! 25. Video **/
/**! 26. Colors **/
/**! 27. Image Blocks **/
/**! 28. MailChimp & Campaign Monitor **/
/**! 29. Twitter **/
/**! 30. Transitions **/
/**! 31. Switchable Sections **/
/**! 32. Typed Effect **/
/**! 33. Gradient BG **/
/**! 34. Bars **/
/**! 35. Navigation In Page **/
/**! 36. Helper Classes **/
/**! 37. Spacing **/
/**! 38. Boxed Layout **/
/**! 39. Wizard **/
/**! 40. Alerts **/
/**! 41. Progress - Horizontal **/
/**! 42. Theme Overrides **/
/**!**************/
/**!**************/
/**!**************/
/**! 01. Reset **/
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a {
  margin: 0;
  padding: 0;
}
/**! 02. Typography **/
html {
  font-size: 87.5%;
}
@media all and (max-width: 768px) {
  html {
    font-size: 81.25%;
  }
}
body {
  font-size: 1em;
  line-height: 1.85714286em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  color: #666666;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  color: #252525;
  font-weight: 300;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
}
h1,
.h1 {
  font-size: 3.14285714em;
  line-height: 1.31818182em;
}
h2,
.h2 {
  font-size: 2.35714286em;
  line-height: 1.36363636em;
}
h3,
.h3 {
  font-size: 1.78571429em;
  line-height: 1.5em;
}
h4,
.h4 {
  font-size: 1.35714286em;
  line-height: 1.36842105em;
}
h5,
.h5 {
  font-size: 1em;
  line-height: 1.85714286em;
}
h6,
.h6 {
  font-size: 0.85714286em;
  line-height: 2.16666667em;
}
.lead {
  font-size: 1.35714286em;
  line-height: 1.68421053em;
}
@media all and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2.35714286em;
    line-height: 1.36363636em;
  }
  h2,
  .h2 {
    font-size: 1.78571429em;
    line-height: 1.5em;
  }
  h3,
  .h3 {
    font-size: 1.35714286em;
    line-height: 1.85714286em;
  }
  .lead {
    font-size: 1.35714286em;
    line-height: 1.36842105em;
  }
}
p,
ul,
ol,
pre,
table,
blockquote {
  margin-bottom: 1.85714286em;
}
ul,
ol {
  list-style: none;
  line-height: 1.85714286em;
}
ul.bullets {
  list-style: inside;
}
ol {
  list-style-type: upper-roman;
  list-style-position: inside;
}
blockquote {
  font-size: 1.78571429em;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
  border-left: 0;
}
strong {
  font-weight: 600;
}
hr {
  margin: 1.85714286em 0;
  border-color: #FAFAFA;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}
/*! Typography -- Helpers */
.type--fade {
  opacity: .5;
}
.type--uppercase {
  text-transform: uppercase;
}
.type--bold {
  font-weight: bold;
}
.type--italic {
  font-style: italic;
}
.type--fine-print {
  font-size: 0.85714286em;
}
.type--strikethrough {
  text-decoration: line-through;
  opacity: .5;
}
.type--underline {
  text-decoration: underline;
}
.type--body-font {
  font-family: 'Open Sans', 'Helvetica';
}
/**! 03. Position **/
body {
  overflow-x: hidden;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.pos-absolute.container {
  left: 0;
  right: 0;
}
.pos-top {
  top: 0;
}
.pos-bottom {
  bottom: 0;
}
.pos-right {
  right: 0;
}
.pos-left {
  left: 0;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
@media all and (max-width: 767px) {
  .float-left,
  .float-right {
    float: none;
  }
  .float-left-xs {
    float: left;
  }
  .float-right-xs {
    float: right;
  }
}
.pos-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
@media all and (max-width: 767px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
@media all and (max-height: 600px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
.pos-vertical-align-columns {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.pos-vertical-align-columns > div[class*='col-'] {
  display: table-cell;
  float: none;
  vertical-align: middle;
}
@media all and (max-width: 990px) {
  .pos-vertical-align-columns {
    display: block;
    width: auto;
  }
  .pos-vertical-align-columns > div[class*='col-'] {
    display: block;
  }
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
@media all and (max-width: 767px) {
  .block--xs {
    display: block;
  }
}
@media all and (max-width: 990px) {
  .text-center-md {
    text-align: center;
  }
  .text-left-md {
    text-align: left;
  }
  .text-right-md {
    text-align: right;
  }
}
@media all and (max-width: 767px) {
  .text-center-xs {
    text-align: center;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
}
/**! 04. Element Size **/
.height-100,
.height-90,
.height-80,
.height-70,
.height-60,
.height-50,
.height-40,
.height-30,
.height-20,
.height-10 {
  height: auto;
  padding: 5em 0;
}
@media all and (max-width: 767px) {
  .height-100,
  .height-90,
  .height-80,
  .height-70,
  .height-60,
  .height-50,
  .height-40,
  .height-30,
  .height-20,
  .height-10 {
    height: auto;
    padding: 4em 0;
  }
}
@media all and (min-height: 600px) and (min-width: 767px) {
  .height-10 {
    height: 10vh;
  }
  .height-20 {
    height: 20vh;
  }
  .height-30 {
    height: 30vh;
  }
  .height-40 {
    height: 40vh;
  }
  .height-50 {
    height: 50vh;
  }
  .height-60 {
    height: 60vh;
  }
  .height-70 {
    height: 70vh;
  }
  .height-80 {
    height: 80vh;
  }
  .height-90 {
    height: 90vh;
  }
  .height-100 {
    height: 100vh;
  }
}
section.height-auto {
  height: auto;
}
section.height-auto .pos-vertical-center {
  top: 0;
  position: relative;
  transform: none;
}
@media all and (max-width: 767px) {
  div[class*='col-'][class*='height-'] {
    padding-top: 5.57142857em !important;
    padding-bottom: 5.57142857em !important;
  }
}
/**! 05. Images **/
img {
  max-width: 100%;
  margin-bottom: 1.85714286em;
}
/*p+img, img:last-child{
	margin-bottom: 0;
}*/
.img--fullwidth {
  width: 100%;
}
[data-grid="2"].masonry {
  -webkit-column-count: 2;
  -webkit-column-gap: 0;
  -moz-column-count: 2;
  -moz-column-gap: 0;
  column-count: 2;
  column-gap: 0;
}
[data-grid="2"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="2"] li {
  width: 50%;
  display: inline-block;
}
[data-grid="3"].masonry {
  -webkit-column-count: 3;
  -webkit-column-gap: 0;
  -moz-column-count: 3;
  -moz-column-gap: 0;
  column-count: 3;
  column-gap: 0;
}
[data-grid="3"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="3"] li {
  width: 33.33333333%;
  display: inline-block;
}
[data-grid="4"].masonry {
  -webkit-column-count: 4;
  -webkit-column-gap: 0;
  -moz-column-count: 4;
  -moz-column-gap: 0;
  column-count: 4;
  column-gap: 0;
}
[data-grid="4"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="4"] li {
  width: 25%;
  display: inline-block;
}
[data-grid="5"].masonry {
  -webkit-column-count: 5;
  -webkit-column-gap: 0;
  -moz-column-count: 5;
  -moz-column-gap: 0;
  column-count: 5;
  column-gap: 0;
}
[data-grid="5"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="5"] li {
  width: 20%;
  display: inline-block;
}
[data-grid="6"].masonry {
  -webkit-column-count: 6;
  -webkit-column-gap: 0;
  -moz-column-count: 6;
  -moz-column-gap: 0;
  column-count: 6;
  column-gap: 0;
}
[data-grid="6"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="6"] li {
  width: 16.66666667%;
  display: inline-block;
}
[data-grid="7"].masonry {
  -webkit-column-count: 7;
  -webkit-column-gap: 0;
  -moz-column-count: 7;
  -moz-column-gap: 0;
  column-count: 7;
  column-gap: 0;
}
[data-grid="7"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="7"] li {
  width: 14.28571429%;
  display: inline-block;
}
[data-grid="8"].masonry {
  -webkit-column-count: 8;
  -webkit-column-gap: 0;
  -moz-column-count: 8;
  -moz-column-gap: 0;
  column-count: 8;
  column-gap: 0;
}
[data-grid="8"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="8"] li {
  width: 12.5%;
  display: inline-block;
}
@media all and (max-width: 767px) {
  [data-grid]:not(.masonry) li {
    width: 33.333333%;
  }
  [data-grid="2"]:not(.masonry) li {
    width: 50%;
  }
  [data-grid].masonry {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.background-image-holder {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity .3s linear;
  -webkit-transition: opacity .3s linear;
  opacity: 0;
  background: #252525;
}
.background-image-holder:not([class*='col-']) {
  width: 100%;
}
.background-image-holder.background--bottom {
  background-position: 50% 100% !important;
}
.background-image-holder.background--top {
  background-position: 50% 0% !important;
}
.image--light .background-image-holder {
  background: none;
}
.background-image-holder img {
  display: none;
}
[data-overlay] {
  position: relative;
}
[data-overlay]:before {
  position: absolute;
  content: '';
  background: #252525;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
[data-overlay] *:not(.container):not(.background-image-holder) {
  z-index: 2;
}
[data-overlay].image--light:before {
  background: #fff;
}
[data-overlay].bg--primary:before {
  background: #E62E6A;
}
[data-overlay="1"]:before {
  opacity: 0.1;
}
[data-overlay="2"]:before {
  opacity: 0.2;
}
[data-overlay="3"]:before {
  opacity: 0.3;
}
[data-overlay="4"]:before {
  opacity: 0.4;
}
[data-overlay="5"]:before {
  opacity: 0.5;
}
[data-overlay="6"]:before {
  opacity: 0.6;
}
[data-overlay="7"]:before {
  opacity: 0.7;
}
[data-overlay="8"]:before {
  opacity: 0.8;
}
[data-overlay="9"]:before {
  opacity: 0.9;
}
[data-overlay="10"]:before {
  opacity: 1;
}
[data-overlay="0"]:before {
  opacity: 0;
}
[data-scrim-bottom] {
  position: relative;
}
[data-scrim-bottom]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, #252525));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #252525 100%);
  bottom: 0;
  left: 0;
  z-index: 1;
  backface-visibility: hidden;
}
[data-scrim-bottom]:not(.image--light) h1,
[data-scrim-bottom]:not(.image--light) h2,
[data-scrim-bottom]:not(.image--light) h3,
[data-scrim-bottom]:not(.image--light) h4,
[data-scrim-bottom]:not(.image--light) h5,
[data-scrim-bottom]:not(.image--light) h6 {
  color: #a5a5a5;
  color: #f1f1f1;
}
[data-scrim-bottom]:not(.image--light) p,
[data-scrim-bottom]:not(.image--light) span,
[data-scrim-bottom]:not(.image--light) ul {
  color: #e6e6e6;
}
[data-scrim-bottom].image--light:before {
  background: #fff;
}
[data-scrim-bottom="1"]:before {
  opacity: 0.1;
}
[data-scrim-bottom="2"]:before {
  opacity: 0.2;
}
[data-scrim-bottom="3"]:before {
  opacity: 0.3;
}
[data-scrim-bottom="4"]:before {
  opacity: 0.4;
}
[data-scrim-bottom="5"]:before {
  opacity: 0.5;
}
[data-scrim-bottom="6"]:before {
  opacity: 0.6;
}
[data-scrim-bottom="7"]:before {
  opacity: 0.7;
}
[data-scrim-bottom="8"]:before {
  opacity: 0.8;
}
[data-scrim-bottom="9"]:before {
  opacity: 0.9;
}
[data-scrim-bottom="10"]:before {
  opacity: 1;
}
[data-scrim-top] {
  position: relative;
}
[data-scrim-top]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  background: -moz-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left bottom, left bottom, color-stop(0%, #252525), color-stop(100%, rgba(0, 0, 0, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: 1;
}
[data-scrim-top]:not(.image--light) h1,
[data-scrim-top]:not(.image--light) h2,
[data-scrim-top]:not(.image--light) h3,
[data-scrim-top]:not(.image--light) h4,
[data-scrim-top]:not(.image--light) h5,
[data-scrim-top]:not(.image--light) h6 {
  color: #fff;
}
[data-scrim-top]:not(.image--light) p,
[data-scrim-top]:not(.image--light) span,
[data-scrim-top]:not(.image--light) ul {
  color: #e6e6e6;
}
[data-scrim-top].image--light:before {
  background: #fff;
}
[data-scrim-top="1"]:before {
  opacity: 0.1;
}
[data-scrim-top="2"]:before {
  opacity: 0.2;
}
[data-scrim-top="3"]:before {
  opacity: 0.3;
}
[data-scrim-top="4"]:before {
  opacity: 0.4;
}
[data-scrim-top="5"]:before {
  opacity: 0.5;
}
[data-scrim-top="6"]:before {
  opacity: 0.6;
}
[data-scrim-top="7"]:before {
  opacity: 0.7;
}
[data-scrim-top="8"]:before {
  opacity: 0.8;
}
[data-scrim-top="9"]:before {
  opacity: 0.9;
}
[data-scrim-top="10"]:before {
  opacity: 1;
}
.imagebg {
  position: relative;
}
.imagebg .container {
  z-index: 2;
}
.imagebg .container:not(.pos-absolute) {
  position: relative;
}
.imagebg:not(.image--light) h1,
.imagebg:not(.image--light) h2,
.imagebg:not(.image--light) h3,
.imagebg:not(.image--light) h4,
.imagebg:not(.image--light) h5,
.imagebg:not(.image--light) h6,
.imagebg:not(.image--light) p,
.imagebg:not(.image--light) ul,
.imagebg:not(.image--light) blockquote {
  color: #fff;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6 {
  color: #252525;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white ul {
  color: #666666;
}
div[data-overlay] h1,
div[data-overlay] h2,
div[data-overlay] h3,
div[data-overlay] h4,
div[data-overlay] h5,
div[data-overlay] h6 {
  color: #fff;
}
div[data-overlay] p,
div[data-overlay] ul {
  color: #fff;
}
.parallax {
  overflow: hidden;
}
.parallax .background-image-holder {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
}
.image--xxs {
  max-height: 1.85714286em;
}
.image--xs {
  max-height: 3.71428571em;
}
.image--sm {
  max-height: 5.57142857em;
}
.image--md {
  max-height: 7.42857143em;
}
/**! 06. Buttons **/
.btn {
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  border-radius: 6px;
  padding-top: 0.46428571em;
  padding-bottom: 0.46428571em;
  padding-right: 2.78571429em;
  padding-left: 2.78571429em;
  border: 1px solid #252525;
  border-width: 1px;
  font-size: inherit;
  line-height: 1.85714286em;
}
.btn .btn__text,
.btn i {
  color: #252525;
  border-color: #252525;
  font-weight: 700;
  font-size: 0.85714286em;
}
.btn[class*='col-'] {
  margin-left: 0;
  margin-right: 0;
}
.btn:active {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.btn.bg--facebook,
.btn.bg--twitter,
.btn.bg--instagram,
.btn.bg--googleplus,
.btn.bg--pinterest,
.btn.bg--dribbble,
.btn.bg--behance,
.btn.bg--dark {
  border-color: rgba(0, 0, 0, 0) !important;
}
.btn.bg--facebook .btn__text,
.btn.bg--twitter .btn__text,
.btn.bg--instagram .btn__text,
.btn.bg--googleplus .btn__text,
.btn.bg--pinterest .btn__text,
.btn.bg--dribbble .btn__text,
.btn.bg--behance .btn__text,
.btn.bg--dark .btn__text {
  color: #fff;
}
.btn.bg--facebook .btn__text i,
.btn.bg--twitter .btn__text i,
.btn.bg--instagram .btn__text i,
.btn.bg--googleplus .btn__text i,
.btn.bg--pinterest .btn__text i,
.btn.bg--dribbble .btn__text i,
.btn.bg--behance .btn__text i,
.btn.bg--dark .btn__text i {
  color: #fff;
}
.btn.bg--facebook:hover,
.btn.bg--twitter:hover,
.btn.bg--instagram:hover,
.btn.bg--googleplus:hover,
.btn.bg--pinterest:hover,
.btn.bg--dribbble:hover,
.btn.bg--behance:hover,
.btn.bg--dark:hover {
  opacity: .9;
}
.btn.bg--error {
  background: #E23636;
  border-color: #E23636 !important;
}
.btn.bg--error:hover {
  background: #e54c4c;
  border-color: #e54c4c !important;
  color: #fff !important;
}
.btn.bg--error .btn__text {
  color: #fff;
}
.btn.bg--error .btn__text i {
  color: #fff;
}
@media all and (min-width: 768px) {
  .btn + .btn {
    margin-left: 1.85714286em;
  }
}
.btn:first-child {
  margin-left: 0;
}
.btn:last-child {
  margin-right: 0;
}
.btn--xs {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1.39285714em;
  padding-left: 1.39285714em;
}
.btn--sm {
  padding-top: 0.30952381em;
  padding-bottom: 0.30952381em;
  padding-right: 1.85714286em;
  padding-left: 1.85714286em;
}
.btn--lg {
  padding-top: 0.58035714em;
  padding-bottom: 0.58035714em;
  padding-right: 3.48214286em;
  padding-left: 3.48214286em;
}
.btn--lg .btn__text {
  font-size: 1.07142857em;
}
.btn--primary,
.btn--primary:visited {
  background: #E62E6A;
  border-color: #E62E6A;
}
.btn--primary .btn__text,
.btn--primary:visited .btn__text {
  color: #fff;
}
.btn--primary .btn__text i,
.btn--primary:visited .btn__text i {
  color: #fff;
}
.btn--primary:hover {
  background: #e9457a;
}
.btn--primary:active {
  background: #e01b5b;
}
.btn--primary-1,
.btn--primary-1:visited {
  background: #DE1AF5;
  border-color: #DE1AF5;
}
.btn--primary-1 .btn__text,
.btn--primary-1:visited .btn__text {
  color: #fff;
}
.btn--primary-1:hover {
  background: #e232f6;
}
.btn--primary-1:active {
  background: #d40aeb;
}
.btn--primary-2,
.btn--primary-2:visited {
  background: #53294A;
  border-color: #53294A;
}
.btn--primary-2 .btn__text,
.btn--primary-2:visited .btn__text {
  color: #fff;
}
.btn--primary-2:hover {
  background: #643159;
}
.btn--primary-2:active {
  background: #42213b;
}
.btn--secondary {
  background: #FAFAFA;
  border-color: #FAFAFA;
}
.btn--secondary:hover {
  background: #ffffff;
}
.btn--secondary:active {
  background: #f5f5f5;
}
.btn--white {
  background: #fff;
  color: #252525;
  border-color: #fff;
}
.btn--white i {
  color: #252525;
}
.btn--transparent {
  background: none;
  border-color: rgba(0, 0, 0, 0);
  padding-left: 0;
  padding-right: 0;
}
.btn--transparent.btn--white .btn__text {
  color: #fff;
}
.btn--unfilled {
  background: none;
}
.btn--unfilled.btn--white .btn__text {
  color: #fff;
}
.btn--unfilled.btn--white i {
  color: #fff;
}
.btn--floating {
  position: fixed;
  bottom: 3.71428571em;
  right: 3.71428571em;
  z-index: 10;
}
/**! 07. Icons **/
.icon {
  line-height: 1em;
  font-size: 3.14285714em;
}
.icon--xs {
  font-size: 1em;
}
.icon--sm {
  font-size: 2.35714286em;
}
.icon--lg {
  font-size: 5.57142857em;
}
/**! 08. Lists **/
ul:last-child {
  margin: 0;
}
.list-inline li {
  padding: 0 1em;
  margin-left: 0;
}
.list-inline li:first-child {
  padding-left: 0;
}
.list-inline li:last-child {
  padding-right: 0;
}
.list-inline.list-inline--narrow li {
  padding: 0 .5em;
}
.list-inline.list-inline--wide li {
  padding: 0 2em;
}
/**! 09. Lightbox **/
.lb-outerContainer {
  border-radius: 0;
}
.lb-outerContainer .lb-container {
  padding: 0;
}
.lb-outerContainer .lb-container img {
  margin: 0;
}
.lightbox-gallery {
  overflow: hidden;
}
.lightbox-gallery li {
  float: left;
}
.lightbox-gallery li img {
  margin: 0;
  width: 100%;
}
.lightbox-gallery.gallery--gaps li {
  padding: 0.46428571em;
}
/**! 10. Menus **/
.menu-horizontal > li:not(:hover) > a,
.menu-horizontal > li:not(:hover) > span,
.menu-horizontal > li:not(:hover) > .modal-instance > .modal-trigger {
  opacity: .5;
}
.menu-horizontal > li > a,
.menu-horizontal > li > span,
.menu-horizontal > li > .modal-instance > .modal-trigger {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  color: #252525;
}
.menu-horizontal > li > a:hover,
.menu-horizontal > li > span:hover,
.menu-horizontal > li > .modal-instance > .modal-trigger:hover {
  color: #252525;
}
.bg--dark .menu-horizontal > li > a,
.bg--dark .menu-horizontal > li > span {
  color: #fff;
}
.bg--dark .menu-horizontal > li > a:hover,
.bg--dark .menu-horizontal > li > span:hover {
  color: #fff;
}
.menu-vertical {
  width: 100%;
}
.menu-vertical li {
  width: 100%;
}
.menu-vertical li a {
  font-weight: normal;
}
@media all and (min-width: 990px) {
  .menu-horizontal {
    display: inline-block;
  }
  .menu-horizontal > li {
    display: inline-block;
  }
  .menu-horizontal > li:not(:last-child) {
    margin-right: 1.85714286em;
  }
  .menu-vertical {
    display: inline-block;
  }
  .menu-vertical li {
    white-space: nowrap;
  }
  .menu-vertical .dropdown__container {
    top: 0;
  }
  .menu-vertical .dropdown__container .dropdown__content:not([class*='bg-']) {
    background: #FFFFFF;
  }
  .menu-vertical .dropdown__container .dropdown__content {
    transform: translateX(75%);
  }
}
/**! 11. Dropdowns **/
.dropdown {
  position: relative;
}
.dropdown .dropdown__container {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: 999;
}
.dropdown .dropdown__container .dropdown__container:before {
  height: 0;
}
.dropdown .dropdown__content {
  padding: 1.85714286em;
}
.dropdown .dropdown__content:not([class*='col-']) {
  width: 18.57142857em;
}
.dropdown .dropdown__content:not([class*='bg-']) {
  background: #FFFFFF;
}
.dropdown .dropdown__content:not([class='bg--dark']) .menu-vertical a {
  color: #666666;
}
.dropdown .dropdown__trigger {
  cursor: pointer;
  user-select: none;
}
.dropdown.dropdown--active > .dropdown__container {
  opacity: 1;
}
.dropdown.dropdown--active > .dropdown__container > .container > .row > .dropdown__content {
  pointer-events: all;
}
@media all and (min-width: 991px) {
  .dropdown .dropdown__container:before {
    height: 0.92857143em;
    width: 100%;
    content: '';
    display: block;
  }
  .dropdown .dropdown__content.dropdown__content--md {
    padding: 2.78571429em;
  }
  .dropdown .dropdown__content.dropdown__content--lg {
    padding: 3.71428571em;
  }
  .dropdown .dropdown__content.dropdown__content--xlg {
    padding: 4.64285714em;
  }
}
@media all and (max-width: 767px) {
  .dropdown .dropdown__container {
    min-width: 100%;
    position: relative;
    display: none;
  }
  .dropdown .dropdown__content {
    padding: 1.85714286em;
    left: 0 !important;
  }
  .dropdown.dropdown--active > .dropdown__container {
    display: block;
  }
}
body.dropdowns--hover .dropdown:not(.dropdown--click):hover > .dropdown__container {
  opacity: 1;
}
body.dropdowns--hover .dropdown:not(.dropdown--click):hover > .dropdown__container:before {
  pointer-events: all;
}
body.dropdowns--hover .dropdown:not(.dropdown--click):hover > .dropdown__container .dropdown__content {
  pointer-events: all;
}
body:not(.dropdowns--hover) .dropdown.dropdown--hover:hover > .dropdown__container {
  opacity: 1;
}
body:not(.dropdowns--hover) .dropdown.dropdown--hover:hover > .dropdown__container:before {
  pointer-events: all;
}
body:not(.dropdowns--hover) .dropdown.dropdown--hover:hover > .dropdown__container .dropdown__content {
  pointer-events: all;
}
@media all and (max-width: 990px) {
  body.dropdowns--hover .dropdown:not(.dropdown--click).dropdown--active > .dropdown__container {
    display: block;
  }
  body.dropdowns--hover .dropdown:not(.dropdown--click).dropdown--active > .dropdown__container:before {
    pointer-events: all;
  }
}
@media all and (max-width: 767px) {
  .dropdown.dropdown--absolute .dropdown__container {
    position: absolute;
  }
}
/**! 12. Form Elements **/
form {
  max-width: 100%;
}
form + form {
  margin-top: 30px;
}
form:before,
form:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
form:after {
  clear: both;
}
label {
  margin: 0;
  font-size: 1.14285714em;
  font-weight: 400;
}
input[type],
textarea,
select {
  -webkit-appearance: none;
  background: #FCFCFC;
  padding: 0.46428571em;
  border-radius: 6px;
  border: 1px solid #ECECEC;
}
input[type]:focus,
textarea:focus,
select:focus {
  outline: none;
}
input[type]:active,
textarea:active,
select:active {
  outline: none;
}
input[type]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type]:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder {
  /* Firefox 18- */
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type]::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
  /* Firefox 19+ */
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type="image"] {
  border: none;
  padding: none;
  width: auto;
}
textarea {
  display: block;
  width: 100%;
  max-width: 100%;
}
select {
  cursor: pointer;
  padding-right: 1.85714286em;
  -webkit-appearance: none;
}
select::ms-expand {
  display: none;
}
input[type="submit"] {
  background: none;
  outline: none;
  border: none;
  background: #E62E6A;
  padding: 0.46428571em 2.78571429em 0.46428571em 2.78571429em;
  color: #fff;
}
@keyframes load {
  0% {
    opacity: 0;
    width: 0;
  }
  50% {
    width: 100%;
    opacity: .8;
    left: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
button {
  background: none;
}
button[type="submit"].btn--loading {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  color: rgba(0, 0, 0, 0);
}
button[type="submit"].btn--loading * {
  opacity: 0;
}
button[type="submit"].btn--loading:after {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  background: #ddd;
  animation: load 1.5s ease-out infinite;
  left: 0;
  top: 0;
}
button[type="submit"].btn--loading .btn__text {
  opacity: 0;
}
button:focus {
  outline: none !important;
}
button.bg--error {
  color: #fff;
}
.input-icon {
  position: relative;
}
.input-icon i {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  position: absolute;
}
.input-checkbox,
.input-radio,
.input-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
}
.input-checkbox input,
.input-radio input,
.input-select input {
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  position: absolute !important;
}
.input-checkbox label,
.input-radio label,
.input-select label {
  display: block !important;
  cursor: pointer;
}
.input-checkbox {
  padding: 0;
}
.input-checkbox label {
  width: 1.85714286em;
  height: 1.85714286em;
  border-radius: 6px;
  background: #000;
}
.input-radio {
  padding: 0;
}
.input-radio label {
  width: 1.85714286em;
  height: 1.85714286em;
  border-radius: 50%;
  background: #000;
}
.input-select {
  position: relative;
}
.input-select i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 1em;
  font-size: .87em;
}
.input-file {
  position: relative;
  display: inline-block;
}
.input-file input {
  display: none;
}
.form-error {
  margin-top: 1.5625em;
  padding: 0.78125em;
  background: #D84D4D;
  color: #fff;
  position: fixed;
  min-width: 350px;
  left: 50%;
  bottom: 1.5625em;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.form-success {
  margin-top: 1.5625em;
  padding: 0.78125em;
  background: #1DC020;
  color: #fff;
  position: fixed;
  min-width: 350px;
  left: 50%;
  bottom: 1.5625em;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.attempted-submit .field-error {
  border-color: #D84D4D !important;
}
.attempted-submit div.recaptcha.field-error {
  border-color: #D84D4D !important;
  border-style: solid;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
}
div.recaptcha iframe {
  min-height: 0;
}
/**! 13. Accordions **/
.accordion li .accordion__title,
.accordion li .accordion__content,
.accordion li .accordion__content * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.accordion li .accordion__title {
  cursor: pointer;
  padding: 0.46428571em 0.92857143em;
  border: 1px solid #ECECEC;
  border-bottom: none;
  background: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.accordion li:last-child .accordion__title {
  border-bottom: 1px solid #ECECEC;
}
.accordion li .accordion__content {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}
.accordion li .accordion__content > * {
  display: none;
}
.accordion li .accordion__content > *:first-child {
  padding-top: 0;
}
.accordion li .accordion__content > *:last-child {
  padding-bottom: 0;
}
.accordion li.active .accordion__title {
  background: #E62E6A;
  border-bottom: 1px solid #ECECEC;
}
.accordion li.active .accordion__content {
  opacity: 1;
  visibility: visible;
  max-height: 500px;
}
.accordion li.active .accordion__content > * {
  display: inline-block;
}
/**! 14. Breadcrumbs **/
.breadcrumb {
  padding: 0;
  margin: 0;
  background: none;
  display: inline-block;
}
.breadcrumb li {
  font-size: 1em;
}
.breadcrumb li + li:before {
  padding: 0 0.46428571em;
}
/**! 15. Pie Charts **/
.radial {
  position: relative;
}
.radial .radial__label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  margin-bottom: 0;
}
/**! 16. Tabs **/
.tabs {
  display: block;
  margin-bottom: 0;
}
.tabs > li {
  display: inline-block;
  opacity: .5;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tabs > .active,
.tabs:hover {
  opacity: 1;
}
.tabs .tab__title {
  cursor: pointer;
}
.tabs .tab__title:not(.btn) {
  padding: 0 1.85714286em;
}
.tabs li:first-child .tab__title:not(.btn) {
  padding-left: 0;
}
.tabs .tab__content {
  display: none;
}
.tabs-content {
  margin-top: 1em;
}
.tabs-content li > .tab__content {
  width: 100%;
  display: none;
}
.tabs-content > .active > .tab__content {
  display: block;
}
.tabs-container[data-content-align="left"] .tabs-content {
  text-align: left;
}
@media all and (max-width: 767px) {
  .tabs-content > li:not(.active) .tab__content {
    display: none !important;
  }
}
/**! 17. Boxes **/
.boxed {
  position: relative;
  overflow: hidden;
  padding: 1.85714286em;
  margin-bottom: 30px;
}
.boxed.boxed--lg {
  padding: 2.78571429em;
}
.boxed.boxed--sm {
  padding: 1.23809524em;
}
.boxed.boxed--border {
  border: 1px solid #ECECEC;
}
.boxed > div[class*='col-']:first-child:not(.boxed) {
  padding-left: 0;
}
.boxed > div[class*='col-']:last-child:not(.boxed) {
  padding-right: 0;
}
img + .boxed {
  margin-top: -1.85714286em;
}
@media all and (max-width: 767px) {
  .boxed {
    padding: 1.23809524em;
    margin-bottom: 15px;
  }
  .boxed.boxed--lg {
    padding: 1.23809524em;
  }
  .boxed div[class*='col-']:not(.boxed) {
    padding: 0;
  }
  .boxed:last-child {
    margin-bottom: 15px;
  }
}
/**! 18. Sliders Flickity **/
.slides:not(.flickity-enabled) li.imagebg:not(:first-child) {
  display: none;
}
.slides:not(.flickity-enabled) li.imagebg:first-child {
  background: #252525;
  animation: backgroundLoad .5s ease alternate infinite;
}
.slides:not(.flickity-enabled) li.imagebg:first-child .container {
  opacity: 0;
}
@keyframes backgroundLoad {
  0% {
    background: #252525;
  }
  100% {
    background: #3f3f3f;
  }
}
.slider.height-10 {
  height: auto;
}
.slider.height-10 .slides .flickity-slider > li {
  height: 10vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-10 .slides li.imagebg {
    min-height: 10vh;
  }
}
.slider.height-20 {
  height: auto;
}
.slider.height-20 .slides .flickity-slider > li {
  height: 20vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-20 .slides li.imagebg {
    min-height: 20vh;
  }
}
.slider.height-30 {
  height: auto;
}
.slider.height-30 .slides .flickity-slider > li {
  height: 30vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-30 .slides li.imagebg {
    min-height: 30vh;
  }
}
.slider.height-40 {
  height: auto;
}
.slider.height-40 .slides .flickity-slider > li {
  height: 40vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-40 .slides li.imagebg {
    min-height: 40vh;
  }
}
.slider.height-50 {
  height: auto;
}
.slider.height-50 .slides .flickity-slider > li {
  height: 50vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-50 .slides li.imagebg {
    min-height: 50vh;
  }
}
.slider.height-60 {
  height: auto;
}
.slider.height-60 .slides .flickity-slider > li {
  height: 60vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-60 .slides li.imagebg {
    min-height: 60vh;
  }
}
.slider.height-70 {
  height: auto;
}
.slider.height-70 .slides .flickity-slider > li {
  height: 70vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-70 .slides li.imagebg {
    min-height: 70vh;
  }
}
.slider.height-80 {
  height: auto;
}
.slider.height-80 .slides .flickity-slider > li {
  height: 80vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-80 .slides li.imagebg {
    min-height: 80vh;
  }
}
.slider.height-90 {
  height: auto;
}
.slider.height-90 .slides .flickity-slider > li {
  height: 90vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-90 .slides li.imagebg {
    min-height: 90vh;
  }
}
.slider.height-100 {
  height: auto;
}
.slider.height-100 .slides .flickity-slider > li {
  height: 100vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-100 .slides li.imagebg {
    min-height: 100vh;
  }
}
.slider .slides .flickity-slider > li:not([class*='col-']) {
  width: 100%;
}
.slider .slides.slides--gapless li[class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}
.slider[data-arrows="true"].slider--arrows-hover:not(:hover) .flickity-prev-next-button {
  opacity: 0;
}
.slider[data-paging="true"]:not(section) {
  margin-bottom: 3.71428571em;
}
.slider[data-paging="true"]:not(section) .flickity-page-dots {
  bottom: -3.71428571em;
}
.slider[data-paging="true"]:not([class*='text-']) .flickity-page-dots {
  text-align: center;
}
.slider[data-children="1"] .flickity-prev-next-button {
  display: none;
}
.slider:not([data-paging="true"]) .slides {
  margin: 0;
}
.slider.controls--dark .flickity-page-dots .dot {
  background: #252525;
}
.slider.controls--dark .flickity-prev-next-button:before {
  color: #252525;
}
section.slider {
  padding: 0;
}
section.slider.height-10 {
  height: auto;
}
section.slider.height-10 .slides .flickity-slider > li {
  height: 10vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-10 .slides li.imagebg {
    min-height: 10vh;
  }
}
section.slider.height-20 {
  height: auto;
}
section.slider.height-20 .slides .flickity-slider > li {
  height: 20vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-20 .slides li.imagebg {
    min-height: 20vh;
  }
}
section.slider.height-30 {
  height: auto;
}
section.slider.height-30 .slides .flickity-slider > li {
  height: 30vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-30 .slides li.imagebg {
    min-height: 30vh;
  }
}
section.slider.height-40 {
  height: auto;
}
section.slider.height-40 .slides .flickity-slider > li {
  height: 40vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-40 .slides li.imagebg {
    min-height: 40vh;
  }
}
section.slider.height-50 {
  height: auto;
}
section.slider.height-50 .slides .flickity-slider > li {
  height: 50vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-50 .slides li.imagebg {
    min-height: 50vh;
  }
}
section.slider.height-60 {
  height: auto;
}
section.slider.height-60 .slides .flickity-slider > li {
  height: 60vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-60 .slides li.imagebg {
    min-height: 60vh;
  }
}
section.slider.height-70 {
  height: auto;
}
section.slider.height-70 .slides .flickity-slider > li {
  height: 70vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-70 .slides li.imagebg {
    min-height: 70vh;
  }
}
section.slider.height-80 {
  height: auto;
}
section.slider.height-80 .slides .flickity-slider > li {
  height: 80vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-80 .slides li.imagebg {
    min-height: 80vh;
  }
}
section.slider.height-90 {
  height: auto;
}
section.slider.height-90 .slides .flickity-slider > li {
  height: 90vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-90 .slides li.imagebg {
    min-height: 90vh;
  }
}
section.slider.height-100 {
  height: auto;
}
section.slider.height-100 .slides .flickity-slider > li {
  height: 100vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-100 .slides li.imagebg {
    min-height: 100vh;
  }
}
section.slider[data-paging="true"] .flickity-page-dots {
  bottom: 1.85714286em;
}
section.slider:not(.image--light)[data-paging="true"] .flickity-page-dots .dot {
  background: #fff;
}
section.slider .slides {
  margin: 0;
}
@media all and (max-width: 767px) {
  section.slider[class*='height-'] .slides .flickity-slider > li {
    height: auto;
    padding: 7.42857143em 0;
  }
  section.slider.space--lg .slides .flickity-slider > li {
    padding: 11.14285714em 0;
  }
  section.slider.space--xlg .slides .flickity-slider > li {
    padding: 11.14285714em 0;
  }
}
section.bg--dark .slider[data-paging="true"] .flickity-page-dots .dot,
section.bg--primary .slider[data-paging="true"] .flickity-page-dots .dot {
  background: #fff;
}
.flickity-page-dots .dot {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #252525;
  border: none;
  margin: 0 0.46428571em;
}
.flickity-page-dots .dot:hover:not(.is-selected) {
  opacity: .6;
}
.text-center .flickity-page-dots,
section.slider .flickity-page-dots {
  text-align: center;
}
.flickity-prev-next-button svg {
  display: none;
}
.flickity-prev-next-button:before {
  font-family: 'stack-interface';
  content: "\e80c";
  font-size: 1em;
  font-weight: normal;
}
.flickity-prev-next-button.previous:before {
  content: "\e80b";
}
.imagebg:not(.image--light) .flickity-page-dots .dot,
.bg--dark .flickity-page-dots .dot {
  background: #fff;
}
/**! 19. Hover Elements **/
.hover-element {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.hover-element * {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.hover-element .hover-element__reveal {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.hover-element .hover-element__reveal .boxed {
  height: 100%;
}
.hover-element:hover .hover-element__reveal,
.hover-element.hover--active .hover-element__reveal {
  opacity: 1;
}
.hover-element img {
  margin-bottom: 0;
}
@media all and (max-width: 1024px) {
  .hover-element {
    cursor: pointer;
  }
}
.row:last-child div[class*='col-']:last-child .hover-element {
  margin-bottom: 0;
}
/**! 20. Masonry **/
.masonry .masonry__container.masonry--active .masonry__item {
  opacity: 1;
  pointer-events: all;
}
.masonry .masonry__container .masonry__item {
  opacity: 0;
  pointer-events: none;
}
.masonry .masonry__filters li {
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.masonry .masonry__filters li.active {
  cursor: default;
}
.masonry.masonry--gapless .masonry__item {
  padding: 0 !important;
  margin-bottom: 0;
}
/**! 21. Modals **/
.modal-instance .modal-body {
  display: none;
}
.modal-container {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.modal-container.modal-active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.modal-container:before {
  background: rgba(0, 0, 0, 0.85);
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.modal-container .modal-content {
  backface-visibility: hidden;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  max-height: 100%;
  overflow-y: scroll;
  border: none;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
.modal-container .modal-content:not(.height--natural) {
  width: 50%;
  height: 50%;
}
.modal-container .modal-content .modal-close-cross {
  cursor: pointer;
  position: absolute;
  opacity: .5;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  top: 1em;
  right: 1em;
  z-index: 99;
}
.modal-container .modal-content .modal-close-cross:before {
  content: '\00D7';
  font-size: 1.5em;
}
.modal-container .modal-content .modal-close-cross:hover {
  opacity: 1;
}
.modal-container .modal-content.imagebg:not(.image--light) .modal-close-cross:before {
  color: #fff;
}
.modal-container .modal-content iframe {
  width: 100%;
  outline: none;
  border: none;
  height: 100%;
  backface-visibility: hidden;
}
.modal-container .modal-content iframe:first-child + .modal-close-cross:last-child {
  top: -3.71428571em;
}
.modal-content.section-modal {
  pointer-events: none;
}
.modal-content.section-modal [class*='col-'] {
  pointer-events: all;
}
@media all and (max-width: 767px) {
  .modal-container .modal-content {
    width: 97% !important;
    height: auto !important;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
/**! 22. Maps **/
.map-container {
  position: relative;
  overflow: hidden;
}
.map-container iframe,
.map-container .map-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
/**! 23. Parallax **/
.parallax > .background-image-holder,
.parallax .slides li > .background-image-holder {
  height: 100%;
  min-height: 100vh;
  top: -50vh;
  transition: opacity 0.3s ease !important;
  -webkit-transition: opacity 0.3s ease !important;
  -webkit-transform-style: preserve-3d;
}
.parallax:first-child .slides li > .background-image-holder,
.parallax:first-child .background-image-holder {
  top: 0;
}
.main-container > a:first-child + .parallax .background-image-holder {
  top: 0;
}
@media all and (max-width: 1024px) {
  .parallax > .background-image-holder,
  .parallax .slides li > .background-image-holder {
    -webkit-transition: transform 0.016s linear !important;
    transition: transform 0.016s linear !important;
  }
  .parallax.parallax-disable-mobile .background-image-holder,
  .parallax.parallax-disable-mobile .slides li > .background-image-holder {
    top: 0 !important;
    transform: none !important;
  }
}
/**! 24. Notifications **/
.notification {
  max-width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: fixed;
  z-index: 99;
  pointer-events: none;
  padding: 0;
  margin: 1em;
  opacity: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.notification:not([class*='bg-']) {
  background: #fff;
}
.notification[class*='col-'] {
  min-width: 400px;
}
.notification .notification-close-cross {
  position: absolute;
  top: 1em;
  z-index: 99;
  right: 1em;
  cursor: pointer;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  opacity: .7;
}
.notification .notification-close-cross:before {
  content: '\00D7';
  font-size: 1.5em;
}
.notification .notification-close-cross:hover {
  opacity: 1;
}
.notification.notification--reveal {
  z-index: 99;
  pointer-events: initial;
}
.notification.notification--reveal[data-animation="from-bottom"] {
  animation: from-bottom 0.3s linear 0s forwards;
  -webkit-animation: from-bottom 0.3s linear 0s forwards;
  -moz-animation: from-bottom 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-top"] {
  animation: from-top 0.3s linear 0s forwards;
  -webkit-animation: from-top 0.3s linear 0s forwards;
  -moz-animation: from-top 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-left"] {
  animation: from-left 0.3s linear 0s forwards;
  -webkit-animation: from-left 0.3s linear 0s forwards;
  -moz-animation: from-left 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-right"] {
  animation: from-right 0.3s linear 0s forwards;
  -webkit-animation: from-right 0.3s linear 0s forwards;
  -moz-animation: from-right 0.3s linear 0s forwards;
}
.notification.notification--dismissed {
  animation: fade-out 0.4s linear 0s forwards !important;
  -webkit-animation: fade-out 0.4s linear 0s forwards !important;
  -moz-animation: fade-out 0.4s linear 0s forwards !important;
  pointer-events: none;
}
.bg--dark + .notification-close-cross:before {
  color: #fff;
}
a[data-notification-link] {
  text-decoration: none;
}
a[data-notification-link]:hover {
  text-decoration: none;
}
@media all and (max-width: 767px) {
  .notification[class*='col-'] {
    min-width: 0;
  }
}
@keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/**! 25. Video **/
iframe {
  width: 100%;
  min-height: 350px;
  border: none;
}
@media all and (max-width: 767px) {
  iframe {
    min-height: 220px;
  }
}
.videobg {
  background: #252525;
  position: relative;
  overflow: hidden;
}
.videobg .container,
.videobg .background-image-holder {
  opacity: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.videobg .background-image-holder {
  opacity: 0 !important;
}
.videobg.video-active .container {
  opacity: 1;
}
.videobg.video-active .loading-indicator {
  opacity: 0;
  visibility: hidden;
}
.videobg video {
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  z-index: 0 !important;
  left: 0;
}
@media all and (max-width: 1024px) {
  .videobg .background-image-holder,
  .videobg .container {
    opacity: 1 !important;
  }
  .videobg .loading-indicator {
    display: none;
  }
  .videobg video {
    display: none;
  }
}
.youtube-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 0 !important;
}
.youtube-background .mb_YTPBar {
  opacity: 0;
  height: 0;
  visibility: hidden;
}
@media all and (max-width: 1024px) {
  .youtube-background {
    display: none;
  }
}
.loading-indicator {
  position: absolute !important;
  top: 50%;
  left: 50%;
  z-index: 99 !important;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: loading-spinner 1s infinite ease-in-out;
  animation: loading-spinner 1s infinite ease-in-out;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
@-webkit-keyframes loading-spinner {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes loading-spinner {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.video-cover {
  position: relative;
}
.video-cover video {
  max-width: 100%;
}
.video-cover iframe {
  background: #252525;
}
.video-cover .background-image-holder {
  z-index: 3;
}
.video-cover .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
}
.video-cover .video-play-icon,
.video-cover .background-image-holder {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.video-cover.reveal-video .video-play-icon,
.video-cover.reveal-video .background-image-holder {
  opacity: 0 !important;
  pointer-events: none;
}
.video-cover[data-scrim-bottom]:before,
.video-cover[data-overlay]:before,
.video-cover[data-scrim-top]:before {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  z-index: 4;
}
.video-cover.reveal-video[data-scrim-bottom]:before,
.video-cover.reveal-video[data-overlay]:before,
.video-cover.reveal-video[data-scrim-top]:before {
  opacity: 0;
  pointer-events: none;
}
.video-play-icon {
  width: 7.42857143em;
  height: 7.42857143em;
  border-radius: 50%;
  position: relative;
  z-index: 4;
  display: inline-block;
  border: 2px solid #fff;
  cursor: pointer;
  background: #fff;
}
.video-play-icon.video-play-icon--sm {
  width: 3.71428571em;
  height: 3.71428571em;
}
.video-play-icon.video-play-icon--sm:before {
  border-width: 4px 0 4px 9px;
}
.video-play-icon.video-play-icon--xs {
  width: 1.85714286em;
  height: 1.85714286em;
}
.video-play-icon.video-play-icon--xs:before {
  border-width: 3px 0 3px 6px;
  margin-left: -3px;
}
.video-play-icon.bg--primary:before {
  border-color: transparent transparent transparent #fff;
}
.video-play-icon:before {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 50%;
  margin-left: -4px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 12px;
  border-color: transparent transparent transparent #fff;
  border-color: transparent transparent transparent #808080;
}
.video-play-icon.video-play-icon--dark {
  border-color: #252525;
  background: #252525;
}
.video-play-icon.video-play-icon--dark:before {
  border-color: transparent transparent transparent #252525;
}
.video-play-icon.video-play-icon--dark:before {
  border-color: transparent transparent transparent #fff;
}
@media all and (max-width: 767px) {
  .video-play-icon {
    width: 4.95238095em;
    height: 4.95238095em;
  }
}
.col-12:not([class*='col-lg']):not([class*='col-md']) .video-cover iframe {
  min-height: 550px;
}
@media all and (max-width: 990px) {
  div[class*='col-'][class*='-12']:not([class*='xs-12']) .video-cover iframe {
    min-height: 350px;
  }
}
div[class*='col-'][class*='-10'] .video-cover iframe {
  min-height: 450px;
}
div[class*='col-'][class*='-8'] .video-cover iframe {
  min-height: 400px;
}
div[class*='col-'][class*='-6'] .video-cover iframe {
  min-height: 350px;
}
@media all and (max-width: 1200px) {
  div[class*='col-'][class*='-6'] .video-cover iframe {
    min-height: 300px;
  }
}
@media all and (max-width: 990px) {
  div[class*='col-'][class*='-6'] .video-cover iframe {
    min-height: 220px;
  }
}
@media all and (max-width: 767px) {
  div[class*='col-'] .video-cover iframe {
    min-height: 220px !important;
  }
}
.modal-container video {
  max-width: 100%;
}
/**! 26. Colors **/
body {
  background: #FFFFFF;
}
.color--primary {
  color: #E62E6A !important;
}
a {
  color: #E62E6A;
}
.color--primary-1 {
  color: #DE1AF5 !important;
}
.color--primary-2 {
  color: #53294A !important;
}
.color--white {
  color: #fff;
}
.color--dark {
  color: #252525;
}
.color--success {
  color: #4EBF56;
}
.color--error {
  color: #E23636;
}
.bg--dark {
  background: #252525;
}
.bg--dark:not(.nav-bar):not(.bar) {
  color: #FFFFFF;
}
.bg--dark:not(.nav-bar):not(.bar) h1,
.bg--dark:not(.nav-bar):not(.bar) h2,
.bg--dark:not(.nav-bar):not(.bar) h3,
.bg--dark:not(.nav-bar):not(.bar) h4,
.bg--dark:not(.nav-bar):not(.bar) h5,
.bg--dark:not(.nav-bar):not(.bar) h6,
.bg--dark:not(.nav-bar):not(.bar) i,
.bg--dark:not(.nav-bar):not(.bar) span:not(.btn__text),
.bg--dark:not(.nav-bar):not(.bar) p {
  color: #FFFFFF;
}
.bg--dark:not(.nav-bar):not(.bar) a:not(.btn) {
  color: #fff;
}
.bg--site {
  background: #FFFFFF;
}
.bg--secondary {
  background: #FAFAFA;
}
.bg--primary {
  background: #E62E6A;
}
.bg--primary p,
.bg--primary span,
.bg--primary ul,
.bg--primary a:not(.btn) {
  color: #fff;
}
.bg--primary h1,
.bg--primary h2,
.bg--primary h3,
.bg--primary h4,
.bg--primary h5,
.bg--primary h6,
.bg--primary i {
  color: #fff;
}
.bg--primary .color--primary {
  color: #fff !important;
}
.bg--white {
  background: #fff;
}
.bg--white p,
.bg--white span,
.bg--white ul,
.bg--white a:not(.btn) {
  color: #666666;
}
.bg--white h1,
.bg--white h2,
.bg--white h3,
.bg--white h4,
.bg--white h5,
.bg--white h6,
.bg--white i {
  color: #252525;
}
.bg--error {
  background: #E23636;
}
.bg--success {
  background: #4EBF56;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white span,
.imagebg:not(.image--light) .bg--white ul,
.imagebg:not(.image--light) .bg--white a:not(.btn) {
  color: #666666;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6,
.imagebg:not(.image--light) .bg--white i {
  color: #252525;
}
.imagebg:not(.image--light) .bg--secondary {
  background: rgba(250, 250, 250, 0.2);
}
.bg--primary-1 {
  background: #DE1AF5;
}
.bg--primary-1 p,
.bg--primary-1 span,
.bg--primary-1 ul,
.bg--primary-1 a:not(.btn) {
  color: #fff;
}
.bg--primary-1 h1,
.bg--primary-1 h2,
.bg--primary-1 h3,
.bg--primary-1 h4,
.bg--primary-1 h5,
.bg--primary-1 h6,
.bg--primary-1 i {
  color: #fff;
}
.bg--primary-2 {
  background: #53294A;
}
.bg--primary-2 p,
.bg--primary-2 span,
.bg--primary-2 ul,
.bg--primary-2 a:not(.btn) {
  color: #fff;
}
.bg--primary-2 h1,
.bg--primary-2 h2,
.bg--primary-2 h3,
.bg--primary-2 h4,
.bg--primary-2 h5,
.bg--primary-2 h6,
.bg--primary-2 i {
  color: #fff;
}
.image-bg:not(.image-light) *:not(a) {
  color: #fff;
}
.color--facebook {
  color: #3b5998;
}
.color--twitter {
  color: #00aced;
}
.color--googleplus {
  color: #dd4b39;
}
.color--instagram {
  color: #125688;
}
.color--pinterest {
  color: #cb2027;
}
.color--dribbble {
  color: #ea4c89;
}
.color--behance {
  color: #053eff;
}
.bg--facebook {
  background: #3b5998;
  color: #fff;
}
.bg--twitter {
  background: #00aced;
  color: #fff;
}
.bg--googleplus {
  background: #dd4b39;
  color: #fff;
}
.bg--instagram {
  background: #125688;
  color: #fff;
}
.bg--pinterest {
  background: #cb2027;
  color: #fff;
}
.bg--dribbble {
  background: #ea4c89;
  color: #fff;
}
.bg--behance {
  background: #053eff;
  color: #fff;
}
/**! 27. Image Blocks **/
.imageblock {
  position: relative;
  padding: 0;
}
.imageblock > .container,
.imageblock > div[class*='col-']:not(.imageblock__content) {
  padding-top: 7.42857143em;
  padding-bottom: 7.42857143em;
  float: none;
  overflow: hidden;
}
.imageblock.imageblock--lg > .container,
.imageblock.imageblock--lg > div[class*='col-']:not(.imageblock__content) {
  padding-top: 9.28571429em;
  padding-bottom: 9.28571429em;
  float: none;
  overflow: hidden;
}
.imageblock .imageblock__content {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;
  padding: 0;
}
.imageblock .imageblock__content .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.imageblock .imageblock__content .slider .slides > li {
  padding: 0;
  min-height: 100%;
  position: absolute !important;
}
.imageblock.allow-overflow .imageblock__content {
  overflow: visible;
}
@media all and (max-height: 728px) {
  .imageblock > .container,
  .imageblock > div[class*='col-']:not(.imageblock__content) {
    padding-top: 3.71428571em;
    padding-bottom: 3.71428571em;
  }
}
@media all and (max-width: 767px) {
  .imageblock[class*='space-'] {
    padding-bottom: 0;
    padding-top: 0;
  }
  .imageblock .imageblock__content {
    position: relative;
    min-height: 18.57142857em;
  }
  .imageblock > .container,
  .imageblock > div[class*='col-']:not(.imageblock__content) {
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
    float: none;
    overflow: hidden;
  }
  .imageblock.imageblock--lg > .container,
  .imageblock.imageblock--lg > div[class*='col-']:not(.imageblock__content) {
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
    float: none;
    overflow: hidden;
  }
}
/**! 28. MailChimp & Campaign Monitor **/
form[action*='createsend.com'] * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
}
form[action*='createsend.com'].form--active * {
  opacity: 1;
}
form[action*='createsend.com'] .input-checkbox + br {
  display: none;
}
form[action*='createsend.com'].no-labels label {
  display: none;
}
form[action*='createsend.com'] br {
  display: none;
}
form[action*='createsend.com'] p > label:first-child {
  margin-bottom: 0.92857143em;
}
form[action*='list-manage.com'] h2 {
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  color: #252525;
  font-weight: 300;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.35714286em;
  line-height: 1.36842105em;
  margin-bottom: 1.36842105263158em;
  font-weight: 400;
}
form[action*='list-manage.com'] h2.inline-block + .h4.inline-block:not(.typed-text) {
  margin-left: 0.68421052631579em;
}
form[action*='list-manage.com'] .input-group ul {
  overflow: hidden;
}
form[action*='list-manage.com'] .input-group ul li {
  float: left;
}
form[action*='list-manage.com'] * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
}
form[action*='list-manage.com'].form--active * {
  opacity: 1;
}
form[action*='list-manage.com'].no-labels label {
  display: none;
}
form[action*='list-manage.com'] .small-meta {
  font-size: 0.5em;
}
/**! 29. Twitter **/
.twitter-feed .user {
  display: none;
}
.twitter-feed .interact {
  display: none;
}
.twitter-feed .timePosted {
  font-size: .87em;
}
/**! 30. Transitions **/
[class*='transition--'] {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  opacity: 0;
}
[class*='transition--'].transition--active {
  opacity: 1;
}
.transition--scale {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
}
.transition--scale.transition--active {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
}
.transition--slide {
  transform: translate3d(200px, 0, 0);
  -webkit-transform: translate3d(200px, 0, 0);
  transform: translate3d(30vw, 0, 0);
  -webkit-transform: translate3d(30vw, 0, 0);
}
.transition--slide.transition--active {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
/**! 31. Switchable Sections **/
.switchable {
  position: relative;
}
.switchable div[class*='col-']:first-child {
  float: left;
  right: auto;
}
.switchable div[class*='col-']:first-child:not([class*='pull']):not([class*='push']) {
  left: 0;
}
.switchable div[class*='col-']:last-child {
  float: right;
  left: auto;
}
.switchable div[class*='col-']:last-child:not([class*='pull']):not([class*='push']) {
  right: 0;
}
.switchable.switchable--switch div[class*='col-']:first-child {
  float: right;
  right: 0;
  left: auto;
}
.switchable.switchable--switch div[class*='col-']:first-child:not([class*='pull']):not([class*='push']) {
  left: auto;
}
.switchable.switchable--switch div[class*='col-']:last-child {
  float: left;
  left: 0;
  right: auto;
}
.switchable .switchable__text {
  margin-top: 3.71428571em;
}
.switchable > div[class*='col-'] {
  padding: 0;
}
/**! 32. Typed Effect **/
.typed-text {
  display: inline-block;
}
.typed-text.typed-text--cursor:after {
  content: '|';
  font-size: 1.2em;
  -webkit-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
  position: relative;
  right: 6px;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/**! 33. Gradient BG **/
[data-gradient-bg] {
  position: relative;
  background: #252525;
}
[data-gradient-bg] > canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
[data-gradient-bg] > canvas + .background-image-holder {
  opacity: .2 !important;
}
/**! 34. Bars **/
.bar {
  padding: 1.85714286em 0;
}
.bar .logo {
  margin: 0;
  position: relative;
  top: 4px;
}
.bar .menu-horizontal {
  position: relative;
  top: 6px;
}
.bar:not([class*='visible-']) + .bar {
  margin-top: 0.92857143em;
}
.bar.bar--xs {
  padding: 0.46428571em 0;
}
.bar.bar--sm {
  padding: 0.92857143em 0;
}
.bar.bar--lg {
  padding: 2.78571429em 0;
}
.bar.bar--lg .logo {
  top: 0;
}
.bar.bar--xlg {
  padding: 4.64285714em 0;
}
.bar.bar--xlg .logo {
  top: 0;
}
.bar.bg--dark .logo-dark {
  display: none;
}
.bar:not(.bg--dark):not(.bar--transparent) .logo-light {
  display: none;
}
@media all and (max-width: 767px) {
  .bar.bar--mobile-sticky[data-scroll-class*='fixed'].pos-fixed {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
  }
  .bar.bar--mobile-sticky[data-scroll-class*='fixed'] + .bar.pos-fixed {
    top: 3.71428571em;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
  }
}
@media all and (min-width: 991px) {
  .bar__module:not(:only-child) {
    display: inline-block;
  }
  .bar__module:not(:last-child) {
    margin-right: 0.92857143em;
  }
  .bar--transparent:not(.bar--dark) {
    background: none;
  }
  .bar--transparent:not(.bar--dark) .logo-dark {
    display: none;
  }
  .bar--transparent:not(.bar--dark) .logo-light {
    display: inline-block;
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .menu-horizontal > li > a,
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .menu-horizontal > li > span {
    color: #fff;
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']) {
    border-color: rgba(255, 255, 255, 0.3);
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']) .btn__text {
    color: #fff;
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']):hover {
    border-color: rgba(255, 255, 255, 0.7);
  }
  .bar--absolute {
    position: absolute;
  }
  .bar--absolute,
  .pos-fixed {
    z-index: 99;
    width: 100%;
  }
  .bar.pos-fixed {
    position: fixed;
    top: 0;
    animation: fadeInDown 0.3s ease-out forwards;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  }
  .bar.pos-fixed:not([class*='bg-']) {
    background: #FFFFFF;
  }
  .bar.pos-fixed:not([class*='bg-']) .logo-dark {
    display: inline-block;
  }
  .bar.pos-fixed:not([class*='bg-']) .logo-light {
    display: none;
  }
  .bar.pos-fixed.bg--dark {
    background: #252525;
  }
}
@media all and (max-width: 767px) {
  .bar__module {
    margin-bottom: 0.92857143em;
  }
  .bar__module + .bar__module {
    margin-top: 1.85714286em;
  }
  .bar__module .btn {
    display: block;
  }
  .bar__module .btn + .btn {
    margin-left: 0 !important;
  }
  .bar__module .btn:not(:last-child) {
    margin-bottom: 0.92857143em;
  }
  .bar + nav.bar {
    padding-top: 0;
  }
}
/**! 35. Navigation InPage **/
.page-navigator {
  position: fixed;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 1.85714286em;
  z-index: 10;
}
.page-navigator ul {
  display: inline-block;
  padding: 0.92857143em;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 1.85714286em;
  transition: all .2s ease;
}
.page-navigator ul:hover {
  background: rgba(0, 0, 0, 0.6);
}
.page-navigator ul li:not(:last-child) {
  margin-bottom: 1.85714286em;
}
@media all and (max-width: 767px) {
  .page-navigator {
    right: 0;
  }
  .page-navigator ul {
    border-radius: 1.85714286em 0 0 1.85714286em;
    padding: 1.85714286em 0.92857143em;
  }
}
.page-navigator li a {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  transition: all .2s ease;
  display: block;
  position: relative;
}
.page-navigator li a:not(:hover) {
  opacity: .5;
}
.page-navigator li a.inner-link--active {
  opacity: 1;
  animation: bulge .5s ease;
  -webkit-animation: bulge .5s ease;
}
@keyframes bulge {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes bulge {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.page-navigator li a[data-title]:before {
  content: attr(data-title);
  position: absolute;
  right: 12px;
  top: -14px;
  background: #222;
  color: #fff;
  border-radius: 6px;
  padding: 4px 8px;
  display: inline-block;
  transition: all .2s ease;
  white-space: nowrap;
}
.page-navigator li a[data-title]:not(:hover):before {
  opacity: 0;
  transform: translateX(-20px);
}
/**! 36. Helper Classes **/
.clearfix {
  overflow: hidden;
}
.clearfix-after:after {
  content: "";
  display: table;
  clear: both;
}
.allow-overflow {
  overflow: visible;
}
.container .row--gapless {
  padding-left: 15px;
  padding-right: 15px;
}
.container .row--gapless > div[class*='col-'] {
  padding: 0;
}
@media all and (max-width: 767px) {
  .text-left-xs {
    text-align: left;
  }
}
@media all and (max-width: 991px) {
  .text-left-sm {
    text-align: left;
  }
}
section > .row--gapless {
  padding-left: 0;
  padding-right: 0;
}
section > .row--gapless > div[class*='col-'] {
  padding: 0;
}
div.right {
  float: right;
}
div.left {
  float: left;
}
section.text-right > .container:last-child > .row:only-child > div[class*='col-']:only-child {
  float: right;
}
/**! 37. Spacing **/
section,
footer {
  padding-top: 7.42857143em;
  padding-bottom: 7.42857143em;
}
section.space--xxs,
footer.space--xxs {
  padding-top: 1.85714286em;
  padding-bottom: 1.85714286em;
}
section.space--xs,
footer.space--xs {
  padding-top: 3.71428571em;
  padding-bottom: 3.71428571em;
}
section.space--sm,
footer.space--sm {
  padding-top: 4.95238095em;
  padding-bottom: 4.95238095em;
}
section.space--md,
footer.space--md {
  padding-top: 11.14285714em;
  padding-bottom: 11.14285714em;
}
section.space--lg,
footer.space--lg {
  padding-top: 14.85714286em;
  padding-bottom: 14.85714286em;
}
section.space--xlg,
footer.space--xlg {
  padding-top: 29.71428571em;
  padding-bottom: 29.71428571em;
}
section.space--0,
footer.space--0 {
  padding: 0;
}
section.section--even,
footer.section--even {
  padding-top: 7.42857143em;
  padding-bottom: 7.42857143em;
}
section.space-bottom--sm,
footer.space-bottom--sm {
  padding-bottom: 4.95238095em;
}
@media all and (max-width: 767px) {
  section,
  footer,
  section.section--even {
    padding: 5.57142857em 0;
  }
  section.space--lg,
  footer.space--lg,
  section.section--even.space--lg,
  section.space--md,
  footer.space--md,
  section.section--even.space--md {
    padding: 5.57142857em 0;
  }
  section.space--xlg,
  footer.space--xlg,
  section.section--even.space--xlg {
    padding: 8.35714286em 0;
  }
}
div[class*='col-'] > div[class*='col-']:first-child {
  padding-left: 0;
}
div[class*='col-'] > div[class*='col-']:last-child {
  padding-right: 0;
}
@media all and (max-width: 767px) {
  .col-xs-6:nth-child(odd) {
    padding-right: 7.5px;
  }
  .col-xs-6:nth-child(even) {
    padding-left: 7.5px;
  }
}
@media all and (min-width: 768px) {
  .mt--1 {
    margin-top: 1.85714286em;
  }
  .mt--2 {
    margin-top: 3.71428571em;
  }
  .mt--3 {
    margin-top: 5.57142857em;
  }
  .mb--1 {
    margin-bottom: 1.85714286em;
  }
  .mb--2 {
    margin-bottom: 3.71428571em;
  }
  .mb--3 {
    margin-bottom: 5.57142857em;
  }
}
@media all and (max-width: 990px) {
  .mt--1,
  .mt--2 {
    margin-top: 1.85714286em;
  }
  .mt--3 {
    margin-top: 2.78571429em;
  }
}
.unpad {
  padding: 0;
}
.unpad--bottom {
  padding-bottom: 0;
}
.unpad--top {
  padding-top: 0;
}
section.unpad--bottom {
  padding-bottom: 0;
}
section.unpad {
  padding: 0;
}
section.unpad--top {
  padding-top: 0;
}
.unmarg--bottom {
  margin-bottom: 0;
}
.unmarg {
  margin: 0;
}
.unmarg--top {
  margin-top: 0;
}
/**! 38. Boxed Layout **/
@media all and (min-width: 1280px) {
  body.boxed-layout {
    padding: 3.71428571em 0;
    background: #ededed;
  }
  body.boxed-layout section:not([class*='bg-']):not(.imagebg),
  body.boxed-layout footer:not([class*='bg-']):not(.imagebg),
  body.boxed-layout nav:not([class*='bg-']):not(.bar--transparent):not(.bar--absolute),
  body.boxed-layout .tabs-container:not([class*='bg-']):not(.imagebg) {
    background: #FFFFFF;
  }
  body.boxed-layout .nav-container,
  body.boxed-layout .main-container,
  body.boxed-layout > section,
  body.boxed-layout nav {
    max-width: 1280px;
    margin: 0 auto;
  }
}
/**! 39. Wizard **/
.wizard__body {
  list-style: none;
}
.wizard__step:not(.active) {
  display: none;
}
.wizard__step .wizard__title {
  display: none;
}
.wizard__controls {
  overflow: hidden;
}
.wizard__controls .wizard-prev {
  float: left;
}
.wizard__controls .wizard-next {
  float: right;
}
.wizard__controls .wizard-prev:hover,
.wizard__controls .wizard-next:hover {
  transform: none !important;
}
.wizard__controls .wizard-prev.inactive,
.wizard__controls .wizard-next.inactive {
  pointer-events: none;
  opacity: .3;
  cursor: not-allowed;
}
/**! 40. Alerts **/
.alert {
  overflow: hidden;
  border: 1px solid #e6e6e6;
  padding: 0.92857143em;
}
.alert .alert__body,
.alert .alert__close {
  display: inline-block;
  user-select: none;
}
.alert .alert__body {
  float: left;
}
.alert .alert__close {
  float: right;
  cursor: pointer;
}
.alert.bg--error {
  background: #fce8e8;
  border-color: #E23636;
}
.alert.bg--error .alert__close {
  color: #E23636;
}
.alert.bg--success {
  background: #e4f5e5;
  border-color: #4EBF56;
}
.alert.bg--success .alert__close {
  color: #4EBF56;
}
.alert.bg--primary {
  background: #fce4ec;
  border-color: #E62E6A;
}
.alert.bg--primary .alert__body > span {
  color: #666666;
}
.alert.bg--primary .alert__close {
  color: #E62E6A;
}
.alert.alert--dismissed {
  display: none;
}
/**! 41. Progress - Horizontal **/
.progress-horizontal:after {
  content: "";
  display: table;
  clear: both;
}
.progress-horizontal .progress-horizontal__bar {
  position: relative;
  overflow: hidden;
}
.progress-horizontal .progress-horizontal__progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
/**! 42. Theme Overrides **/
/*! -- Stack Customizers -- */
.box-shadow {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.box-shadow-shallow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
}
.box-shadow-wide {
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.border--round {
  border-radius: 6px;
}
.border--round:before {
  border-radius: 6px;
}
.border--round .background-image-holder {
  border-radius: 6px;
}
.border--round [data-scrim-top]:before,
.border--round [data-scrim-bottom]:before,
.border--round [data-overlay]:before {
  border-radius: 6px;
}
.imageblock.border--round .background-image-holder {
  border-radius: 6px 0 0 6px;
}
@media all and (max-width: 767px) {
  .imageblock.border--round .background-image-holder {
    border-radius: 6px 6px 0 0;
  }
}
.theme--square .border--round,
.theme--square .btn {
  border-radius: 0px;
}
.theme--bordered {
  border: 0.92857143em solid #252525;
}
.main-container.transition--fade:not(.transition--active) {
  cursor: wait;
}
@media all and (min-width: 1280px) {
  body.boxed-layout > section.bar-3:first-of-type {
    border-radius: 6px 6px 0 0;
  }
  body.boxed-layout .main-container > footer:last-child {
    border-radius: 0 0 6px 6px;
  }
}
body.boxed-layout .modal-container section:not([class*='bg-']) {
  background: none;
}
/*! -- Stack Helpers -- */
@media all and (max-width: 767px) {
  .block--xs {
    margin-top: 0.92857143em;
  }
}
.container .container {
  max-width: 100%;
}
.switchable-toggle {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}
.back-to-top {
  position: fixed;
  width: 3.71428571em;
  height: 3.71428571em;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  right: 1.85714286em;
  bottom: 3.71428571em;
  padding-top: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  z-index: 99;
  border: 1px solid #ececec;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
}
.back-to-top i {
  color: #252525;
}
.back-to-top:not(.active) {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  -webkit-transform: translate3d(0, 20px, 0);
  pointer-events: none;
}
.back-to-top.active:hover {
  transform: translate3d(0, -5px, 0);
  -webkit-transform: translate3d(0, -5px, 0);
}
.disable-scroll-bars {
  -ms-overflow-style: none;
}
.disable-scroll-bars::-webkit-scrollbar {
  display: none;
}
/*! -- Stack Animations -- */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
    -webkit-transform: translate3d(0, -100px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
    -webkit-transform: translate3d(0, -100px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
    -webkit-transform: translate3d(0, -50px, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
    -webkit-transform: translate3d(0, -50px, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
    -webkit-transform: translate3d(50px, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
    -webkit-transform: translate3d(50px, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
    -webkit-transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
    -webkit-transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2);
    -webkit-transform: scale(2);
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2);
    -webkit-transform: scale(2);
  }
}
@keyframes kenBurns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@-webkit-keyframes kenBurns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
/*! -- Stack Sections -- */
section:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom):not(.space--xxs) + section:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.border--bottom):not(.space--xxs) {
  padding-top: 0;
}
section:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom) + footer:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom) {
  padding-top: 0;
}
section:not(.imagebg):not([class*='bg-']) + section.bg--secondary {
  border-top: 1px solid #ebebeb;
}
section.bg--secondary:not(.imagebg) + section:not(.imagebg):not([class*='bg-']) {
  border-top: 1px solid #ebebeb;
}
section.bg--secondary:not(.unpad):not(.imageblock):not(.unpad--bottom):not(.border--bottom) + section.bg--secondary {
  padding-top: 0;
}
section.bg--secondary + footer:not(.bg--dark):not(.bg--secondary) {
  border-top: 1px solid #ebebeb;
}
section.bg--dark + section.bg--dark {
  padding-top: 0;
}
section.bg--dark:last-of-type + footer.bg--dark {
  background: #1b1b1b;
}
section.border--bottom:not([data-gradient-bg]) {
  border-bottom: 1px solid #ececec;
}
section.unpad {
  overflow: hidden;
}
section:not([class*='bg--']) + footer.bg--secondary {
  border-top: 1px solid #ebebeb;
}
section.text-center div[class*='col-']:first-child:last-child {
  margin: 0 auto;
  float: none;
}
.section--overlap {
  z-index: 2;
  position: relative;
}
/*! -- Stack Typography -- */
@media all and (max-width: 1024px) {
  html {
    font-size: 80%;
  }
}
h1,
.h1 {
  letter-spacing: -0.01em;
}
h1:not(:last-child),
.h1:not(:last-child) {
  margin-bottom: 0.59090909090909em;
}
@media all and (min-width: 768px) {
  h1.h1--large,
  .h1.h1--large {
    font-weight: 200;
    font-size: 4.428571428571429em;
    line-height: 1.048387096774194em;
  }
  h1.h1--large:not(:last-child),
  .h1.h1--large:not(:last-child) {
    margin-bottom: 0.419354838709677em;
  }
  h1.h1--large.type--uppercase,
  .h1.h1--large.type--uppercase {
    letter-spacing: 10px;
    margin-right: -10px;
  }
  h1.h1--large + p.lead,
  .h1.h1--large + p.lead {
    margin-top: 2.052631578947368em;
  }
}
h2,
.h2 {
  margin-bottom: 0.78787878787879em;
}
h3,
.h3 {
  margin-bottom: 1.04em;
}
h3 strong,
.h3 strong {
  font-weight: 400;
}
blockquote {
  font-family: 'Merriweather', serif;
  font-style: italic;
  font-weight: 300;
}
blockquote:not(:last-child) {
  margin-bottom: 1.04em;
}
blockquote > p {
  font-size: 1em !important;
}
h4,
.h4 {
  margin-bottom: 1.36842105263158em;
  font-weight: 400;
}
h4.inline-block + .h4.inline-block:not(.typed-text),
.h4.inline-block + .h4.inline-block:not(.typed-text) {
  margin-left: 0.68421052631579em;
}
h5,
.h5 {
  font-weight: 600;
}
h5:not(:last-child),
.h5:not(:last-child) {
  margin-bottom: 1.85714286em;
}
h6,
.h6 {
  font-weight: 700;
}
h6:not(:last-child),
.h6:not(:last-child) {
  margin-bottom: 2.16666666666667em;
}
h6.type--uppercase,
.h6.type--uppercase {
  letter-spacing: 1px;
  margin-right: -1px;
}
span.h1:not(.inline-block),
span.h2:not(.inline-block),
span.h3:not(.inline-block),
span.h4:not(.inline-block),
span.h5:not(.inline-block),
span.h6:not(.inline-block) {
  display: block;
}
b {
  font-weight: 600;
}
hr {
  border-color: #ECECEC;
}
.bg--dark hr {
  border-color: #585858;
}
[class*='bg-']:not(.bg--white):not(.bg--secondary) p,
[class*='imagebg']:not(.image--light) p {
  opacity: .9;
}
.lead {
  font-weight: 400;
  color: #808080;
}
.lead:not(:last-child) {
  margin-bottom: 1.36842105263158em;
}
.lead + .btn:last-child {
  margin-top: 0.92857143em;
}
p:last-child {
  margin-bottom: 0;
}
p strong {
  color: #252525;
}
pre {
  padding: 0.92857143em;
  background: #FAFAFA;
  border: 1px solid #ececec;
  border-radius: 6px;
  line-height: 20px;
  max-height: 500px;
}
.bg--secondary > pre {
  background: #f5f5f5;
  border-color: #ddd;
}
.text-block {
  margin-bottom: 1.85714286em;
}
.text-block h2,
.text-block .h2 {
  margin-bottom: 0.3939393939394em;
}
.text-block h5,
.text-block .h5 {
  margin: 0;
}
.text-block h4:not(:last-child),
.text-block .h4:not(:last-child) {
  margin-bottom: 0.3421052631579em;
}
.text-block h3,
.text-block .h3 {
  margin-bottom: 0.52em;
}
@media all and (min-width: 768px) {
  div[class*='col-'] .text-block + .text-block {
    margin-top: 3.71428571em;
  }
}
.heading-block {
  margin-bottom: 3.71428571em;
}
.heading-block h1,
.heading-block h2,
.heading-block h3,
.heading-block h4,
.heading-block h5,
.heading-block h6,
.heading-block .h1,
.heading-block .h2,
.heading-block .h3,
.heading-block .h4,
.heading-block .h5,
.heading-block .h6 {
  margin-bottom: 0;
}
/*! -- Stack Colours -- */
.bg--dark .bg--secondary {
  background: #343434;
}
/*! -- Stack Links -- */
a {
  color: #E62E6A;
  font-weight: 700;
}
a:hover {
  color: #c91852;
  text-decoration: underline;
}
a.block {
  font-weight: normal;
  text-decoration: none;
  color: #666666;
}
p a,
span a,
label a {
  font-size: 1em;
  text-decoration: underline;
  font-weight: 400;
  line-height: 1.85714286em;
}
p + a:not(.btn) {
  font-size: 0.85714286em;
  line-height: 2.16666667em;
}
.imagebg:not(.image--light) a {
  color: #fff;
  font-weight: 600;
}
/*! -- Stack Tables -- */
table {
  width: 100%;
  border-collapse: separate;
}
table th,
table td {
  padding: 0.92857143em;
}
table th {
  background: #FAFAFA;
  color: #252525;
}
table tr:not(:last-of-type) {
  border-bottom: 1px solid #ececec;
}
table.border--round {
  border-radius: 6px;
  border: 1px solid #ececec;
}
.table--alternate-column th:nth-child(odd) {
  background: none;
}
.table--alternate-column td:nth-child(even) {
  background: #FAFAFA;
}
.table--alternate-row tbody tr:nth-child(even) {
  background: #FAFAFA;
}
.bg--dark table.border--round {
  border-radius: 6px;
  border: 1px solid #3f3f3f;
}
.bg--dark table th {
  background: #3f3f3f;
  color: #fff;
}
.bg--dark .table--alternate-row tbody tr:nth-child(even) {
  background: #323232;
}
/*! -- Stack Lists -- */
ul:not([class*='menu']) li > a {
  font-weight: normal;
}
ul:not([class*='menu']) li > a:hover {
  text-decoration: none;
}
ol {
  list-style-position: outside;
  list-style-type: decimal;
}
ol li:not(:last-child) {
  margin-bottom: 1.85714286em;
}
ol.lead li:not(:last-child) {
  margin-bottom: 1.26315789473684em;
}
.list-inline {
  margin-left: 0;
  display: inline-block;
}
.list-inline li {
  display: inline-block;
  padding: 0;
}
.list-inline li:not(:last-child) {
  margin-right: 1.85714286em;
}
.list-inline:not(:last-child) {
  margin-right: 1.85714286em;
}
.list-inline--images img {
  max-height: 2.78571429em;
}
@media all and (min-width: 768px) {
  .list-inline--images li:not(:last-child) {
    margin-right: 5.57142857em;
  }
}
@media all and (max-width: 767px) {
  .list-inline--images li:not(:last-child) {
    margin-bottom: 1.85714286em;
  }
}
.list--loose > li:not(:last-child) {
  margin-bottom: 0.46428571em;
}
.list--hover li {
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
}
.list--hover li:not(:hover) {
  opacity: .6;
}
.social-list a {
  color: #252525;
}
.imagebg:not(.image--light) .social-list a {
  color: #fff;
}
.results-list > li > a:first-child {
  display: flex;
  align-items: center;
  margin-bottom: 0.92857143em;
}
.results-list > li > a:first-child span {
  display: inline-block;
  margin-left: 0.46428571em;
}
.results-list > li > a:first-child h4 {
  display: inline-block;
  margin-bottom: 0;
}
.results-list > li > a:first-child:hover h4,
.results-list > li > a:first-child:hover span {
  text-decoration: underline;
}
.results-list > li:not(:last-child) {
  margin-bottom: 1.85714286em;
  padding-bottom: 1.85714286em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media all and (max-width: 767px) {
  .list-inline:not(.social-list):not(.list-inline--images) {
    display: block;
  }
  .list-inline:not(.social-list):not(.list-inline--images) li {
    display: block;
    margin: 0;
  }
  .list-inline:not(.social-list):not(.list-inline--images) li:not(:last-child) {
    margin-bottom: 0.46428571em;
  }
}
@media all and (max-width: 767px) {
  .list-inline {
    min-width: 100%;
  }
}
.row--list span.h6 {
  margin-bottom: 0;
}
.row--list span.h3:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .row--list > li {
    margin-bottom: 0.92857143em;
  }
}
/*! -- Stack Rules -- */
hr:first-child {
  margin-top: 0;
}
hr.short {
  width: 2.78571429em;
  border-color: #E62E6A;
}
hr[data-title] {
  margin: 2.78571429em 0;
  text-align: center;
}
hr[data-title]:before {
  content: attr(data-title);
  background: #FFFFFF;
  position: relative;
  bottom: 14px;
  padding: 0.92857143em;
  font-style: italic;
}
.bg--dark hr:not(.short),
.imagebg hr:not(.short),
.bg--primary hr:not(.short) {
  opacity: .3;
}
/*! -- Stack Buttons -- */
.btn {
  position: relative;
}
.btn:not([class*='primary']) {
  border-color: #d3d3d3;
}
.btn:not([class*='primary']):hover {
  border-color: #252525;
}
.btn.type--uppercase {
  letter-spacing: .5px;
}
.btn.type--uppercase .btn__text {
  letter-spacing: .5px;
  margin-right: -0.5px;
}
.btn .label {
  top: -0.92857143em;
  right: -3.71428571em;
}
.btn.btn--lg .btn__text {
  font-weight: 600;
}
.btn.btn--lg.type--uppercase .btn__text {
  letter-spacing: 1px;
}
.btn + p.type--fine-print,
.btn + span.type--fine-print {
  margin-top: 1.08333333333334em;
}
.btn.block {
  margin-left: 0;
}
.btn.block + .btn.block {
  margin-top: 0.92857143em;
}
.btn:hover {
  transform: translate3d(0, -2px, 0);
  -webkit-transform: translate3d(0, -2px, 0);
}
.btn.btn--sm + .btn--sm {
  margin-left: 0.92857143em;
}
p > .btn {
  text-decoration: none;
}
@media all and (max-width: 767px) {
  .btn:not(:last-child) {
    margin-bottom: 1.85714286em;
  }
}
.btn--icon {
  padding-left: 5.57142857em;
}
.btn--icon i {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  padding: 0 13px;
  border-radius: 6px 0 0 6px;
  font-size: 16px;
  line-height: 41px;
}
.btn--icon i.socicon {
  line-height: 42px;
}
.btn--cart {
  width: 3.71428571em;
  height: 3.71428571em;
  border: 2px solid #252525;
  display: block;
  text-align: center;
  border-radius: 6px;
  opacity: .4;
  padding: 0;
}
.btn--cart:hover {
  opacity: 1;
}
.btn--cart .btn__text {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.142857142857143em;
}
.imagebg:not(.image--light) .btn--icon:not([class*='bg']):not([class*='primary']) i {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}
@media all and (min-width: 768px) {
  .btn-group .btn {
    margin-bottom: 0 !important;
    margin-left: 0;
  }
  .btn-group .btn + .btn {
    margin-left: 0;
  }
  .btn-group .btn:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .btn-group .btn:first-child {
    border-radius: 6px 0 0 6px;
  }
  .btn-group .btn:last-child {
    border-radius: 0 6px 6px 0;
  }
  .btn-group .btn:first-child:nth-last-child(2),
  .btn-group .btn:last-child:nth-child(2) {
    width: 50%;
  }
}
.btn-group {
  border: none;
  padding: 0;
}
@media all and (max-width: 767px) {
  .btn-group .btn {
    display: block;
  }
}
.bg--primary div:not([class*='feature']) .btn--primary {
  background: #fff;
  border-color: #fff;
}
.bg--primary div:not([class*='feature']) .btn--primary .btn__text {
  color: #E62E6A;
}
.bg--primary > .btn--primary {
  background: #fff;
  border-color: #fff;
}
.bg--primary > .btn--primary .btn__text {
  color: #E62E6A;
}
.bg--dark .btn:not(.btn--primary) {
  border-color: #3f3f3f;
}
.bg--dark .btn:not(.btn--primary):hover {
  border-color: #656565;
}
.bg--dark .btn .btn__text {
  color: #fff;
}
.imagebg:not(.image--light) .btn:not([class*='primary']) {
  border-color: rgba(255, 255, 255, 0.3);
}
.imagebg:not(.image--light) .btn:not([class*='primary']):hover {
  border-color: #fff;
}
.imagebg.image--light .btn:not([class*='primary']) {
  border-color: #252525;
  background: rgba(255, 255, 255, 0.3);
}
.imagebg.image--light .btn:not([class*='primary']):hover {
  background: rgba(255, 255, 255, 0.5);
}
h1 + .btn,
.h1 + .btn {
  margin-top: 1.85714286em;
}
h2 + .btn,
.h2 + .btn {
  margin-top: 0.92857143em;
}
/*! -- Stack Images -- */
img:last-child {
  margin-bottom: 0;
}
img.flag {
  max-height: 1.85714286em;
}
img.image--sm:not(:last-child) {
  margin-bottom: 0.92857143em;
}
img.promo.border--round {
  border: 1px solid #ececec;
}
p.lead img {
  max-height: 1.68421053em;
}
.imagebg h1,
.imagebg h2,
.imagebg h3,
.imagebg h4,
.imagebg h5,
.imagebg h6 {
  position: relative;
}
.imagebg:not(.image--light) span {
  color: #fff;
}
.imagebg.border--round {
  overflow: hidden;
}
section.parallax .row .background-image-holder {
  transform: none !important;
  top: 0 !important;
}
.triptych.border--round img {
  position: relative;
  border-radius: 6px;
  width: 33.333333%;
  float: left;
  margin: 0;
}
.triptych.border--round img:nth-child(2) {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  z-index: 2;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.gallery > div[class*='col-'] {
  margin-bottom: 30px;
}
.gallery-1 {
  overflow: hidden;
  position: relative;
}
.gallery-1 .gallery__image:not(:last-child) {
  margin-bottom: 30px;
}
.gallery-1 > div[class*='col-']:first-child > .gallery__image {
  height: 800px;
}
.gallery-1 > div[class*='col-']:last-child > .gallery__image {
  height: calc(385px);
}
.gallery__image {
  position: relative;
  overflow: hidden;
}
@media all and (max-width: 767px) {
  .gallery-1 .gallery__image {
    max-height: 300px;
    margin-bottom: 15px;
  }
  .gallery-1 .gallery__image:not(:last-child) {
    margin-bottom: 15px;
  }
}
.section--ken-burns {
  overflow: hidden;
}
.section--ken-burns > .background-image-holder,
.section--ken-burns > img:only-child {
  animation: kenBurns 15s ease alternate infinite;
  -webkit-animation: kenBurns 15s ease alternate infinite;
}
/*! -- Stack Titles -- */
.breadcrumbs {
  list-style: none;
}
.breadcrumbs li {
  font-size: 0.85714285714286em;
  display: inline-block;
}
.breadcrumbs li:not(:last-child) {
  margin-right: 1.08333333333334em;
}
.breadcrumbs li:not(:last-child):after {
  content: '\00bb';
  margin-left: 1.08333333333334em;
}
.breadcrumbs li a {
  font-weight: normal;
}
.imagebg .breadcrumbs,
.bg--primary .breadcrumbs {
  color: #fff;
}
.imagebg .breadcrumbs a,
.bg--primary .breadcrumbs a {
  color: #fff;
  font-weight: 600;
}
.elements-title {
  border-top: none !important;
}
.elements-title + .tabs-container:not(:nth-last-child(2)),
.elements-title + section:not(:nth-last-child(2)) {
  margin-bottom: 9.28571429em;
}
.elements-title + section:not(.imagebg):not([class*='bg-']):not(.unpad) {
  padding-top: 1.85714286em;
}
/*! -- Stack Labels -- */
.label {
  display: inline-block;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: .5px;
  color: #fff;
  text-transform: uppercase;
  height: 26px;
  min-width: 65px;
  padding: 0 10px;
  text-align: center;
  border-radius: 50px;
  position: absolute;
  z-index: 3;
  top: 1.23809524em;
  right: 1.23809524em;
}
.label:not([class*='bg--']) {
  background: #DE1AF5;
}
.label.label--inline {
  position: relative;
  top: 0;
  right: 0;
}
.label.label--inline:not(:last-child) {
  margin-right: 0.92857143em;
}
.label.label--inline + span,
.label.label--inline + p {
  position: relative;
  top: 1px;
}
.label.switchable-toggle {
  left: 50%;
  right: auto;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
}
.bg--primary-1 .label:not([class*='bg--']) {
  background: #53294A;
}
/*! -- Stack Bars -- */
.menu-horizontal > li,
.menu-vertical > li {
  font-family: 'Open Sans', 'Roboto', 'Helvetica', Sans-Serif;
}
.bar .logo {
  max-height: 1.85714286em;
  max-width: none;
}
.menu-horizontal > li > a,
.menu-horizontal > li > .modal-instance > a {
  font-weight: 500;
}
.menu-horizontal > li a:hover {
  text-decoration: none;
}
.hamburger-toggle i {
  color: #252525;
}
@media all and (min-width: 990px) {
  .menu-horizontal li:not(:last-child) {
    margin-right: 1.23809524em;
  }
  .bar__module:not(:only-child) .menu-horizontal {
    top: 0;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .bar-2 .menu-horizontal > li,
  .bar-1 .menu-horizontal > li {
    display: inline-block;
  }
  .bar-2 .menu-horizontal > li:not(:first-child),
  .bar-1 .menu-horizontal > li:not(:first-child) {
    margin-left: 0.92857143em;
  }
  .bar-2 .bar__module,
  .bar-1 .bar__module {
    margin-bottom: 1.85714286em;
  }
  .bar-2 .row div[class*='col-']:last-child .bar__module:last-child,
  .bar-1 .row div[class*='col-']:last-child .bar__module:last-child {
    margin-bottom: 0;
  }
}
.bar-1 .menu-horizontal > li > .dropdown__trigger,
.bar-1 .menu-horizontal > li > a {
  font-size: 0.857142857142857em;
  line-height: 2.166666666666667em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .5px;
}
@media all and (min-width: 1024px) {
  .bar-1 .bar__module + .bar__module {
    margin-left: 1.85714286em;
  }
}
@media all and (min-width: 991px) {
  .bar-2 .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.bar-3 {
  font-size: 0.85714286em;
}
.bar-3 .menu-horizontal {
  top: 0;
}
@media all and (max-width: 990px) {
  .bar-3 .menu-horizontal li {
    display: inline-block;
  }
  .bar-3 .menu-horizontal li:not(:last-child) {
    margin-right: 0.92857143em;
  }
}
@media all and (min-width: 990px) {
  .bar-toggle .col-md-1 .logo {
    top: 10px;
  }
}
@media all and (max-width: 767px) {
  .bar-4 .logo-light {
    display: none;
  }
  .bar-4 .logo-dark {
    margin-bottom: 1.85714286em;
  }
  .bar.bg--dark {
    background: #252525;
  }
  .bar.bg--dark .hamburger-toggle i {
    color: #fff;
  }
}
/*! -- Stack Utilties -- */
.hidden {
  display: none !important;
  visibility: hidden !important;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
@media all and (min-width: 768px) and (max-width: 990px) {
  .text-left-sm {
    text-align: left;
  }
  .text-right-sm {
    text-align: right;
  }
  .text-center-sm {
    text-align: center;
  }
}
@media all and (max-width: 767px) {
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
  .text-center-xs {
    text-align: center;
  }
}
/*! -- Stack Alerts -- */
.alert {
  border-radius: 6px;
}
.alert:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.alert .alert__close {
  font-size: 1.35714286em;
}
/*! -- Stack Menu Toggle -- */
.menu-toggle {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  width: 3.71428571em;
  height: 3.71428571em;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  display: inline-block;
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
}
.menu-toggle i {
  font-size: 1.78571429em;
  position: relative;
  top: 13px;
  color: #252525;
}
.menu-toggle:hover {
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  transform: translate3d(0, -2px, 0);
  -webkit-transform: translate3d(0, -2px, 0);
}
/*! -- Stack Nav Utility -- */
.nav-utility {
  padding: 0.92857143em 0;
}
.nav-utility .nav-utility__module {
  font-size: 0.85714285714286em;
}
.nav-utility .nav-utility__module a {
  color: #666666;
  font-weight: normal;
}
.nav-utility .nav-utility__module a i {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  display: inline-block;
}
.nav-utility .nav-utility__module a:hover i {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
}
.nav-utility .nav-utility__module.right:not(:last-child) {
  margin-left: 2.16666666666667em;
}
.utility-toggle {
  display: inline-block;
  cursor: pointer;
  width: 3px;
  height: 3px;
  position: relative;
  bottom: 8px;
  border-radius: 50%;
  background: #252525;
}
.utility-toggle:before,
.utility-toggle:after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  background: #252525;
}
.utility-toggle:before {
  top: 6px;
}
.utility-toggle:after {
  top: 12px;
}
/*! -- Stack Nav Stacked -- */
.bar-stacked .logo {
  margin-bottom: 3.71428571em;
}
/*! -- Stack Nav Side Menu -- */
.notification.side-menu {
  z-index: 9999;
  background: #FFFFFF;
  padding-top: 1.85714286em;
  margin: 0;
  height: 100%;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  width: 27.85714286em;
}
.notification.side-menu .side-menu__module {
  overflow: hidden;
  padding: 2.78571429em;
}
.notification.side-menu .side-menu__module + hr:not(:last-child) {
  margin: 0;
}
.notification.side-menu .menu-vertical li a {
  font-size: 1.35714286em;
  line-height: 1.36842105em;
}
.notification.side-menu .menu-vertical li a:hover {
  text-decoration: none;
}
.notification.side-menu .btn:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.notification.side-menu .btn + ul.list--loose {
  margin-top: 0.92857143em;
}
.notification.side-menu .notification-close-cross {
  top: 0.92857143em;
  right: 2.78571429em;
  margin-top: 8px;
}
.notification.side-menu .social-list:not(:first-child) {
  margin-top: 1.85714286em;
}
.menu-toggle.pos-fixed {
  position: fixed;
  right: 5.57142857em;
  animation: fadeInDown .3s ease forwards;
}
@media all and (min-width: 768px) {
  .side-menu .side-menu__module span.type--fine-print {
    position: relative;
    top: 8px;
  }
}
@media all and (max-width: 767px) {
  .side-menu {
    width: 100%;
  }
  .side-menu .side-menu__module .float-right,
  .side-menu .side-menu__module .float-left {
    display: block;
  }
}
/*! -- Stack Nav Sidebar Column -- */
.nav-container.nav-container--sidebar + .main-container {
  width: calc(100vw - 20.428571428571463em);
  float: right;
}
.nav-sidebar-column {
  position: fixed;
  z-index: 3;
  left: 0;
  width: 20.42857143em;
  border-right: 1px solid #ececec;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  padding: 2.78571429em 1.85714286em;
}
.nav-sidebar-column:not([class*='bg-']) {
  background: #FFFFFF;
}
.nav-sidebar-column.bg--dark {
  border-right: 1px solid #3f3f3f;
}
.nav-sidebar-column::-webkit-scrollbar {
  display: none;
}
.nav-sidebar-column .logo {
  max-height: 2.32142857em;
  margin-bottom: 0.92857143em;
}
.nav-sidebar-column .text-block > p {
  margin-bottom: 0.92857143em;
}
.nav-sidebar-column .menu-vertical li.dropdown:not(:hover):after {
  opacity: .35;
}
.nav-sidebar-column .menu-vertical a:hover {
  text-decoration: none;
}
.nav-sidebar-column .dropdown .dropdown__container,
.nav-sidebar-column .dropdown .dropdown__content {
  left: 0 !important;
  position: relative;
  pointer-events: all;
}
.nav-sidebar-column .dropdown .dropdown__container:before {
  height: 0;
}
.nav-sidebar-column .dropdown .dropdown__content {
  transform: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  padding-top: 0.46428571em;
  padding-right: 0;
  padding-left: 0.46428571em;
  background: none !important;
}
.nav-sidebar-column .dropdown .menu-vertical li.dropdown:after {
  right: 1.85714286em;
}
.nav-sidebar-column .dropdown:not(.dropdown--active) .dropdown__container {
  display: none;
}
.nav-sidebar-column .dropdown.dropdown--hover:hover > .dropdown__container {
  display: block;
}
.nav-sidebar-column .social-list {
  margin-bottom: 0.92857143em;
}
body.dropdowns--hover .nav-sidebar-column .dropdown:hover > .dropdown__container {
  display: block;
}
.nav-sidebar-column-toggle {
  transition: all .3s ease;
  left: 0;
  width: 3.71428571em;
  height: 3.71428571em;
  background: #FFFFFF;
  text-align: center;
  position: fixed;
  z-index: 4;
  cursor: pointer;
  border-radius: 0 0 6px 0;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
}
.nav-sidebar-column-toggle > i {
  transition: all .3s ease;
  font-size: 1.78571429em;
  position: relative;
  top: 11px;
}
.nav-sidebar-column-toggle:not(.toggled-class) > i {
  opacity: .5;
}
.nav-sidebar-column-toggle.toggled-class i:before {
  content: '\e80b';
}
@media all and (max-width: 990px) {
  .nav-sidebar-column {
    transition: all .3s ease;
    left: -20.42857143em;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0);
  }
  .nav-sidebar-column.active {
    left: 0;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .nav-sidebar-column-toggle.toggled-class {
    left: 20.42857143em;
  }
  .nav-container.nav-container--sidebar + .main-container {
    width: 100%;
    float: none;
  }
}
.nav-container.nav-container--right .nav-sidebar-column {
  right: 0;
  left: auto;
}
.nav-container.nav-container--right + .main-container {
  float: left;
}
.nav-container.nav-container--right .nav-sidebar-column-toggle {
  left: auto;
  right: 0;
  border-radius: 0 0 0 6px;
  box-shadow: -2px 1px 4px rgba(0, 0, 0, 0.1);
}
@media all and (max-width: 990px) {
  .nav-container.nav-container--right .nav-sidebar-column {
    right: -20.42857143em;
    left: auto;
  }
  .nav-container.nav-container--right .nav-sidebar-column.active {
    left: auto;
    right: 0;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .nav-container.nav-container--right .nav-sidebar-column-toggle.toggled-class {
    left: auto;
    right: 20.42857143em;
  }
}
/*! -- Stack Nav Fullscreen -- */
.menu-fullscreen {
  color: #fff;
}
.menu-fullscreen .pos-absolute {
  width: 100%;
}
.menu-fullscreen a {
  color: #fff;
}
.menu-fullscreen a:hover {
  text-decoration: none;
}
.menu-fullscreen .social-list {
  margin-right: 0;
}
.menu-fullscreen:before {
  background: rgba(0, 0, 0, 0.9);
}
.menu-fullscreen .modal-content .modal-close-cross {
  right: 3.71428571em;
  top: 1em;
}
@media all and (max-width: 767px) {
  .menu-fullscreen .pos-bottom {
    position: relative;
  }
}
/*! -- Stack Dropdowns -- */
.dropdown > .dropdown__trigger:after {
  font-family: "stack-interface";
  display: inline-block;
  font-size: 8px;
  position: relative;
  bottom: 2px;
}
.dropdown > .dropdown__trigger .image--xxs {
  margin-right: 0.46428571em;
}
.dropdown .dropdown__content {
  border-radius: 6px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
  padding: 1.23809524em 1.85714286em;
}
.dropdown .dropdown__content h5:not(:last-child) {
  margin-bottom: 0.92857143em;
}
.dropdown .dropdown__content .background-image-holder {
  border-radius: 6px 0 0 6px;
}
.dropdown .dropdown__content > .pos-absolute[class*='col-'] {
  left: 0;
  top: 0;
  height: 100%;
  position: absolute !important;
}
.dropdown .dropdown__content > .pos-absolute[class*='col-'][data-overlay]:before {
  border-radius: 6px 0 0 6px;
}
.menu-horizontal > .dropdown > .dropdown__trigger:after {
  content: '\e80a';
  margin-left: 4px;
}
.menu-vertical > .dropdown > .dropdown__trigger {
  display: inline-block;
  width: 100%;
}
@media all and (max-width: 767px) {
  .dropdown [class*='col-'] + [class*='col-'] {
    margin-top: 0.92857143em;
  }
  .dropdown .dropdown__content:not([class*='bg-']) {
    border: none;
    box-shadow: none;
  }
}
@media all and (max-width: 767px) {
  .dropdown__container {
    left: 0 !important;
  }
}
.menu-vertical li {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.menu-vertical li:not(:hover):not(.dropdown--active) {
  opacity: .75;
}
.menu-vertical li:not(:last-child) {
  margin-bottom: 0.30952381em;
}
.menu-vertical li.dropdown {
  position: relative;
}
.menu-vertical li.dropdown:after {
  content: '\e80c';
  font-family: 'stack-interface';
  right: 0;
  top: 0;
  position: absolute;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  display: inline-block;
  transform: translate3d(-10px, 0, 0);
  -webkit-transform: translate3d(-10px, 0, 0);
}
.menu-vertical li.dropdown:hover:after {
  transform: translate3d(-5px, 0, 0);
  -webkit-transform: translate3d(-5px, 0, 0);
}
.menu-vertical li.separate {
  border-top: 1px solid #e6e6e6;
  margin-top: 0.92857143em;
  padding-top: 0.92857143em;
}
.menu-vertical li.separate.dropdown:after {
  top: .92857143em;
}
.menu-vertical + h5 {
  margin-top: 1.85714286em;
}
.dropdown__container > .container {
  width: 100vw;
  max-width: 1140px;
}
@media all and (max-width: 990px) {
  .menu-vertical .dropdown.dropdown--active:after {
    opacity: 0;
  }
}
@media all and (min-width: 991px) {
  .dropdown .dropdown__container:before {
    height: 18px;
  }
}
/*! -- Stack Twitter Feeds -- */
.tweets-feed .interact {
  display: none;
}
.tweets-feed .user a:hover {
  text-decoration: none;
}
.tweets-feed .user img {
  border-radius: 50%;
}
.tweets-feed-1 {
  border-radius: 6px;
  border: 1px solid #ececec;
  overflow: hidden;
}
.tweets-feed-1 li {
  overflow: hidden;
  padding: 1.85714286em;
  padding-bottom: 0;
}
.tweets-feed-1 li:not(:last-child) {
  border-bottom: 1px solid #ececec;
}
.tweets-feed-1 .user {
  width: 20%;
  float: left;
  margin-bottom: 0.92857143em;
}
.tweets-feed-1 .user img {
  max-height: 3.71428571em;
  margin-right: 0.46428571em;
}
.tweets-feed-1 .user [data-scribe="element:name"],
.tweets-feed-1 .user [data-scribe="element:screen_name"] {
  display: none;
}
.tweets-feed-1 .tweet,
.tweets-feed-1 .timePosted {
  width: 80%;
  float: right;
}
.tweets-feed-1 .tweet {
  margin-bottom: 0.46428571em;
}
.tweets-feed-1 .timePosted {
  font-size: 0.85714286em;
  line-height: 2.16666667em;
}
.tweets-feed-1:not(:last-child) + .btn {
  margin-top: 2.78571429em;
}
.imagebg .tweets-feed-1,
.bg--dark .tweets-feed-1 {
  border-color: rgba(255, 255, 255, 0.1);
}
.imagebg .tweets-feed-1 li:not(:last-child),
.bg--dark .tweets-feed-1 li:not(:last-child) {
  border-color: rgba(255, 255, 255, 0.1);
}
.tweets-feed-2 .user {
  display: none;
}
.tweets-feed-2 .timePosted {
  display: none;
}
.tweets-feed-2 li {
  overflow: hidden;
}
.tweets-feed-2 li:before {
  font-family: 'socicon';
  content: "\e08d";
}
.tweets-feed-2 li .tweet {
  width: 85%;
  float: right;
}
.tweets-feed.slider .user img {
  display: inline-block;
  clear: both;
  margin-bottom: 0.92857143em;
}
.tweets-feed.slider .user [data-scribe="element:name"] {
  display: none;
}
.tweets-feed.slider .user [data-scribe="element:screen_name"] {
  display: block;
  font-size: 1.35714286em;
  line-height: 1.36842105em;
  font-weight: normal;
}
.tweets-feed.slider .tweet {
  margin-top: 1.36842105263158em;
  margin-bottom: 0.68421052631579em;
  font-size: 1.35714286em;
  line-height: 1.36842105em;
}
.tweets-feed.slider .timePosted {
  margin: 0;
}
.tweets-feed.slider:not(:last-child) + .btn {
  margin-top: 2.78571429em;
}
/*! -- Stack Instagram -- */
.instafeed a {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.instafeed a:hover {
  opacity: .75;
}
.instafeed:not(.instafeed--gapless) li {
  padding: 0.46428571em;
}
.instafeed.instafeed--gapless li {
  margin-bottom: -1px;
}
.instafeed + .btn {
  margin-top: 2.78571429em;
}
.instafeed[data-grid="1"][data-amount="8"] li {
  display: inline-block;
  width: 12.5%;
}
.instagram {
  position: relative;
}
.instagram.unpad .btn {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  margin: 0;
}
/*! -- Stack Cards -- */
.card__top {
  position: relative;
  overflow: hidden;
}
.card__action a {
  color: #666666;
  text-decoration: none;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.card__action a i {
  color: #252525;
  font-size: 1em;
}
.card__action a span {
  position: relative;
  bottom: 2px;
}
.card__action a:not(:hover) {
  opacity: .5;
}
.card p a {
  text-decoration: none;
}
.masonry__item .card.boxed {
  margin-bottom: 0;
}
.card-1 .card__avatar {
  margin-bottom: 0.92857143em;
}
.card-1 .card__avatar img {
  max-height: 2.78571429em;
  margin-right: 0.46428571em;
  margin-bottom: 0;
}
.card-1 .card__avatar,
.card-1 .card__meta {
  display: inline-block;
}
.card-1 .card__meta {
  float: right;
}
.card-1 .card__body {
  border-bottom: 1px solid #ececec;
  padding-bottom: 0.92857143em;
  margin-bottom: 0.92857143em;
}
.card-1 .card__body img {
  margin-bottom: 0.92857143em;
}
.card-1 .card__body h4 {
  margin-bottom: 0.342105263157895em;
}
.bg--dark .card-1 .card__body {
  border-color: #3f3f3f;
}
@media all and (max-width: 767px) {
  .card .list-inline:not(.social-list) li {
    display: inline-block;
  }
  .card .list-inline:not(.social-list) li:not(:first-child) {
    margin-left: 0.92857143em;
  }
}
.card-2 .card__top img {
  border-radius: 6px 6px 0 0;
}
.card-2 .card__body {
  border-bottom: 1px solid #ececec;
  padding-bottom: 1.85714286em;
}
.card-2 .card__body h4 {
  margin: 0;
}
.card-2 .card__body p {
  margin-top: 0.92857143em;
}
.card-2 .card__bottom {
  overflow: hidden;
}
.card-2 .card__bottom > div {
  display: inline-block;
  width: 50%;
  float: left;
}
.card-2 .card__bottom .h6 {
  margin-bottom: 0.541666666666667em;
}
.card-2 .card__body,
.card-2 .card__bottom {
  padding: 1.85714286em;
  border: 1px solid #ececec;
}
.card-2 .card__body {
  border-bottom: none;
  border-top: none;
}
.card-2 .card__bottom {
  border-radius: 0 0 6px 6px;
  padding: 0.92857143em 1.85714286em;
}
.card-2 .card__action i {
  font-size: 1.78571429em;
}
.bg--dark .card-2 .card__body,
.bg--dark .card-2 .card__bottom {
  border-color: #3f3f3f;
}
/*! -- Stack Checkmarks & Crosses -- */
.checkmark {
  display: inline-block;
  position: relative;
  width: 1.23809524em;
  height: 1.23809524em;
  border-radius: 50%;
  text-align: center;
}
.checkmark:not([class*='bg-']) {
  background: #E62E6A;
}
.checkmark:before {
  content: '\2713';
  color: #fff;
  font-size: 11px;
  position: absolute;
  width: 100%;
  left: 0;
  top: -4px;
}
.checkmark.checkmark--cross:before {
  content: '\2717';
}
.checkmark:not(:last-child) {
  margin-right: 13px;
}
/*! -- Stack Icons -- */
.icon:not(.block) {
  display: inline-block;
}
.icon.icon--xs {
  font-size: 1.14285714285714em;
}
.text-block .icon + h4 {
  margin-top: 0.6842105263158em;
}
.imagebg .icon {
  color: #fff;
}
p .material-icons {
  font-size: 1em;
}
p .material-icons:first-child {
  margin-right: 0.30952381em;
}
.material-icons {
  font-family: 'Material Icons' !important;
}
.icon-circle {
  position: relative;
  display: inline-block;
  width: 3.71428571em;
  height: 3.71428571em;
  border-radius: 50%;
  border: 1px solid #ececec;
  background: #FAFAFA;
  text-align: center;
}
.icon-circle i {
  font-size: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
}
/*! -- Stack Tooltips -- */
.tooltip {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 1.23809524em;
  height: 1.23809524em;
  border-radius: 50%;
  background: #252525;
  text-align: center;
  cursor: pointer;
}
.tooltip .tooltip__anchor {
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  position: absolute;
  top: -5px;
  left: 6px;
}
.tooltip .tooltip__text {
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  text-align: left;
  z-index: 10;
  position: absolute;
  width: 280px;
  background: #FAFAFA;
  border: 1px solid #ececec;
  padding: 0.61904762em;
  left: 1.48571429em;
  top: -0.92857143em;
  opacity: 0;
  pointer-events: none;
  cursor: default;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  line-height: 20px;
}
.tooltip:hover .tooltip__text {
  opacity: 1;
}
.tooltip:last-child {
  margin-left: 0.92857143em;
}
[data-tooltip] {
  position: relative;
  overflow: visible;
}
[data-tooltip]:after {
  transition: all .2s ease;
  content: attr(data-tooltip);
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  background: #252525;
  color: #fff;
  border: 1px solid #e7e7e7;
  padding: 2px 12px;
  pointer-events: none;
  white-space: nowrap;
  font-size: 11px;
  line-height: 18px;
  font-weight: 600;
  border-radius: 2px;
  z-index: 2;
  font-family: 'Open Sans';
}
[data-tooltip]:not(:hover):after {
  opacity: 0;
}
/*! -- Stack Boxes -- */
.boxed {
  border-radius: 6px;
}
.boxed .background-image-holder {
  border-radius: 6px;
}
.boxed:before {
  border-radius: 6px;
}
.boxed:not(:last-child) {
  margin-bottom: 30px;
}
.boxed:not([class*='bg-']) {
  background: #FFFFFF;
}
.boxed.bg--secondary .boxed {
  background: #FAFAFA;
}
@media all and (min-width: 768px) {
  .switchable .imagebg .boxed {
    padding-left: 5.57142857em;
  }
}
.bg--dark .boxed:not([class*='bg-']),
.imagebg:not(.image--light) .boxed:not([class*='bg-']) {
  background: none;
  color: #fff;
}
.bg--dark .boxed:not([class*='bg-']).boxed--border,
.imagebg:not(.image--light) .boxed:not([class*='bg-']).boxed--border {
  border-color: #3f3f3f;
}
.bg--dark .boxed[class*='bg-'].boxed--border,
.imagebg:not(.image--light) .boxed[class*='bg-'].boxed--border {
  border: none;
}
.imagebg:not(.image--light) .boxed:not(.bg--white):not(.bg--none) {
  background: rgba(20, 20, 20, 0.3);
}
.imagebg:not(.image--light) .boxed:not(.bg--white):not(.bg--none).boxed--border {
  border: none;
}
.imagebg:not(.image--light) .boxed:not(.bg--white):not(.bg--none) .boxed {
  background: none;
}
.bg--primary .boxed:not(.imagebg) {
  color: #666666;
}
.bg--primary .boxed:not(.imagebg) p {
  color: #666666;
}
.bg--primary .boxed:not(.imagebg) h1,
.bg--primary .boxed:not(.imagebg) h2,
.bg--primary .boxed:not(.imagebg) h3,
.bg--primary .boxed:not(.imagebg) h4,
.bg--primary .boxed:not(.imagebg) h5,
.bg--primary .boxed:not(.imagebg) h6,
.bg--primary .boxed:not(.imagebg) .h1,
.bg--primary .boxed:not(.imagebg) .h2,
.bg--primary .boxed:not(.imagebg) .h3,
.bg--primary .boxed:not(.imagebg) .h4,
.bg--primary .boxed:not(.imagebg) .h5,
.bg--primary .boxed:not(.imagebg) .h6,
.bg--primary .boxed:not(.imagebg) i {
  color: #252525;
}
.bg--primary .boxed:not(.imagebg) a {
  color: #E62E6A;
}
.bg--primary .boxed:not(.imagebg) .color--primary {
  color: #E62E6A !important;
}
@media all and (min-width: 768px) {
  .boxed.boxed--emphasis {
    transform: scale(1.075);
    -webkit-transform: scale(1.075);
  }
}
/*! -- Stack Features Small -- */
.feature:not(.boxed) {
  margin-bottom: 60px;
}
.feature.feature--featured:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: #E62E6A;
  left: 0;
}
.feature h5 {
  margin: 0;
}
.feature h4 {
  margin-bottom: 0.342105263157895em;
}
.feature h4 + p {
  max-width: 22.28571429em;
}
.feature i + h5 {
  margin-top: 1.85714286em;
}
.feature i + h4 {
  margin-top: 0.68421052631579em;
}
.feature i.icon--lg + h4 {
  margin-top: 1.36842105263158em;
}
.feature i.icon--lg + .h5 {
  margin-top: 0.92857143em;
}
.feature img + .boxed {
  border-radius: 0 0 6px 6px;
  border-top: none;
}
a.feature {
  display: block;
  text-decoration: none;
  font-weight: normal;
  color: #666666;
}
a.feature img:first-child:not(:last-child) {
  margin-bottom: 0.92857143em;
}
@media all and (max-width: 767px) {
  a.feature {
    margin-bottom: 0.92857143em !important;
  }
}
.imagebg:not(.image--light) .feature.bg--white a:not(.btn) {
  color: #E62E6A;
}
.imagebg:not(.image--light) .feature.bg--white .label {
  color: #fff;
}
@media all and (max-width: 767px) {
  .feature .feature__body form .row {
    margin-left: 0;
    margin-right: 0;
  }
}
section.text-center .feature-6 h4 + p {
  margin: 0 auto;
}
@media all and (min-width: 768px) {
  .row div[class*='col-']:nth-child(1):nth-last-child(3) .feature,
  .row div[class*='col-']:nth-child(2):nth-last-child(2) .feature,
  .row div[class*='col-']:nth-child(3):last-child .feature {
    margin-bottom: 0;
  }
}
a.block > .feature {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
a.block:hover > .feature {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.feature-1 {
  min-height: 11.14285714em;
}
.feature-1 p {
  margin: 0;
}
.feature-1 p.lead {
  min-height: 5.05263157894737em;
}
.feature-1 > img:first-child {
  border-radius: 6px 6px 0 0;
}
.feature-1 > a.block img {
  border-radius: 6px 6px 0 0;
}
.feature-1 > a.block + .feature__body {
  border-radius: 0 0 6px 6px;
}
.feature-1[class*='box-shadow'] {
  border-radius: 6px;
  transition: 0.35s ease-out;
  -webkit-transition: 0.35s ease-out;
  -moz-transition: 0.35s ease-out;
}
.feature-1[class*='box-shadow']:hover {
  transform: translate3d(0, -10px, 0);
  -webkit-transform: translate3d(0, -10px, 0);
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.hover-shadow {
  border-radius: 6px;
  backface-visibility: hidden;
  overflow: hidden;
  border: 1px solid #ececec;
  transition: 0.35s ease-out;
  -webkit-transition: 0.35s ease-out;
  -moz-transition: 0.35s ease-out;
}
.hover-shadow:hover {
  transform: translate3d(0, -10px, 0);
  -webkit-transform: translate3d(0, -10px, 0);
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.bg--dark .hover-shadow {
  border-color: #222;
}
.bg--dark .hover-shadow:hover {
  box-shadow: 0 23px 40px #000000;
}
.feature-2 {
  overflow: hidden;
}
.feature-2 .feature__body {
  width: 75%;
  float: right;
}
.feature-2 h5 {
  margin-bottom: 0.46428571em;
}
.feature-2 p:last-child {
  margin: 0;
}
.feature-2 i {
  width: 25%;
  float: left;
}
.feature-large .feature-2 + .feature-2:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 990px) {
  .feature-2 .feature__body,
  .feature-2 i {
    width: 100%;
    float: none;
  }
  .feature-2 p:first-of-type {
    margin-top: 0.92857143em;
  }
}
.feature-3 i {
  margin-bottom: 0.16666666666667em;
}
.feature-3 p {
  min-height: 7.42857143em;
}
.feature-4 .btn {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 0;
  padding: 0.92857143em;
}
.feature-4 .btn:hover {
  transform: none;
}
.feature-4 p {
  min-height: 9.28571429em;
}
.feature-4 p:last-of-type {
  margin-bottom: 3.71428571em;
}
.feature-5 i {
  width: 25%;
  float: left;
}
.feature-5 .feature__body {
  width: 75%;
  float: right;
}
.feature-5 p {
  min-height: 5.57142857em;
}
.feature-5 p:last-of-type {
  margin-bottom: 0.92857143em;
}
.feature-5:not([class*='bg-']) {
  color: #252525;
}
.feature-6 p {
  min-height: 9.28571429em;
}
.feature-6 p:last-child {
  margin: 0;
}
.feature-7 {
  height: 13em;
  margin-bottom: 30px;
}
.feature-7[data-overlay]:before {
  border-radius: 6px;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.feature-7[data-overlay]:hover:before {
  opacity: .75;
}
.feature-7 .background-image-holder {
  border-radius: 6px;
}
.feature-7 h3 {
  margin: 0;
}
.row--gapless .feature-7 {
  margin: 0;
  border-radius: 0;
}
.row--gapless .feature-7 .background-image-holder {
  border-radius: 0;
}
.row--gapless .feature-7:before {
  border-radius: 0;
}
@media all and (max-width: 767px) {
  .feature-7 .pos-vertical-center {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
  }
}
.feature-8 {
  padding: 2.78571429em 0 3.71428571em 0;
}
.feature-8:not(.boxed) {
  margin-bottom: 0;
}
.feature-8 .feature__body {
  max-width: 70%;
  margin: 0 auto;
}
.feature-8 p {
  min-height: 5.57142857em;
}
@media all and (max-width: 990px) {
  .feature-8 .feature__body {
    max-width: 85%;
  }
}
@media all and (max-width: 767px) {
  .feature p {
    min-height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .feature.boxed {
    margin-bottom: 15px;
  }
  .feature:not(.boxed) {
    margin-bottom: 30px;
  }
  .feature.feature-8 {
    margin-bottom: 0;
  }
}
.feature-8 img {
  max-height: 14.85714286em;
}
.feature-9 h4 {
  margin: 0;
}
.feature-9:not(.boxed) {
  margin-bottom: 30px;
}
@media all and (min-width: 768px) {
  .features-small-14 .text-block {
    margin-bottom: 3.71428571em;
  }
  .features-small-14 h4 {
    min-height: 2.736842105263158em;
  }
}
/*! -- Stack Features Large -- */
@media all and (min-width: 768px) {
  .staggered div[class*='col-']:nth-child(2):last-child {
    margin-top: 13em;
  }
  .staggered div[class*='col-']:nth-child(2):last-child:not(:last-child) {
    margin-bottom: 9.28571429em;
  }
  .staggered div[class*='col-'] .feature:not(:last-child) {
    margin-bottom: 9.28571429em;
  }
}
.feature-large h4:first-child {
  margin-bottom: 0.68421052631579em;
}
.feature-large .feature:not(.boxed) {
  margin-bottom: 30px;
}
.feature-large .feature-3.text-center p {
  margin: 0 auto;
}
.feature-large .lead + .feature-large__group {
  margin-top: 3.71428571em;
}
.feature-large .feature-large__group {
  overflow: hidden;
}
.feature-large-1 .lead {
  margin-bottom: 2.73684210526316em;
}
@media all and (min-width: 768px) {
  .feature-large-2 div[class*='col-']:first-child {
    margin-top: 5.57142857em;
  }
  .feature-large-2 div[class*='col-']:last-child {
    margin-top: 7.42857143em;
  }
}
@media all and (max-width: 767px) {
  .feature-large-2 img {
    margin: 1.85714286em 0;
  }
}
.feature-large-7.switchable .boxed div[class*='col-']:only-child {
  float: none;
}
.feature-large-13 p.lead + .text-block {
  margin-top: 3.71428571em;
}
.feature-large-13 div[class*='col-'] .text-block + .text-block {
  margin-top: 2.78571429em;
}
@media all and (min-width: 1200px) {
  .feature-large-13:not(.text-center) .text-block p {
    max-width: 26em;
  }
}
.feature-large-14 h5 {
  margin-bottom: 0.46428571em;
}
.feature-large-14 .boxed {
  margin-bottom: 0;
}
.feature-large-14 .boxed.boxed--border img {
  border-radius: 6px;
}
@media all and (min-width: 768px) {
  .feature-large-14.switchable .switchable__text {
    margin-top: 5.57142857em;
  }
}
/*! -- Stack Accordions -- */
.accordion .accordion__title {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  opacity: .5;
  margin-bottom: 0.92857143em;
}
.accordion .accordion__title:hover {
  opacity: 1;
}
.accordion .accordion__title:before {
  font-family: "stack-interface";
  content: '\e80d';
  margin-right: 6px;
  color: #252525;
}
.accordion .accordion__title span {
  display: inline-block;
}
.accordion li.active {
  margin-bottom: 0.92857143em;
}
.accordion li.active .accordion__title {
  opacity: 1;
}
.accordion li.active .accordion__title:before {
  content: '\e80a';
}
.accordion li.active .accordion__content {
  animation: fadeUp .5s ease forwards;
  -webkit-animation: fadeUp .5s ease forwards;
}
.accordion-1 li.active .accordion__title {
  border-color: #E62E6A;
}
.accordion-1 li.active .accordion__title span {
  color: #fff;
}
.accordion-1 li.active .accordion__title:before {
  color: #fff;
}
.accordion-1 li .accordion__title {
  border-radius: 6px;
  border-bottom: 1px solid #ECECEC;
}
.accordion-2 li .accordion__title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #ECECEC;
  padding-left: 0;
}
.accordion-2 li.active .accordion__title {
  background: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.bg--dark .accordion-2 li .accordion__title,
.imagebg:not(.image--light) .accordion-2 li .accordion__title,
.bg--primary .accordion-2 li .accordion__title,
.bg--dark .accordion-1 li .accordion__title,
.imagebg:not(.image--light) .accordion-1 li .accordion__title,
.bg--primary .accordion-1 li .accordion__title {
  border-color: #4b4b4b;
}
.bg--dark .accordion-2 li .accordion__title:before,
.imagebg:not(.image--light) .accordion-2 li .accordion__title:before,
.bg--primary .accordion-2 li .accordion__title:before,
.bg--dark .accordion-1 li .accordion__title:before,
.imagebg:not(.image--light) .accordion-1 li .accordion__title:before,
.bg--primary .accordion-1 li .accordion__title:before {
  color: #fff;
}
.imagebg:not(.image--light) .accordion li .accordion__title,
.bg--primary .accordion li .accordion__title {
  border-color: rgba(255, 255, 255, 0.3);
}
/*! -- Stack Tabs -- */
.tabs-container.tabs--vertical {
  overflow: hidden;
}
.tabs-container.tabs--vertical .tabs {
  width: 25%;
  float: left;
}
.tabs-container.tabs--vertical .tabs li {
  display: block;
}
.tabs-container.tabs--vertical .tabs li:not(:last-child) {
  border-right: none;
  border-bottom: 1px solid #ECECEC;
}
.tabs-container.tabs--vertical .tabs-content {
  width: 70%;
  float: right;
  margin-top: 0;
}
.tabs-container.tabs--vertical .tabs-content > li:not(.active) .tab__content {
  animation: fadeOutUp .5s ease forwards;
  -webkit-animation: fadeOutUp .5s ease forwards;
}
.tabs-container.tabs--vertical .tabs-content > li.active .tab__content {
  animation: fadeInUp .5s ease forwards;
  -webkit-animation: fadeInUp .5s ease forwards;
  backface-visibility: hidden;
}
.tabs-container.tabs--vertical.tabs--icons .tabs {
  width: 15%;
  text-align: center;
}
.tabs-container.tabs--vertical.tabs--icons .tabs-content {
  width: 80%;
}
@media all and (max-width: 767px) {
  .tabs-container.tabs--vertical .tabs,
  .tabs-container.tabs--vertical .tabs-content {
    width: 100%;
    float: none;
  }
  .tabs-container.tabs--vertical .tabs {
    margin-bottom: 1.85714286em;
  }
  .tabs-container.tabs--vertical .tabs-content > li:not(.active) .tab__content {
    animation: fadeOutRight .5s ease forwards;
    -webkit-animation: fadeOutRight .5s ease forwards;
  }
  .tabs-container.tabs--vertical .tabs-content > li.active .tab__content {
    animation: fadeInLeft .5s ease forwards;
    -webkit-animation: fadeInLeft .5s ease forwards;
    backface-visibility: hidden;
  }
}
.tabs {
  display: inline-block;
  border: 1px solid #ECECEC;
  border-radius: 6px;
  margin-bottom: 1.85714286em;
}
.tabs li {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  padding: 0.92857143em 1.85714286em;
}
.tabs li:not(:last-child) {
  border-right: 1px solid #ECECEC;
}
.tabs li.active {
  opacity: 1;
}
.tabs li.active .tab__title {
  color: #E62E6A;
}
.tabs li.active .tab__title span {
  color: #E62E6A;
}
.tabs li:not(.active) {
  opacity: .5;
}
.tabs li:hover {
  opacity: 1;
  cursor: pointer;
}
.tabs .tab__title {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.tabs .tab__title:not(.btn) {
  padding-right: 0;
  padding-left: 0;
}
.tabs .tab__title i + span {
  margin-top: 0.46428571em;
  display: inline-block;
  margin-bottom: 0;
}
.bg--primary .tabs {
  border-color: #fff;
}
.bg--primary .tabs li.active .tab__title {
  color: #fff;
}
.bg--primary .tabs li.active .tab__title span {
  color: #fff;
}
.bg--primary .tabs li:not(:last-child) {
  border-color: #fff;
}
@media all and (min-width: 768px) {
  .tabs.tabs--spaced {
    margin-bottom: 3.71428571em;
  }
}
.tabs-content {
  position: relative;
}
.tabs-content > li:not(.active) .tab__content {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  opacity: 0;
  pointer-events: none;
  animation: fadeOutRight .5s ease forwards;
  -webkit-animation: fadeOutRight .5s ease forwards;
}
.tabs-content > li.active {
  animation: fadeInLeft .5s ease forwards;
  -webkit-animation: fadeInLeft .5s ease forwards;
  backface-visibility: hidden;
}
@media all and (max-width: 767px) {
  .tabs li {
    display: block;
  }
  .tabs li:not(:last-child) {
    border-bottom: 1px solid #ECECEC;
    border-right: none;
  }
}
.tabs--folder .tabs {
  overflow: hidden;
  margin-bottom: 0;
  border-radius: 6px 6px 0 0;
  border-bottom: none;
}
.tabs--folder .tabs > li {
  border-top: 3px solid rgba(0, 0, 0, 0);
}
.tabs--folder .tabs > li.active {
  border-top: 3px solid #E62E6A;
}
.tabs--folder .tabs-content {
  overflow: hidden;
  position: relative;
  bottom: 8px;
  padding: 1.85714286em;
  border: 1px solid #ECECEC;
  border-radius: 0 0 6px 6px;
}
.tabs--folder .tabs-content > li:not(.active) .tab__content {
  top: 1.85714286em;
  left: 1.85714286em;
}
.tabs--folder .tab__title .h5 {
  margin: 0;
  display: inline-block;
}
.tabs--folder .tab__title .label {
  position: relative;
  color: #fff !important;
  top: -2px;
  right: 0;
  margin-left: 0.46428571em;
  min-width: 0;
}
/*! -- Stack Switchable Sections -- */
@media all and (min-width: 768px) {
  .switchable .container > .row {
    flex-flow: row-reverse;
  }
  .switchable.switchable--switch .container > .row {
    flex-flow: row;
  }
}
.switchable .switchable__text {
  margin-top: 1.85714286em;
}
.switchable [class*='col-']:first-child .switchable__text {
  margin-bottom: 2.78571429em;
}
.section--overlap .row {
  position: relative;
  margin-top: -13em;
}
@media all and (max-width: 990px) {
  .switchable .switchable__text {
    margin-top: 0;
  }
}
@media all and (max-width: 767px) {
  .switchable .switchable__text {
    margin-top: 1.85714286em;
  }
  .switchable .imageblock__content[class*='col-'] {
    float: none !important;
  }
  .switchable .row > div[class*='col-'] {
    float: none !important;
  }
  .switchable .row > div[class*='col-']:last-child:not(:only-child) {
    margin-top: 1.85714286em;
  }
}
/*! -- Stack CTA -- */
.cta .btn:first-child:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.cta.boxed:last-child {
  margin-bottom: 0;
}
@media all and (min-width: 768px) {
  .cta--horizontal h4,
  .cta--horizontal .lead,
  .cta--horizontal .btn {
    margin: 0;
  }
  .cta--horizontal h4,
  .cta--horizontal .lead {
    position: relative;
    top: 4px;
  }
  .bg--dark .cta-1 > div[class*='col-']:last-child,
  .imagebg .cta-1 > div[class*='col-']:last-child {
    border-left: none;
  }
}
@media all and (min-width: 1200px) {
  .cta-1.boxed--border {
    padding: 0;
  }
  .cta-1 > div[class*='col-'] {
    padding: 1.23809524em 15px;
  }
  .cta-1 > div[class*='col-']:last-child {
    border-left: 1px solid #ECECEC;
  }
}
@media all and (max-width: 990px) {
  .cta-1 {
    text-align: center;
  }
  .cta-1 > div[class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
  .cta-1 > div[class*='col-']:not(:last-child) {
    margin-bottom: 0.92857143em;
  }
  .cta-1 > div[class*='col-']:last-child {
    margin-top: 1.85714286em;
  }
}
@media all and (max-width: 767px) {
  .cta .lead {
    margin: 1.36842105263158em 0;
  }
}
.cta-2 {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.cta-2 h2 {
  margin: 1.57575757575758em 0;
}
.cta-2.bg--primary:hover {
  background: #e83c74;
}
.cta-2:hover {
  background: #ffffff;
}
@media all and (min-width: 768px) {
  .cta-3 .switchable__text {
    margin-top: 5.57142857em;
  }
}
/*! -- Stack Videos -- */
.video-play-icon:before {
  border-color: transparent transparent transparent #252525;
  margin-top: -3px;
}
.modal-instance .video-play-icon + span {
  position: relative;
  display: inline-block;
  margin-left: 0.92857143em;
  white-space: nowrap;
  bottom: 22px;
}
.modal-instance .video-play-icon.video-play-icon--xs + span {
  bottom: 8px;
  margin-left: 0.46428571em;
}
.video-cover {
  background: #252525;
}
.video-cover.border--round {
  overflow: hidden;
}
.video-cover.border--round iframe {
  border-radius: 6px;
}
.video-cover:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.video-cover + span.h4 + span {
  display: inline-block;
  margin-left: 0.92857143em;
}
@media all and (min-width: 1025px) {
  .video-cover .video-play-icon {
    transition: 0.2s ease-out;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
  }
  .video-cover .video-play-icon:hover {
    box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
    transform: translate3d(-50%, -52%, 0);
    -webkit-transform: translate3d(-50%, -52%, 0);
  }
}
.imagebg .video-cover .video-play-icon {
  z-index: 10;
}
@media all and (min-width: 768px) {
  h2 + .video-cover {
    margin-top: 3.71428571em;
  }
}
@media all and (min-width: 768px) {
  .video-1 .video-play-icon {
    margin-right: 1.85714286em;
  }
  .video-1 h2 {
    display: inline-block;
    position: relative;
    bottom: 14px;
  }
  div[class*='col-sm-6'] .video-1 h2 {
    bottom: 0;
    display: block;
  }
  div[class*='col-sm-6'] .video-1 .video-play-icon {
    margin-bottom: 0.46428571em;
  }
}
.video-1 h2 {
  margin: 0;
}
.imageblock__content .modal-instance {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
}
/*! -- Stack Modals -- */
.modal-container .modal-content:not(.height--natural) {
  height: auto;
  width: auto;
}
.modal-instance:not(.block) {
  display: inline-block;
}
.modal-instance.block > .btn {
  display: block;
}
@media all and (min-width: 768px) {
  .modal-instance + span:last-child {
    display: inline-block;
    margin-left: 0.92857143em;
  }
}
.modal-container .modal-content {
  max-height: 95%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
}
.modal-container .modal-content .feature:only-child {
  margin-bottom: 0;
}
.modal-container .modal-content .feature:only-child .boxed {
  margin-bottom: 0;
}
.modal-container .modal-content .imagebg:not(.image--light) .modal-close-cross:before {
  color: #fff;
}
.modal-container .modal-content .container {
  width: 100vw;
}
@media all and (max-width: 767px) {
  .modal-container .modal-content > .modal-close-cross {
    top: 3em;
    background: #222;
    width: 2em;
    height: 2em;
    text-align: center;
    border-radius: 50%;
    color: #fff;
  }
  .modal-container:not(.modal-active) .modal-content {
    display: none;
  }
}
.modal-content {
  -ms-overflow-style: none;
}
.modal-content::-webkit-scrollbar {
  display: none;
}
/*! -- Stack Notifications -- */
.notification {
  max-width: inherit;
  margin: 1.85714286em;
  box-shadow: none;
}
.notification:not([class*='bg--']) {
  background: none;
}
.notification > .boxed {
  margin: 0;
}
.notification > .feature,
.notification .feature__body {
  margin-bottom: 0;
}
.search-box {
  width: 100%;
  margin: 0;
  padding: 1.85714286em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.search-box.notification--reveal {
  z-index: 9999;
}
.search-box .notification-close-cross {
  top: 2.32142857em;
  right: 1.85714286em;
}
.notification-close-cross.notification-close-cross--circle {
  background: #222;
  width: 2em;
  height: 2em;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}
/*! -- Stack Loader -- */
.loader {
  border: 3px solid #E62E6A;
  border-radius: 30px;
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  opacity: 0;
  position: fixed;
  z-index: 10;
  top: 50%;
  width: 30px;
  animation: pulsate 0.7s ease-out infinite;
}
.loader.loader--fade {
  opacity: 0;
  animation: none;
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
/*! -- Stack Lightboxes -- */
.lb-close {
  position: relative;
  top: 10px;
}
.lb-close:before {
  content: '\00D7';
  color: #fff;
  font-size: 2.5em;
  font-weight: 400;
}
.lb-close:hover {
  text-decoration: none;
}
.lb-prev:after,
.lb-next:after {
  font-family: 'stack-interface';
  position: fixed;
}
.lb-prev:after {
  content: '\e801';
  left: 1.85714286em;
}
.lb-next:after {
  content: '\e802';
  right: 1.85714286em;
}
.lb-data {
  overflow: hidden;
  min-height: 30px;
}
a[data-lightbox] {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
a[data-lightbox]:hover {
  opacity: .8;
  cursor: zoom-in;
}
/*! -- Stack Covers -- */
.cover .typed-headline {
  margin-bottom: 3.71428571em;
}
.cover .typed-headline .type--bold {
  font-weight: 400;
}
.cover .triptych {
  margin-top: 3.71428571em;
}
.cover .btn + .modal-instance {
  margin-top: 1.85714286em;
}
.cover h3 + form,
.cover .h3 + form {
  margin-top: 2.78571429em;
}
.cover .lead + .boxed {
  margin-top: 3.71428571em;
}
.cover.unpad--bottom {
  overflow: hidden;
}
.cover .modal-instance.block:not(:first-child):not(:last-child) {
  margin: 2.78571429em 0;
}
.cover .pos-bottom {
  margin-bottom: 1.85714286em;
}
@media all and (min-width: 768px) {
  .cover .row .row:last-child {
    margin-top: 5.57142857em;
  }
}
@media all and (max-width: 767px) {
  .cover .pos-bottom.pos-absolute {
    position: relative;
    margin-top: 1.85714286em;
  }
}
@media all and (max-width: 990px) {
  .cover .mt--3 {
    margin-top: 0;
  }
}
@media all and (min-width: 768px) {
  .cover .triptych {
    margin-top: 7.42857143em;
  }
}
.cover-fullscreen {
  min-height: 100vh;
}
.cover-fullscreen.slider[data-paging="true"] .slides .flickity-slider > li {
  height: 100vh;
}
.cover-fullscreen.slider[data-paging="true"] .pos-bottom {
  bottom: 5.57142857em;
  width: 100%;
}
.cover-fullscreen.slider[data-paging="true"] h4 {
  margin: 0;
}
.cover-slider {
  overflow: hidden;
}
.cover-slider .slider {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.cover-slider .slider:nth-child(1) {
  transform: translateY(-30%);
}
.cover-slider .slider:nth-child(2) {
  transform: translateY(-25%);
}
.cover-slider .slider:nth-child(3) {
  transform: translateY(-20%);
}
.cover-slider .slider .slide {
  opacity: .05;
}
.cover-slider .typed-headline {
  margin: 0;
}
.cover-slider .typed-headline + .btn {
  margin-top: 0.92857143em;
}
.cover-slider .slider-container {
  transform: rotateZ(-30deg) translateY(-10%);
  -webkit-transform: rotateZ(-30deg) translateY(-10%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
@media all and (min-width: 768px) {
  .cover-slider + .cta-4 {
    position: absolute;
    background: none;
    color: #fff;
    border-bottom: none !important;
    width: 100%;
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
  }
  .cover-slider + .cta-4 a {
    color: #fff;
    font-weight: 600;
  }
}
.cover-features.space--lg {
  padding-bottom: 3.71428571em;
}
.cover-features .row:nth-child(2) {
  margin-top: 11.14285714em;
}
@media all and (max-width: 767px) {
  .cover-features.space--lg {
    padding-bottom: 1.85714286em;
  }
  .cover-features .row:nth-child(2) {
    margin-top: 3.71428571em;
  }
}
/*! -- Stack Processes -- */
.process-1 {
  padding: 3.71428571em 0;
  position: relative;
  overflow: hidden;
}
.process-1 .process__item {
  width: 50%;
}
.process-1 .process__item:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.process-1:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  left: 50%;
  top: 0;
  background: #E62E6A;
}
.process__item {
  position: relative;
}
.process__item:after {
  content: '';
  width: 1.85714286em;
  height: 1.85714286em;
  background: #fff;
  border: 2px solid #E62E6A;
  border-radius: 50%;
  position: absolute;
  top: 0;
  margin-left: 1px;
}
@media all and (min-width: 768px) {
  .process-1 .process__item:nth-child(odd) {
    text-align: right;
    padding-right: 3.71428571em;
  }
  .process-1 .process__item:nth-child(odd):after {
    right: -0.92857143em;
  }
  .process-1 .process__item:nth-child(even) {
    margin-left: 50%;
    padding-left: 3.71428571em;
  }
  .process-1 .process__item:nth-child(even):after {
    left: -0.92857143em;
  }
}
@media all and (max-width: 767px) {
  .process-1:before,
  .process-2:before {
    left: 0.92857143em;
  }
  .process-1 .process__item,
  .process-2 .process__item {
    width: 100%;
    padding-left: 3.71428571em;
  }
  .process-1 .process__item:after,
  .process-2 .process__item:after {
    left: 0;
  }
}
.bg--dark .process-1:before,
.imagebg .process-1:before,
.bg--dark .process-2:before,
.imagebg .process-2:before {
  background: #fff;
}
.bg--dark .process-1 .process__item:after,
.imagebg .process-1 .process__item:after,
.bg--dark .process-2 .process__item:after,
.imagebg .process-2 .process__item:after {
  background: #fff;
}
.bg--dark .process-1 .process__item:after,
.bg--dark .process-2 .process__item:after {
  border-color: #252525;
}
.process-2 {
  position: relative;
}
.process-2 .process__item {
  padding-top: 3.71428571em;
}
.process-2 .process__item:after {
  display: inline-block;
  text-align: center;
}
.process-2:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  top: 0.92857143em;
  left: 0;
  background: #E62E6A;
}
@media all and (max-width: 767px) {
  .process-2:before {
    height: 100%;
    width: 2px;
    left: 1.85714286em;
    margin-left: 2px;
  }
  .process-2 .process__item:after {
    top: 3.71428571em;
  }
}
.process-3 {
  list-style: none;
  position: relative;
}
.process-3 li {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.process-3 li .process__number {
  float: left;
  width: 15%;
  width: 3.71428571em;
  height: 3.71428571em;
  border-radius: 50%;
  border: 2px solid #E62E6A;
  background: #fff;
  text-align: center;
}
.process-3 li .process__number span {
  font-size: 1.35714286em;
  font-weight: bold;
  color: #E62E6A;
  position: relative;
  top: 12px;
}
.process-3 li .process__body {
  width: 80%;
  float: right;
}
.process-3 li .process__body h4 {
  margin-bottom: 0.342105263157895em;
}
.process-3 li:not(:last-child) {
  margin-bottom: 3.71428571em;
}
.process-3:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  left: 1.85714286em;
  top: 0;
  border-left: 1px dashed #ddd;
}
.bg--dark .process-3 li .process__number {
  background: #252525;
}
.bg--dark .process-3:before {
  border-color: #aaa;
}
.process-stepper > li {
  transition: all .3s ease;
  display: inline-block;
  float: left;
  text-align: center;
  position: relative;
}
.process-stepper > li:before {
  transition: all .3s ease;
  content: '';
  width: 0.92857143em;
  height: 0.92857143em;
  background: #ccc;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.92857143em;
}
.process-stepper > li:after {
  transition: all .3s ease;
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  background: #ccc;
  top: 5px;
  left: 0;
}
.process-stepper > li:first-child:after,
.process-stepper > li:last-child:after {
  width: 50%;
}
.process-stepper > li:first-child:after {
  left: 50%;
}
.process-stepper > li.active:before,
.process-stepper > li.active:after {
  background: #E62E6A;
}
.process-stepper > li > span {
  cursor: default;
}
.process-stepper[data-process-steps="6"] > li {
  width: 16.666666666666667%;
}
.process-stepper[data-process-steps="5"] > li {
  width: 20%;
}
.process-stepper[data-process-steps="4"] > li {
  width: 25%;
}
.process-stepper[data-process-steps="3"] > li {
  width: 33.33333333%;
}
.process-stepper[data-process-steps="2"] > li {
  width: 50%;
}
.bg--dark .process-stepper li:not(.active):before,
.bg--dark .process-stepper li:not(.active):after {
  background: #585858;
}
/*! -- Stack Typed Text -- */
span + .typed-text.h2 {
  margin-left: 4px;
}
span + .typed-text.h1 {
  margin-left: 6px;
}
@media all and (max-width: 767px) {
  .typed-text {
    display: block;
  }
  .typed-headline {
    margin-bottom: 1.85714286em;
  }
  .typed-headline h1,
  .typed-headline .h1 {
    margin-bottom: 0;
  }
}
/*! -- Stack Forms -- */
form:before,
form:after {
  content: '';
}
.bg--dark input,
.imagebg:not(.image--light) input,
.bg--dark textarea,
.imagebg:not(.image--light) textarea,
.bg--dark select,
.imagebg:not(.image--light) select,
.bg--dark .input-select:after,
.imagebg:not(.image--light) .input-select:after {
  color: #666666;
}
.bg--dark label,
.imagebg:not(.image--light) label {
  color: #fff;
}
.bg--dark form.attempted-submit input.field-error {
  background: #D84D4D;
  color: #fff;
}
form {
  position: relative;
}
form > div[class*='col-']:not(:last-child),
form > .row > div[class*='col-']:not(:last-child) {
  margin-bottom: 0.92857143em;
}
form .boxed:last-child {
  margin: 0;
}
form.form--clearfix {
  margin-left: -15px;
}
@media all and (min-width: 768px) {
  div[class*='col-'] > form div[class*='col-']:last-child:nth-child(2) {
    padding-right: 0;
  }
  div[class*='col-'] > form div[class*='col-']:first-child:nth-last-child(2) {
    padding-left: 0;
  }
  form.form--horizontal > div[class*='col-'] {
    margin: 0;
  }
}
@media all and (max-width: 767px) {
  .row form > .row {
    margin-left: 0;
    margin-right: 0;
  }
}
h2 + form,
.h2 + form {
  margin-top: 2.78571429em;
}
h3 + form,
.h3 + form,
.lead + form {
  margin-top: 2.78571429em;
}
.cover .lead + form {
  margin-top: 3.71428571em;
}
form + span.type--fine-print {
  margin-top: 1.08333333333334em;
  display: inline-block;
}
.form--inline > span.h4 {
  color: #666666;
}
.form--inline input,
.form--inline [class*='input-'] {
  display: inline-block;
  max-width: 200px;
}
.form--inline > span:not(:last-child),
.form--inline input:not(:last-child),
.form--inline [class*='input-']:not(:last-child) {
  margin-right: 0.92857143em;
}
.form--inline button {
  max-width: 200px;
}
.bg--dark .form--inline > span.h4,
.imagebg:not(.image--light) .form--inline > span.h4 {
  color: #fff;
}
button,
input[type="submit"] {
  height: 3.25000000000001em;
}
button.btn,
input[type="submit"].btn {
  font-size: 0.85714286em;
  font-weight: 700;
  padding-left: 0;
  padding-right: 0;
}
button.btn.btn--primary,
input[type="submit"].btn.btn--primary {
  color: #fff;
}
button.btn.type--uppercase,
input[type="submit"].btn.type--uppercase {
  letter-spacing: .5px;
  margin-right: -0.5px;
}
button.checkmark.checkmark--cross,
input[type="submit"].checkmark.checkmark--cross {
  width: 1.85714286em;
  height: 1.85714286em;
  border: none;
  background: #E23636;
}
button.checkmark.checkmark--cross:before,
input[type="submit"].checkmark.checkmark--cross:before {
  content: '\00d7';
  font-size: 18px;
  top: -1px;
}
button[type="submit"].btn--loading:after,
input[type="submit"][type="submit"].btn--loading:after {
  background: #E62E6A;
}
.bg--primary button.btn {
  border-color: rgba(255, 255, 255, 0.5);
  color: #fff;
}
.bg--primary button.btn:hover {
  border-color: #fff;
}
.bg--primary button.btn.btn--primary {
  color: #E62E6A;
  border-color: #E62E6A;
}
.bg--primary button.btn.btn--primary-1 {
  border-color: #DE1AF5;
}
input,
select {
  height: 2.78571429em;
}
input.field-error {
  border-color: #EBA8A8;
}
input[type] + input[type],
input[type] + .input-checkbox,
input[type] + button,
input[type] + .input-select {
  margin-top: 0.92857143em;
}
input,
[class*='input-'] label,
select,
textarea {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
input:not([class*='col-']),
select:not([class*='col-']),
.input-select:not([class*='col-']),
textarea:not([class*='col-']),
button[type="submit"]:not([class*='col-']) {
  width: 100%;
}
input[type],
select,
textarea {
  padding-left: 0.92857143em;
}
input[type]:focus,
select:focus,
textarea:focus {
  border-color: #eb5c8a;
}
input[type="image"] {
  border: none;
  padding: none;
  width: auto;
}
label {
  font-size: .85714286em;
}
.bg--primary label,
.bg--primary-1 label {
  color: #fff;
}
.input-icon i {
  top: 8px;
  left: 8px;
}
.input-icon input {
  padding-left: 2.78571429em;
}
.input-icon.input-icon--right i {
  left: auto;
  right: 8px;
}
.input-icon.input-icon--right input {
  padding-right: 2.78571429em;
  padding-left: .92857143em;
}
.text-center .input-checkbox label,
.text-center .input-radio label {
  margin: 0 auto;
}
.input-checkbox label,
.input-radio label {
  font-size: 1em;
}
.input-checkbox {
  margin-top: 0.46428571em;
}
.input-checkbox.field-error label {
  border-color: #EBA8A8;
}
.input-checkbox label {
  background: none;
  border: 1px solid #d3d3d3;
  text-align: center;
  position: relative;
}
.input-checkbox label:not(:last-child) {
  margin-right: 0.46428571em;
}
.input-checkbox label:hover {
  border-color: #E62E6A;
}
.input-checkbox label:before {
  content: '';
  left: 0;
  border-radius: 6px;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #E62E6A;
  opacity: 0;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.input-checkbox input:checked + label {
  border-color: #E62E6A;
  background: #E62E6A;
}
.input-checkbox input:checked + label:after {
  content: 'L';
  transform: rotateY(180deg) rotateZ(-45deg);
  color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  top: -2px;
  font-weight: 700;
}
.input-checkbox input:checked + label:before {
  animation: pulse .45s ease forwards;
  -webkit-animation: pulse .45s ease forwards;
}
.input-checkbox + span {
  display: inline-block;
  position: relative;
  bottom: 10px;
  margin-left: 0.92857143em;
  font-size: 0.85714286em;
  white-space: nowrap;
}
.input-checkbox + button[type] {
  margin-top: 0.92857143em;
}
.input-checkbox + span + button[type] {
  margin-top: 0.92857143em;
}
.bg--dark .input-checkbox:not(.checked) label {
  border-color: #a5a5a5;
}
.bg--dark .input-checkbox + span {
  opacity: .75;
}
.input-checkbox.input-checkbox--switch label {
  width: 3.71428571em;
  border-radius: 60px;
}
.input-checkbox.input-checkbox--switch label:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-color: #d3d3d3;
  opacity: 1;
  left: 2px;
  top: 2px;
}
.input-checkbox.input-checkbox--switch label:hover:before {
  border-color: #E62E6A;
}
.input-checkbox.input-checkbox--switch input:checked + label {
  background: none;
}
.input-checkbox.input-checkbox--switch input:checked + label:before {
  animation: none !important;
  background: #E62E6A;
  border-color: #E62E6A;
  transform: translateX(1.85714286em);
}
.input-checkbox.input-checkbox--switch input:checked + label:after {
  display: none;
}
.input-radio.field-error label {
  border-color: #EBA8A8;
}
.input-radio .input__label {
  font-size: .85714286em;
  display: inline-block;
}
.input-radio .input__label + label {
  margin-top: 0.92857143em;
}
.input-radio label {
  position: relative;
  background: none;
  border: 1px solid #d3d3d3;
}
.input-radio label:hover {
  border-color: #E62E6A;
}
.input-radio label:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #E62E6A;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  opacity: 0;
  left: 0;
}
.input-radio input:checked + label {
  border-color: #E62E6A;
  background-color: #E62E6A;
}
.input-radio input:checked + label:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  left: 7px;
  top: 7px;
  background-color: #fff;
  border-radius: 50%;
}
.input-radio input:checked + label:before {
  animation: pulse .4s ease forwards;
}
.input-radio--innerlabel label {
  transition: all .3s ease;
  height: 2.78571429em;
  line-height: 2.50714286em;
  padding: 0 0.92857143em;
  border: 1px solid #ececec;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.input-radio--innerlabel:hover {
  border-color: #E62E6A;
}
.input-radio--innerlabel input:checked + label {
  border-color: #E62E6A;
  background: #E62E6A;
  color: #fff;
}
.input-radio--innerlabel input:checked + label:after {
  display: none;
}
.input-radio--innerlabel input:checked + label:before {
  animation: none;
}
@media all and (max-width: 1024px) {
  .input-radio input:checked + label:after {
    top: 6px;
    left: 6px;
  }
}
.bg--primary .input-radio--innerlabel {
  border-color: #eb5c8a;
}
.bg--primary .input-radio--innerlabel:hover {
  border-color: #fff;
}
.bg--primary .input-radio--innerlabel.checked {
  background: #fff;
  border-color: #fff;
}
.bg--primary .input-radio--innerlabel.checked label {
  color: #E62E6A;
}
.bg--primary-1 .input-radio--innerlabel {
  border-color: #e54bf7;
}
.bg--primary-1 .input-radio--innerlabel:hover {
  border-color: #fff;
}
.bg--primary-1 .input-radio--innerlabel.checked {
  background: #fff;
  border-color: #fff;
}
.bg--primary-1 .input-radio--innerlabel.checked label {
  color: #DE1AF5;
}
.input-select {
  position: relative;
}
.input-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
}
.input-select select::-ms-expand {
  display: none;
}
.input-select:not(:last-child) {
  margin-bottom: 0.92857143em;
}
.input-select:after {
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  font-size: 30px;
  content: '\2263';
  pointer-events: none;
  padding: 0 13px;
  border-left: 1px solid #ececec;
  line-height: 31px;
}
.input-select.input-select--borderless:after {
  border-left: none;
}
.input-select:focus:after {
  border-color: #E62E6A;
}
.input-select select:focus {
  border-color: #E62E6A;
}
.input-number {
  position: relative;
}
.input-number > input[type="number"] {
  padding-left: .46428571em;
  -moz-appearance: textfield;
  width: 100%;
  text-align: center;
}
.input-number > input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
.input-number .input-number__controls {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  padding: 0 0.92857143em;
}
.input-number .input-number__controls > span {
  position: absolute;
  display: block;
  width: 10%;
  min-width: 3.71428571em;
  height: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  padding-top: 6px;
  transition: all .3s ease;
}
.input-number .input-number__controls > span:hover {
  color: #E62E6A;
}
.input-number .input-number__controls .input-number__increase {
  right: 0;
  border-left: 1px solid #ececec;
}
.input-number .input-number__controls .input-number__decrease {
  left: 0;
  border-right: 1px solid #ececec;
}
input[type="image"] {
  padding: 0;
  width: auto;
  border: none;
}
/*! -- Stack Subscribe Forms -- */
.subscribe-form-1 h3 {
  margin-bottom: 0.52em;
}
.subscribe-form-1.boxed form {
  margin: 1.85714286em;
}
@media all and (min-width: 991px) {
  .subscribe-form-2 p {
    top: 2px;
    position: relative;
  }
  .subscribe-form-2 input {
    margin: 0;
  }
  .subscribe-form-2 form [class*='col-'] {
    margin: 0 !important;
  }
}
@media all and (max-width: 990px) {
  .subscribe-form-2 {
    text-align: center;
  }
  .subscribe-form-2 form {
    margin-top: 1.85714286em;
  }
}
/*! -- Stack Elaborate Forms -- */
@media all and (max-width: 767px) {
  .elaborate-form-1 .pos-vertical-center {
    padding-left: 15px;
    padding-right: 15px;
  }
  .elaborate-form-1 form .col-xs-6:nth-child(even) {
    padding-left: 15px;
  }
  .elaborate-form-1 form .col-xs-6:nth-child(odd) {
    padding-right: 15px;
  }
  .elaborate-form-1 .row form > .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
/* Stack Boxed Forms */
.boxed-form h4 {
  margin-bottom: 0.684210526315789em;
}
.boxed-form:last-child {
  margin-bottom: 0;
}
/* Stack Datepicker Form */
.datepicker {
  cursor: pointer;
}
.datepicker.datepicker--fluid + .picker {
  max-width: none;
}
.picker {
  transition: all .3s ease;
  z-index: 10;
  max-width: 100%;
  -webkit-transition: all .3s ease;
  margin-top: 0.46428571em;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #ececec;
  border-radius: 6px;
  padding: 0.92857143em;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.picker .picker__header {
  text-align: center;
  margin-bottom: 0.92857143em;
}
.picker .picker__header > div {
  font-weight: 600;
  display: inline-block;
  color: #252525;
}
.picker .picker__header > div:not(:last-child) {
  margin-right: 0.46428571em;
}
.picker .picker__header .picker__nav--prev,
.picker .picker__header .picker__nav--next {
  height: 10px;
  width: 10px;
  cursor: pointer;
}
.picker .picker__header .picker__nav--prev:after,
.picker .picker__header .picker__nav--next:after {
  font-family: 'stack-interface';
}
.picker .picker__header .picker__nav--next {
  float: right;
}
.picker .picker__header .picker__nav--next:after {
  content: '\e80c';
}
.picker .picker__header .picker__nav--prev {
  float: left;
}
.picker .picker__header .picker__nav--prev:after {
  content: '\e80b';
}
.picker .picker__day {
  transition: all .3s ease;
  cursor: pointer;
}
.picker .picker__day.picker__day--highlighted {
  color: #E62E6A;
  font-weight: 600;
}
.picker .picker__day:hover {
  color: #E62E6A;
}
.picker:not(.picker--opened) {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: scale(0);
}
.picker .picker__holder {
  outline: none !important;
  border: none !important;
}
.picker table td {
  padding: 0.46428571em;
  padding-top: 0;
}
.picker .picker__footer {
  text-align: center;
}
.picker .picker__button--today {
  float: left;
}
.picker .picker__button--close {
  float: right;
}
.picker button {
  transition: all .3s ease;
  height: auto;
  border: none;
  display: inline-block;
}
.picker button:not(:hover) {
  opacity: .5;
}
/* Stack Horizontal Forms */
.form-horizontal {
  display: flex;
}
.form-horizontal > div[class*='col-'],
.form-horizontal > div[class*='col-']:not(:last-child) {
  margin-bottom: 0;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.form-horizontal .picker {
  top: 100%;
}
@media all and (max-width: 990px) {
  .form-horizontal {
    display: block;
  }
  label {
    margin-top: 0.46428571em;
  }
  button {
    margin-top: 1.85714286em;
  }
}
/*! -- Stack Sliders -- */
.flickity-prev-next-button {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  border-radius: 0;
  background: #252525;
  width: 2.78571429em;
  height: 5.57142857em;
}
.flickity-prev-next-button:not(:hover) {
  opacity: .5;
}
.flickity-prev-next-button:hover {
  background: #252525;
}
.flickity-prev-next-button.previous {
  left: 0;
}
.flickity-prev-next-button.next {
  right: 0;
}
.flickity-prev-next-button:before {
  color: #fff;
}
.slider.slider--inline-arrows .flickity-prev-next-button {
  background: none;
}
.slider.slider--inline-arrows .flickity-prev-next-button:before {
  font-size: 1.78571429em;
  color: #252525;
  content: '\e802';
}
.slider.slider--inline-arrows .flickity-prev-next-button.previous:before {
  content: '\e801';
}
.slider.slider--columns .previous {
  left: 15px;
}
.slider.slider--columns .next {
  right: 15px;
}
.slider.border--round .slides li {
  border-radius: 6px;
  overflow: hidden;
}
.slider.boxed--border .slides li {
  border: 1px solid #ececec;
}
.slider .slides li .feature:not(.feature-9) {
  margin-bottom: 0;
}
.slide .boxed:last-child {
  margin-bottom: 0;
}
.slide:not([class*='col-']) > img:only-child {
  width: 100%;
}
.bg--dark .slider.slider--inline-arrows .flickity-prev-next-button:before,
.bg--primary .slider.slider--inline-arrows .flickity-prev-next-button:before,
.imagebg:not(.image--light) .slider.slider--inline-arrows .flickity-prev-next-button:before {
  color: #fff;
}
@media all and (max-width: 990px) {
  .flickity-prev-next-button {
    display: none;
  }
}
@media all and (min-width: 768px) {
  h2 + .slider,
  .h2 + .slider {
    margin-top: 3.71428571em;
  }
}
.slider.slider--ken-burns:not(.parallax) .slide.is-selected > .background-image-holder,
.slider.slider--ken-burns:not(.parallax) .slide.is-selected > img:only-child {
  animation: kenBurns 15s ease alternate infinite;
  -webkit-animation: kenBurns 15s ease alternate infinite;
}
.radial {
  margin-left: auto;
  margin-right: auto;
}
.radial .radial__label {
  transition: opacity .3s ease;
  -webkit-transition: opacity .3s ease;
}
.radial:not(.radial--active) .radial__label {
  opacity: 0;
}
.radial:not(:last-child) {
  margin-bottom: 1.85714286em;
}
@media all and (max-width: 767px) {
  .radial {
    margin-bottom: 1.85714286em;
  }
}
/*! -- Stack Pricing -- */
.pricing {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  position: relative;
}
.pricing h3:first-child {
  margin: 0;
}
.pricing .label {
  top: 2.78571429em;
  right: 2.78571429em;
}
.pricing .h2 {
  margin-bottom: 0.3939393939394em;
}
.pricing ul li:not(:last-child) {
  margin-bottom: 0.46428571em;
}
.pricing ul li .checkmark + span {
  position: relative;
  bottom: 3px;
}
.pricing:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.pricing .h1 {
  font-size: 4.21428571428571em;
  line-height: 1.10169491525424em;
  margin-bottom: 0.22033898305085em;
}
.pricing .h1 .pricing__dollar {
  font-size: 0.51020408163265em;
  position: relative;
  bottom: 20px;
}
.pricing .feature-6 p {
  min-height: 5.57142857em;
}
.pricing-1 .btn:last-child {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 0 0 6px 6px;
  padding: 0.92857143em 0;
  margin: 0;
}
.pricing-1 .btn:last-child:hover {
  transform: none !important;
}
.pricing-1 ul {
  margin-bottom: 5.57142857em;
}
.pricing-1 p {
  min-height: 5.57142857em;
}
.pricing-1 p:first-of-type {
  margin-top: 1.85714286em;
}
.pricing-1 p:last-of-type {
  margin-bottom: 5.57142857em;
}
.pricing-1 img + p:first-of-type {
  margin-top: 0;
}
@media all and (min-width: 991px) {
  .pricing-2 ul:first-child {
    margin-top: 4.64285714em;
  }
}
@media all and (max-width: 990px) {
  .pricing-2 {
    text-align: center;
  }
  .pricing-2 ul:first-child {
    margin-top: 1.85714286em;
  }
}
.pricing-3 {
  border: 1px solid #ececec;
  border-radius: 6px;
}
.pricing-3 .pricing__head {
  margin: 0;
  border-bottom: 1px solid #ececec;
}
.pricing-3 .pricing__head + .btn {
  display: block;
  border-radius: 0;
  padding: 0.92857143em 0;
}
.pricing-3 ul {
  border-radius: 0 0 6px 6px;
}
.pricing-3 ul li {
  padding: 0.92857143em 0;
}
.pricing-3 ul li:not(:last-child) {
  border-bottom: 1px solid #ececec;
  margin: 0;
}
.pricing-3 ul:last-child {
  margin: 0;
}
@media all and (max-width: 990px) {
  .pricing-3 {
    margin-bottom: 1.85714286em;
  }
}
@media all and (min-width: 768px) {
  .pricing-section-1 .pricing:not(.boxed--emphasis) {
    left: 0.92857143em;
  }
  .pricing-section-1 .pricing.boxed--emphasis {
    right: 0.92857143em;
  }
}
@media all and (min-width: 990px) {
  .pricing-section-2 div[class*='col-'] {
    padding: 0;
  }
  .pricing-section-2 div[class*='col-']:not(:first-child):not(:last-child) .pricing__head {
    border-radius: 0;
  }
  .pricing-section-2 div[class*='col-']:first-child .pricing {
    border-radius: 6px 0 0 6px;
  }
  .pricing-section-2 div[class*='col-']:first-child .pricing .pricing__head {
    border-radius: 6px 0 0 0;
  }
  .pricing-section-2 div[class*='col-']:last-child .pricing {
    border-radius: 0 6px 6px 6px;
    border-right: 1px solid #ececec;
  }
  .pricing-section-2 div[class*='col-']:last-child .pricing .pricing__head {
    border-radius: 0 6px 0 0;
  }
  .pricing-section-2 .pricing {
    border: 1px solid #ececec;
    border-radius: 0 0 0 6px;
    border-right: none;
  }
  .pricing-section-2 .pricing:hover {
    box-shadow: none;
  }
  .pricing-section-2 .pricing ul {
    margin: 0;
  }
}
.pricing-section-2.bg--dark .pricing,
.pricing-section-2.bg--dark .pricing .pricing__head,
.pricing-section-2.bg--dark .pricing ul li:not(:last-child),
.pricing-section-2.bg--dark div[class*='col-']:last-child .pricing {
  border-color: #3f3f3f;
}
.pricing-section-2.imagebg .pricing,
.pricing-section-2.imagebg .pricing .pricing__head,
.pricing-section-2.imagebg .pricing ul li:not(:last-child),
.pricing-section-2.imagebg div[class*='col-']:last-child .pricing {
  border-color: rgba(255, 255, 255, 0.2);
}
/*! -- Stack Progress Horizontal -- */
.progress-horizontal:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.progress-horizontal.progress-horizontal--lg .progress-horizontal__bar {
  height: 1.85714286em;
}
.progress-horizontal.progress-horizontal--sm .progress-horizontal__bar {
  height: 0.46428571em;
}
.progress-horizontal__bar {
  height: 0.92857143em;
  border-radius: 100px;
  background: #FAFAFA;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.progress-horizontal__bar:not(:last-child) {
  margin-bottom: 0.46428571em;
}
.progress-horizontal__progress {
  background: #E62E6A;
  border-radius: 100px;
}
.imagebg .progress-horizontal__bar {
  background: rgba(20, 20, 20, 0.2);
  border: none;
}
/*! -- Stack Maps -- */
iframe[src*='google.com/maps'] {
  border: none;
}
.map-container {
  min-height: 400px;
}
.map-container:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.map-container.border--round {
  overflow: hidden;
  z-index: 2;
}
.map-container.border--round .gm-style {
  border-radius: 6px;
}
.map-container iframe {
  left: 0;
}
@media all and (min-width: 768px) {
  .background-image-holder .map-container,
  .imageblock__content .map-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .map-container + div[class*='col-'] {
    margin-top: 3.71428571em;
  }
}
/*! -- Stack Testimonials -- */
.testimonial {
  position: relative;
  overflow: hidden;
}
.testimonial h5:nth-last-child(2) {
  margin: 0;
}
.testimonial .testimonial__image {
  margin-bottom: 1.85714286em;
}
@media all and (min-width: 1025px) {
  .testimonial-1 .switchable__text {
    margin-top: 7.42857143em;
  }
}
.testimonial-2 {
  float: none;
  width: 100%;
}
.testimonial-2 .testimonial__image img {
  max-height: 3.71428571em;
  float: left;
  margin-right: 1.85714286em;
}
.testimonial-2 .boxed {
  width: 100%;
}
.bg--secondary .testimonial-2 .boxed {
  background: #fff;
}
@media all and (max-width: 990px) {
  .testimonial-2 p {
    min-height: 7.42857143em;
  }
}
@media all and (max-width: 767px) {
  .testimonial-2 p {
    min-height: 0;
  }
}
@media all and (min-width: 768px) {
  .lead + .testimonial-2 {
    margin-top: 3.71428571em;
  }
}
@media all and (min-width: 768px) {
  .testimonials-1 .list-inline {
    position: relative;
    top: 0.92857143em;
  }
  .testimonials-1 .list-inline li:not(:last-child) {
    margin-right: 3.71428571em;
  }
}
@media all and (max-width: 767px) {
  .testimonials-1 .list-inline {
    margin-top: 1.85714286em;
  }
  .testimonials-1 .list-inline li {
    margin-bottom: 1.85714286em;
  }
}
/*! -- Stack Masonry -- */
.masonry__item:not(:empty) {
  margin-bottom: 30px;
  transform-style: preserve-3d;
}
.masonry__item:not(:empty) article {
  margin: 0;
}
.masonry__item:not(:empty) article .boxed {
  margin: 0;
}
.masonry__item:empty {
  height: 0;
  min-height: 0;
}
.masonry hr + .masonry__container,
.masonry hr + .row {
  margin-top: 4.64285714em;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) {
  display: inline-block;
  cursor: pointer;
  height: 3.71428571em;
  position: relative;
  z-index: 9;
  min-width: 143px;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters {
  cursor: pointer;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  z-index: 2;
  display: inline-block;
  position: absolute;
  left: 0;
  text-align: left;
  padding: 0.92857143em 1.85714286em;
  border: 1px solid #ececec;
  background: #FFFFFF;
  border-radius: 6px;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul {
  margin: 0;
  cursor: pointer;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  display: block;
  color: #666666;
  white-space: nowrap;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li:not(:last-child) {
  margin-bottom: 0.92857143em;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li.active {
  color: #E62E6A;
  cursor: pointer;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li.active:after {
  content: '\25bc';
  font-size: 8px;
  margin-left: 6px;
  position: relative;
  bottom: 2px;
  display: inline-block;
  transform: scaleX(1.4) scaleY(0.9);
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li:hover {
  color: #E62E6A;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul:hover li.active:after {
  opacity: 0;
}
.masonry-filter-holder:not(.masonry-filters--horizontal):not(:hover) .masonry__filters ul {
  max-height: 1.85714286em;
  overflow: hidden;
}
.masonry-filter-holder:not(.masonry-filters--horizontal):not(:hover) .masonry__filters ul li:not(.active) {
  display: none;
}
.masonry-filter-holder:not(.masonry-filters--horizontal):hover .masonry__filters {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.masonry-filter-holder:not(.masonry-filters--horizontal):hover .masonry__filters ul li.active {
  cursor: default;
}
.masonry-filters--horizontal {
  display: inline-block;
}
.masonry-filters--horizontal .masonry__filters {
  display: inline-block;
  position: relative;
  margin-left: 0.92857143em;
}
.masonry-filters--horizontal .masonry__filters ul li {
  transition: all .3s ease;
  cursor: pointer;
  display: inline-block;
}
.masonry-filters--horizontal .masonry__filters ul li:not(:last-child) {
  margin-right: 1.85714286em;
}
.masonry-filters--horizontal .masonry__filters ul li.active {
  color: #E62E6A;
}
.masonry-filters--horizontal .masonry__filters ul li:not(.active) {
  opacity: .5;
}
.masonry-filters--horizontal .masonry__filters ul li:hover {
  opacity: 1;
}
.masonry-filter-holder + .masonry__container {
  margin-top: 3.71428571em;
}
.bg--dark .masonry-filter-holder .masonry__filters {
  background: #2a2a2a;
  border-color: #3f3f3f;
}
.bg--dark .masonry-filter-holder .masonry__filters li {
  color: #fff;
}
.masonry.masonry-demos .masonry__item:not(:empty) {
  margin-bottom: 3.71428571em;
}
.masonry.masonry-demos .masonry__item h5 {
  margin-bottom: 0;
}
.masonry.masonry-demos .masonry__item:not([style]) .hover-item {
  min-height: 450px;
  background: #323232;
  border: 1px solid #3f3f3f;
}
.masonry.masonry-demos .hover-item {
  transition: 0.35s ease-out;
  -webkit-transition: 0.35s ease-out;
  -moz-transition: 0.35s ease-out;
  border-radius: 6px;
  overflow: hidden;
}
.masonry.masonry-demos .hover-item:hover {
  transform: translateY(-10px);
}
.masonry.masonry-demos .masonry__container {
  margin-top: 3.71428571em;
}
/*! -- Stack Blog -- */
article .article__title {
  margin-bottom: 3.71428571em;
}
article .article__title > a {
  display: block;
}
article .article__title > a:hover {
  text-decoration: none;
  opacity: .75;
}
article .article__title h2 {
  margin-bottom: 0.3939393939394em;
}
article .article__title h1 {
  margin-bottom: 0.29545454545455em;
}
article .article__body img,
article .article__body iframe,
article .article__body .video-cover {
  border-radius: 6px;
}
article .article__body img:not(:last-child),
article .article__body iframe:not(:last-child),
article .article__body .video-cover:not(:last-child) {
  margin-bottom: 1.85714286em;
}
article .article__body > a:first-child > img {
  margin-bottom: 1.85714286em;
}
article .article__body p,
article .article__body ul {
  font-size: 1.35714286em;
  line-height: 1.68421053em;
  font-weight: 400;
  color: #808080;
}
article .article__body p:not(:last-child),
article .article__body ul:not(:last-child) {
  margin-bottom: 1.36842105263158em;
}
article .article__body p + .btn:last-child,
article .article__body ul + .btn:last-child {
  margin-top: 0.92857143em;
}
article .article__body p + h5,
article .article__body ul + h5 {
  margin-top: 2.78571429em;
}
article .article__body blockquote {
  text-align: center;
}
article .article__body blockquote:not(:last-child) {
  margin-top: 2.08em;
  margin-bottom: 2.08em;
}
article .article__share {
  margin-top: 5.57142857em;
}
article.imagebg {
  padding: 1.85714286em;
  overflow: hidden;
}
article.imagebg:before {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
article.imagebg:hover:before {
  opacity: .6;
}
article.imagebg .article__title {
  margin: 0;
  position: absolute;
  bottom: 0;
  z-index: 2;
  max-width: 90%;
}
article.imagebg .article__title span {
  font-size: 0.85714286em;
}
article.imagebg .label {
  z-index: 2;
  top: 1.85714286em;
  right: 1.85714286em;
}
article.feature-1 h5 {
  min-height: 3.71428571em;
}
article.feature-1 a img {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
article.feature-1 a:hover img {
  opacity: .85;
}
figure[class*='col-'] {
  padding: 0 !important;
}
figure.pull-right {
  margin-left: 2.78571429em;
}
figure.pull-left {
  margin-right: 2.78571429em;
}
figure img:not(:last-child) {
  margin-bottom: 0.46428571em !important;
}
figure figcaption {
  font-size: 0.85714286em;
  font-style: italic;
}
section.unpad > article .imagebg + div {
  padding: 5.57142857em 0;
}
.article__author {
  padding-bottom: 1.85714286em;
}
.article__author img {
  max-height: 3.71428571em;
  margin-bottom: 0.46428571em;
}
.slides--gapless article.imagebg {
  height: 27.85714286em;
}
.masonry-blog-magazine article,
.masonry-blog-magazine .masonry__promo {
  height: 338px;
}
.masonry-blog-magazine .height--tall {
  height: calc(706px);
}
@media all and (min-width: 991px) {
  .masonry__promo.boxed--lg {
    padding: 7.42857143em;
  }
}
@media all and (max-width: 990px) {
  .masonry-blog-magazine .height--tall {
    height: 338px;
  }
  .masonry__promo .pos-vertical-center {
    top: 0;
    transform: none;
  }
}
@media all and (max-width: 1200px) {
  .masonry__promo.boxed--lg {
    padding: 3.71428571em;
  }
}
.pagination {
  text-align: center;
  margin-top: 1.85714286em;
}
.pagination:after {
  content: "";
  display: table;
  clear: both;
}
.pagination a {
  font-weight: normal;
}
.pagination div[class*='col-'] {
  padding: 0;
}
.pagination ol {
  margin-bottom: 0;
  display: inline-block;
}
.pagination ol li {
  display: inline-block;
  margin: 0;
  font-weight: 700;
  line-height: 23px;
  width: 1.85714286em;
  height: 1.85714286em;
  border-radius: 50%;
  border: 1px solid #ececec;
}
.pagination ol li > a {
  font-weight: 700;
}
.pagination ol li:not(:last-child) {
  margin-right: 0.46428571em;
}
.pagination ol li.pagination__current {
  cursor: default;
  background: #ececec;
  color: #aaa;
}
.pagination__prev,
.pagination__next {
  display: inline-block;
}
.pagination__next {
  float: right;
}
.pagination__prev {
  float: left;
}
.blog-article-wide:not(:last-child) {
  border-bottom: 1px solid #ececec;
  margin-bottom: 3.71428571em;
}
.masonry-blog-list hr + .masonry__container {
  margin-top: 0;
}
.masonry-blog-list article {
  border-bottom: 1px solid #ececec;
  padding-top: 2.78571429em;
  margin-bottom: 5.57142857em;
}
.masonry-blog-list article .article__body {
  padding-bottom: 5.57142857em;
}
.masonry-blog-magazine .masonry__item a {
  font-weight: normal;
}
.masonry-blog-magazine .masonry__item a:hover {
  text-decoration: none;
}
.masonry-filter-container {
  margin-bottom: 1.85714286em;
}
.masonry-filter-container > span {
  position: relative;
}
.masonry-filter-container > span + .masonry-filter-holder {
  margin-left: 0.92857143em;
}
.comments__list li {
  padding: 1.85714286em;
  border: 1px solid #ececec;
  border-bottom: none;
}
.comments__list li:last-child {
  border-bottom: 1px solid #ececec;
}
.comments__list li:nth-child(odd) {
  background: #fcfcfc;
}
.comments__list li:nth-child(even) {
  background: #f9f9f9;
}
.comments__list li:first-child {
  border-radius: 6px 6px 0 0;
}
.comments__list li:last-child {
  border-radius: 0 0 6px 6px;
}
.bg--secondary .comments__list li:nth-child(even) {
  background: #fff;
}
@media all and (min-width: 768px) {
  .comments__list li {
    padding: 2.78571429em;
  }
}
.comment {
  overflow: hidden;
}
.comment:not(:last-child) {
  border-bottom: 1px solid #ececec;
  padding-bottom: 1.85714286em;
}
.comment .comment__avatar {
  width: 15%;
  float: left;
}
.comment .comment__avatar img {
  max-height: 3.71428571em;
}
.comment .comment__meta {
  font-size: 0.85714286em;
}
.comment .comment__meta a {
  display: inline-block;
}
.comment .comment__meta a:not(:first-child) {
  margin-left: 1.08333333333334em;
}
.comment .comment__meta + p {
  margin-top: 0.92857143em;
}
.comment .comment__body {
  width: 85%;
  float: right;
}
.comment .comment__body h5.type--fine-print {
  margin: 0;
}
.comment + .comment {
  margin-top: 1.85714286em;
  padding-left: 15%;
}
@media all and (min-width: 768px) {
  .comments + .comments-form {
    margin-top: 3.71428571em;
  }
}
@media all and (min-width: 768px) {
  h3 + .related-blog-list {
    margin-top: 3.71428571em;
  }
}
.related-blog-list li:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.related-blog-list article img {
  border-radius: 6px;
}
.related-blog-list article .article__title {
  margin-top: 0.92857143em;
  margin-bottom: 0;
}
.related-blog-list article .article__title .h4 {
  margin-bottom: 0.68421052631579em;
}
@media all and (min-width: 991px) {
  .sidebar {
    margin-top: 7.42857143em;
    position: relative;
    bottom: 18px;
  }
}
.sidebar__widget > h5:first-child {
  margin-bottom: 0.92857143em;
}
.sidebar__widget:not(:last-child) {
  margin-bottom: 3.71428571em;
}
.tag-cloud li {
  display: inline-block;
}
.tag-cloud li:not(:last-child) {
  margin-right: 0.46428571em;
}
.tag-cloud li:not(:last-child):after {
  content: ',';
}
.conversation__head {
  border-radius: 6px 6px 0 0;
}
.conversation__head.boxed:not(:last-child) {
  margin: 0;
}
.conversation__head h4 {
  margin: 0;
}
.conversation__head .conversation__avatar {
  margin-top: 0.92857143em;
}
.conversation__head .conversation__avatar img {
  max-height: 1.85714286em;
  display: inline-block;
  margin-right: 0.46428571em;
  margin-bottom: 0;
}
@media all and (min-width: 768px) {
  .conversation__avatar {
    position: absolute;
    top: 1.85714286em;
    right: 2.78571429em;
  }
}
.conversation__reply {
  border-top: none !important;
  border-bottom: none;
  border-radius: 0;
}
.conversation__reply.boxed:not(:last-child) {
  margin-bottom: 0;
}
.conversation__reply + .conversation__comments .comments__list li:first-child {
  border-radius: 0;
  border-top: none;
}
/*! -- Stack Portfolio -- */
.project-thumb > a {
  display: block;
}
.project-thumb:not(.hover-element) > a {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  margin-bottom: 1.85714286em;
}
.project-thumb:not(.hover-element) > a:hover {
  opacity: .85;
}
.project-thumb.hover-element {
  margin: 0;
}
.project-thumb.hover-element > a {
  height: 24.14285714em;
  text-decoration: none;
  font-weight: normal;
}
.project-thumb.hover-element .background-image-holder {
  transition: 0.5s ease-out;
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
}
.project-thumb.hover-element .hover-element__reveal .project-thumb__title {
  padding: 1.85714286em;
  z-index: 2;
  position: relative;
  color: #fff;
}
.project-thumb.hover-element .hover-element__reveal .project-thumb__title h5 {
  margin: 0;
}
.project-thumb.hover-element.hover--active {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.project-thumb.hover-element.hover--active:hover {
  opacity: .85;
}
.project-thumb.hover-element:not(.hover--active)[data-scrim-top] .hover-element__reveal {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  transform: translate3d(0, -100px, 0);
  -webkit-transform: translate3d(0, -100px, 0);
}
.project-thumb.hover-element:not(.hover--active)[data-scrim-top]:hover .hover-element__reveal {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.project-thumb.hover-element [data-scrim-top]:before {
  height: 100%;
}
.project-thumb h4 {
  margin-bottom: 0;
}
.project-thumb.height-40 {
  min-height: 350px;
}
@media all and (max-width: 767px) {
  .project-thumb.height-40 {
    height: 350px;
    min-width: 100%;
  }
}
@media all and (min-width: 768px) {
  .col-xs-12:not(.col-sm-6) .project-thumb.hover-element > a {
    height: 46.42857143em;
  }
}
.project-thumb.border--round .background-image-holder {
  border-radius: 6px;
}
.project-thumb.border--round > a {
  border-radius: 6px;
  overflow: hidden;
}
.project-thumb.border--round:before {
  border-radius: 6px;
  overflow: hidden;
}
.col-md-4 .project-thumb > a:not(.imagebg) {
  margin-bottom: 0.92857143em;
}
/*! -- Stack Shop -- */
.cart-form button[type="submit"] {
  margin-top: 1.85714286em;
}
.cart-total > div {
  overflow: hidden;
}
@media all and (min-width: 768px) {
  .cart-total {
    margin-top: 3.71428571em;
  }
  .cart-customer-details {
    margin-top: 7.42857143em;
  }
}
@media all and (max-width: 767px) {
  .cart-total {
    margin-top: 1.85714286em;
  }
}
.cart-customer-details div[class*='col-'] {
  margin-top: 0.46428571em;
}
.cart-customer-details .input-checkbox {
  margin: 1.85714286em 0;
}
.cart-customer-details .input-checkbox + span {
  bottom: 38px;
}
.product {
  margin-bottom: 0.92857143em;
}
.product img {
  margin-bottom: 0.92857143em;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  backface-visibility: hidden;
}
.product a:hover img {
  transform: translate3d(0, -5px, 0);
  -webkit-transform: translate3d(0, -5px, 0);
}
.product .label + img {
  margin-top: 2.78571429em;
}
.product h5 {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 0.46428571em;
}
.product .h4 {
  margin-bottom: 0;
}
.product .h4:first-child {
  clear: left;
}
.product .product__controls {
  overflow: hidden;
  margin-bottom: 0.92857143em;
}
.product .product__controls label,
.product .product__controls button {
  position: relative;
  top: 6px;
}
.product.product--tile {
  padding: 1.85714286em;
  border-radius: 6px;
}
@media all and (min-width: 1200px) {
  .product .btn--cart {
    position: absolute;
    right: 1.85714286em;
    bottom: 1.85714286em;
  }
}
.col-sm-6:not(.col-md-3) .product {
  text-align: center;
}
.col-md-3 .product--tile h5 {
  display: block;
}
.masonry--tiles {
  padding: 10px;
}
.masonry--tiles .masonry__item:not(:empty) {
  padding: 0 6px;
  margin-bottom: 12px;
}
.masonry--tiles .masonry__item:not(:empty) .product--tile {
  margin: 0;
}
.masonry--tiles .masonry__item:not(:empty) .product--tile .label {
  top: 1.85714286em;
  right: 2.78571429em;
}
/*! -- Stack Wizard -- */
.wizard {
  transition: opacity .3s ease;
  opacity: 0;
}
.wizard.active {
  opacity: 1;
}
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  background: #E62E6A;
  transition: all .3s ease;
  padding: 0.46428571em 1.85714286em;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;
}
.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  background: none;
  color: #252525;
}
.wizard > .steps a {
  position: relative;
}
.wizard > .steps a:before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  background: #eee;
  display: block;
  bottom: 0;
  transition: all .2s ease;
}
.wizard > .steps a:after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 7px;
  height: 2px;
  background: #eee;
  left: 0;
  transition: all .2s ease;
}
.wizard > .steps li:first-child a:after {
  width: 50%;
  left: 50%;
}
.wizard > .steps li:last-child a:after {
  width: 50%;
}
.wizard > .steps li.current a:after,
.wizard > .steps li.current a:before {
  background-color: #E62E6A;
}
.wizard > .steps a,
.wizard > .steps a:hover {
  padding-bottom: 2.78571429em;
}
.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active,
.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  background: none;
  color: #252525;
}
.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  margin: 0;
}
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.wizard > .steps > ul,
.wizard > .actions {
  text-align: center;
}
.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: none;
  display: inline-block;
}
.wizard > section {
  padding: 0;
}
.wizard > .content {
  background: #FAFAFA;
  margin-top: 1.85714286em;
  margin-bottom: 1.85714286em;
  border: 1px solid #ececec;
}
.wizard > .content > .body {
  padding: 1.85714286em;
  width: 100%;
  height: 100%;
}
.wizard > .steps .number {
  font-size: 1em;
}
@media all and (max-width: 767px) {
  .wizard > .steps .number {
    display: block;
  }
  .wizard > .content {
    min-height: 25em;
  }
  .wizard .pos-vertical-center {
    top: 50%;
    transform: translateY(-50%);
    -wekbit-transform: translateY(-50%);
  }
}
.wizard.bg--white {
  background: none !important;
}
.wizard.bg--white > .content {
  background: #fff;
}
.wizard > .content img {
  max-height: 240px;
}
@media all and (max-width: 767px) {
  .wizard > .content img {
    max-height: 120px;
    margin-bottom: 0.92857143em;
  }
}
/*! -- Stack Footers -- */
footer .type--fine-print:not(p) {
  opacity: .5;
}
footer a {
  font-weight: normal;
}
footer:not(.bg--primary):not(.bg--dark) a {
  color: #666666;
}
footer a.type--fine-print:not(:first-child) {
  margin-left: 1.85714286em;
}
footer .logo {
  max-height: 1.67142857em;
}
footer ul:not(.list-inline):not(.slides) > li {
  line-height: 2.32142857em !important;
}
footer.footer-2 .row:last-child {
  margin-top: 3.71428571em;
}
footer.footer-5 .row [class*='col-'] > div:nth-last-child(2) {
  margin: 0.92857143em;
}
footer.footer-5 .row [class*='col-'] > div:nth-last-child(2) .type--fine-print {
  opacity: 1;
}
footer.footer-6 .footer__lower {
  background: #FAFAFA;
  margin-top: 1.85714286em;
  padding: 1.85714286em 0;
}
footer.footer-6.bg--dark .footer__lower {
  background: #181818;
}
footer.footer-6.bg--secondary .footer__lower {
  background: #f5f5f5;
}
footer.footer-6.bg--primary .footer__lower {
  background: #e01b5b;
}
footer.footer-7 {
  padding: 1.85714286em 0;
}
@media all and (min-width: 768px) {
  footer .list-inline + .btn {
    position: relative;
    bottom: 4px;
  }
  footer .logo {
    margin: 0;
  }
  footer .logo:not(:last-child) {
    margin-right: 1.85714286em;
  }
  footer.footer-3 .logo + ul {
    position: relative;
    top: 5px;
  }
  footer.footer-3 .logo + ul a {
    color: #252525;
  }
  footer.footer-3 .social-list {
    position: relative;
    top: 9px;
  }
  footer.footer-3 .row:last-child {
    margin-top: 1.85714286em;
  }
  footer.footer-4 .logo + span {
    position: relative;
  }
  footer.footer-4 form {
    position: relative;
    bottom: 4px;
  }
  footer.footer-4 .row:last-child {
    margin-top: 1.85714286em;
  }
  footer.imagebg:not(.image--light) span,
  footer.imagebg:not(.image--light) p,
  footer.imagebg:not(.image--light) a {
    color: #fff;
  }
  .footer-1 .type--fine-print {
    position: relative;
    top: 2px;
  }
}
@media all and (max-width: 767px) {
  footer .social-list {
    margin: 1.85714286em 0;
  }
  .footer-4 form {
    margin: 1.85714286em 0;
  }
  .footer-4 .logo {
    margin: 0;
  }
  .footer-6 .footer__lower .social-list {
    margin-top: 1.85714286em;
  }
  .footer-7 .social-list {
    margin-top: 1.85714286em;
  }
  .footer-1 .social-list {
    margin-top: 1.85714286em;
  }
  .footer-1 .logo {
    display: block;
    margin-top: 1.85714286em;
    margin-bottom: 0.92857143em;
    margin-left: auto;
    margin-right: auto;
  }
}

/*! Place your custom styles here */
.menu-horizontal > li:not(:hover) > a,
.menu-horizontal > li:not(:hover) > span,
.menu-horizontal > li:not(:hover) > .modal-instance > .modal-trigger {
  opacity: .7;
}

@media all and (min-width: 990px) {
	.bar {
	  padding: 2em 0 1em 0;
	}

	.bar .logo {
	    max-height: 3.4em;
	    max-width: none;
	}

	.bar .logo {
	    margin: 0;
	    position: relative;
	    top: -5px;
	}
	
	.dropdown__content--lg{ left: 0 !important; }
}

@media all and (min-width: 991px) and (max-width: 1200px) {
    .dropdown__container > .container {
	    width: 100vw;
	    max-width: 960px;
	}
}

@media all and (min-width: 768px) and (max-width: 990px) {	
	.dropdown__container > .container {
	    width: 100vw;
	    max-width: 720px;
	}

	.bar .logo {
	    max-height: 2.5em;
	    max-width: none;
	}

	.bar:not(.bg--dark) .logo-light {
	    display: none !important;
	}
}